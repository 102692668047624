import { useCallback, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import debounce from "lodash/debounce";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Divider, Spin } from "antd";
import {
  RiAddFill,
  RiAddLine,
  RiCalendarCheckFill,
  RiCloseCircleFill,
  RiCloseCircleLine,
  RiTeamLine,
} from "react-icons/ri";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import {
  BoxHeader,
  Container,
  Box,
  Title,
  ActionsContainer,
  ConfirmButton,
  CancelButton,
  EventsContainer,
  EmptyCollaboratorsContainer,
  NewCollaboratorButton,
  TeamColorWrapper,
  TeamColorContainer,
  TeamColorButton,
  InputWrapper,
  Input,
  Autocomplete,
  AutocompleteOption,
  ModalStyled,
  FieldWrapper,
  HiddenColorPicker,
} from "./styles";
import { Footer } from "components/Footer";
import { InputForm } from "components/Form/InputForm";
import { Colors } from "constants/colors";
import { ModalFilterByEvent } from "components/ModalFilterByEvent";
import { SelectEventFilter } from "components/FiltersSelect/SelectEventFilter";
import ItemSelected, { ItemsSelectedContainer } from "components/ItemSelected";
import { ModalSuccess } from "components/ModalSuccess";
import { useDisclosure } from "hooks/useDisclosure";
import {
  addEventToTeam,
  deleteEventFromTeam,
  deleteUserFromTeam,
  editTeam,
  handleSearchMemberByEmail,
} from "services/teams";
import {
  EditUserDTO,
  EventDTO,
  Team,
  UserResponse,
} from "services/teams/types";
import { EventOrganizer } from "types";
import { BiUserCircle } from "react-icons/bi";
import ModalSelectUserRole from "./components/ModalSelectUserRole";
import { TeamUsersRoleEnum } from "services/teams/types";
import {
  errorNotification,
  successNotification,
} from "components/Notification";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTeamDetail } from "services/Hook/Team";
import { mutate } from "swr";
import { useAuth } from "contexts/AuthContext";
import { LoadingOutlined } from "@ant-design/icons";
import ModalConfirmDeletion from "components/ModalConfirmDeletion";

type Params = {
  id: string;
};

type FormSchema = {
  name: string;
  events: Array<EventDTO>;
  users: Array<EditUserDTO>;
  color: string;
};

type MemberOption = {
  label: React.ReactNode | JSX.Element;
  value: string;
  user: UserResponse;
};

const metaTags = {
  title: `Edição de time - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

const antIcon = (
  <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
);

const formSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  events: yup
    .array()
    .required("Selecione pelo menos um evento")
    .min(1, "Selecione pelo menos um evento"),
  users: yup
    .array()
    .required("Adicione pelo menos um integrante")
    .min(1, "Adicione pelo menos um integrante"),
  color: yup.string().required("Cor obrigatória"),
});

function TeamEdit() {
  const navigate = useNavigate();
  const colorInputRef = useRef<HTMLInputElement>(null);
  const { id: teamId } = useParams<Params>();
  const [options, setOptions] = useState<Array<MemberOption>>([]);
  const [searchValue, setSearchValue] = useState("");
  const { handleCancel, handleOk, isModalVisible, showModal } = useDisclosure();
  const [selectedEvents, setSelectedEvents] = useState<Array<EventOrganizer>>(
    []
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [customHexColor, setCustomHexColor] = useState("#000");
  const [isOpenModalSelectUserRole, setIsOpenModalSelectUserRole] =
    useState(false);
  const [eventIdForRemove, setEventIdForRemove] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);
  const [validatingPermission, setValidatingPermission] = useState(true);
  const { data } = useTeamDetail(teamId);
  const { user: userFromAuth } = useAuth();
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors, submitCount },
    handleSubmit,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      events: [],
      users: [],
    },
  });

  const {
    handleCancel: handleCancelDeleteEvent,
    handleOk: handleOkDeleteEvent,
    isModalVisible: isModalVisibleDeleteEvent,
    showModal: showModalDeleteEvent,
  } = useDisclosure();
  const {
    handleCancel: handleCancelDeleteUser,
    handleOk: handleOkDeleteUser,
    isModalVisible: isModalVisibleDeleteUser,
    showModal: showModalDeleteUser,
  } = useDisclosure();

  const events = searchParams.get("events") ?? "";

  const currentColor = watch("color");
  const watchUsers = watch("users");

  const handleToggleModalSelectUserRole = () => {
    setIsOpenModalSelectUserRole((prev) => !prev);
  };

  const authUser = (id: string) => {
    const user = options.find((i) => i.value === id)?.user;

    if (user) return user;

    const userFromDetail = data?.users.find((u) => u._id._id === id);

    return userFromDetail?._id;
  };

  const handleRemoveEvent = useCallback((id: string) => {
    setSelectedEvents((prev) => prev.filter((event) => event._id !== id));
  }, []);

  const handleClearEvents = () => {
    setSelectedEvents([]);
  };

  const handleRemoveUser = useCallback(
    (index: number) => {
      const options = watchUsers;

      options.splice(index, 1);

      setValue("users", options);
    },
    [setValue, watchUsers]
  );

  const handleRemoveEventFromTeam = useCallback(async () => {
    if (!teamId) return;

    if (!eventIdForRemove) return;

    setFetching(true);

    const newParams = searchParams;
    const oldEvents = decodeURIComponent(events).split(",");

    const filterEvents = oldEvents
      .filter((i) => i !== eventIdForRemove)
      .join(",");

    const filterEventsByIds = selectedEvents.filter((event) =>
      filterEvents.includes(event._id)
    );

    try {
      await deleteEventFromTeam({ eventId: eventIdForRemove, teamId });

      handleRemoveEvent(eventIdForRemove);

      successNotification("Evento removido com sucesso.");
      mutate("/team");
      handleOkDeleteEvent();

      setSelectedEvents(filterEventsByIds);

      if (filterEventsByIds.length === 0) {
        searchParams.delete("events");

        setSearchParams({
          ...newParams,
        });

        return;
      }

      setSearchParams({
        ...newParams,
        events: filterEvents,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorNotification(
          "Erro ao cadastrar o time",
          error?.response?.data?.message
        );

        return;
      }

      errorNotification(
        "Erro ao cadastrar o time",
        "Tente novamente mais tarde"
      );
    } finally {
      setFetching(false);
    }
  }, [
    teamId,
    eventIdForRemove,
    searchParams,
    events,
    selectedEvents,
    handleRemoveEvent,
    handleOkDeleteEvent,
    setSearchParams,
  ]);

  const handleRemoveUserFromTeam = useCallback(
    async (userId: string, index: number) => {
      if (!teamId) return;

      setFetching(true);

      try {
        await deleteUserFromTeam({ userId, teamId });

        handleRemoveUser(index);

        successNotification("Usuário removido com sucesso.");
        mutate("/team");
        handleOkDeleteUser();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          errorNotification(
            "Erro ao cadastrar o time",
            error?.response?.data?.message
          );

          return;
        }

        errorNotification(
          "Erro ao cadastrar o time",
          "Tente novamente mais tarde"
        );
      } finally {
        setFetching(false);
      }
    },
    [handleRemoveUser, teamId, handleOkDeleteUser]
  );

  const debouncedFetchUsers = debounce((searchText) => {
    if (searchText) {
      fetchUsers(searchText);
    } else {
      setOptions([]);
    }
  }, 1000);

  const handleApplyUserRole = (role: TeamUsersRoleEnum) => {
    setIsOpenModalSelectUserRole(false);

    const options = watchUsers;

    if (options && options.length > 0) {
      const lastUserIndex = options.length - 1;
      const updatedOptions = [...options];

      updatedOptions[lastUserIndex] = {
        ...updatedOptions[lastUserIndex],
        role: role,
      };

      setValue("users", updatedOptions);
    }
  };

  const fetchUsers = async (search: string) => {
    try {
      const response = await handleSearchMemberByEmail(search);
      const results = response.map((user) => ({
        value: user._id,
        label: (
          <AutocompleteOption>
            <strong>{user.name}</strong>
            <br />
            <span>{user.email}</span>
          </AutocompleteOption>
        ),
        user,
      }));

      setOptions(results);
    } catch {
      setOptions([]);
      errorNotification(
        "Ocorreu um erro",
        "Erro ao buscar os usuários, tente novamente mais tarde."
      );
    }
  };

  const handleSelect = (data: any) => {
    const options = watchUsers;

    if (options.some((p) => p._id === (data as string))) {
      errorNotification("E-mail já adicionado anteriormente.");

      return;
    }

    options.push({
      _id: data,
    });

    setValue("users", options);
    handleToggleModalSelectUserRole();
  };

  const onSubmit = async (data: FormSchema) => {
    setFetching(true);

    try {
      if (!teamId) return;

      await editTeam(teamId, data);

      showModal();
      mutate("/team");
      navigate("/teams");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorNotification(
          "Erro ao cadastrar o time",
          error?.response?.data?.message
        );

        return;
      }

      errorNotification(
        "Erro ao cadastrar o time",
        "Tente novamente mais tarde"
      );
    }
  };

  const handleAddEventToTeam = useCallback(
    async (eventsProps: EventOrganizer[], removeIds) => {
      if (!teamId) return;

      const allEventsSelected = eventsProps.map((i) => i._id) ?? [];

      if (allEventsSelected.length > 0) {
        for await (const eventId of allEventsSelected) {
          try {
            setFetching(true);

            await addEventToTeam({ eventId, teamId });

            successNotification("Evento adicionado com sucesso.");
            mutate("/team");
          } catch (error) {
            if (axios.isAxiosError(error)) {
              errorNotification(
                "Erro ao cadastrar o time",
                error?.response?.data?.message
              );

              return;
            }

            errorNotification(
              "Erro ao cadastrar o time",
              "Tente novamente mais tarde"
            );
          } finally {
            setFetching(false);
          }
        }
      }

      if (removeIds.length > 0) {
        for await (const eventId of removeIds) {
          try {
            setFetching(true);

            await deleteEventFromTeam({ eventId, teamId });
          } catch (error) {
            if (axios.isAxiosError(error)) {
              errorNotification(
                "Erro ao cadastrar o time",
                error?.response?.data?.message
              );

              return;
            }

            errorNotification(
              "Erro ao cadastrar o time",
              "Tente novamente mais tarde"
            );
          } finally {
            setFetching(false);
          }
        }
      }
    },
    [teamId]
  );

  useEffect(() => {
    if (data) {
      setFetching(false);

      setValue("name", data.name);
      setValue(
        "events",
        data.events.map((event) => ({ _id: event._id._id }))
      );
      setValue(
        "users",
        data.users.map((user) => ({
          _id: user._id._id,
          role: user.role,
          email: user._id?.email,
          name: user._id?.name,
        }))
      );
      setValue("color", data.color);

      setSelectedEvents(
        data.events.map(
          (e) => ({ _id: e._id._id, name: e._id.name } as EventOrganizer)
        )
      );
    }
  }, [data, setValue]);

  const canEditOrDeleteTeam = useCallback(
    (team: Team): boolean => {
      const allowedPermissions = [
        TeamUsersRoleEnum.COORDINATOR,
        TeamUsersRoleEnum.LEADER,
        TeamUsersRoleEnum.MANAGER,
      ];
      const myself = team.users.find((u) => u._id._id === userFromAuth?.id);

      if (!myself) return false;

      const myRole = myself.role;

      if (allowedPermissions.includes(myRole)) {
        setValidatingPermission(false);

        return true;
      }

      return false;
    },
    [userFromAuth?.id]
  );

  const handleShowColorPicker = () => {
    colorInputRef.current?.click();
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomHexColor(event.target.value);
  };

  useEffect(() => {
    if (data && !canEditOrDeleteTeam(data)) {
      navigate("/teams");
    }
  }, [data, canEditOrDeleteTeam, navigate]);

  useEffect(() => {
    let eventsToAdd = events;
    const newParams = searchParams;

    if (selectedEvents.length > 0) {
      eventsToAdd = selectedEvents?.map((event) => event._id).join(",") ?? "";

      setSearchParams({
        events: eventsToAdd,
      });

      return;
    }

    searchParams.delete("events");

    setSearchParams({
      ...newParams,
    });
  }, [data, events, searchParams, selectedEvents, setSearchParams]);

  useEffect(() => {
    debouncedFetchUsers(searchValue);
    return () => {
      debouncedFetchUsers.cancel();
    };
  }, [searchValue]);

  return (
    <Spin spinning={validatingPermission || fetching} indicator={antIcon}>
      <Header metaTags={metaTags} />

      <Container onSubmit={handleSubmit(onSubmit)}>
        <div className="bread">
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Times", link: "/teams" },
              { name: "Editar time", link: `/teams/${teamId}/edit` },
              { name: data?.name ?? "", link: "" },
            ]}
          />
        </div>

        <Title>Editar time</Title>

        <Box>
          <BoxHeader>
            <h2>Detalhes do time</h2>

            <p>Preencha abaixo os campos para criar um novo time.</p>
          </BoxHeader>

          <InputForm
            {...register("name")}
            isFull
            icon={<RiTeamLine size={22} color={Colors.secondary40} />}
            label="Nome do time"
            placeholder="Insira o nome do time..."
            error={errors?.name}
          />

          <EventsContainer>
            <h3>Eventos vinculados ao time</h3>

            <FieldWrapper>
              <Controller
                name="events"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectEventFilter
                    title="Insira o nome do evento ou selecione um..."
                    label="Selecione os eventos"
                    sortKey="events"
                    onClearEvents={handleClearEvents}
                    showSelectedLenght={false}
                  />
                )}
              />

              {submitCount > 0 && selectedEvents.length <= 0 && (
                <span className="error">Você precisa informar um evento</span>
              )}
            </FieldWrapper>

            <ItemsSelectedContainer>
              {selectedEvents.map((event) => (
                <>
                  <ItemSelected
                    startAdornment={
                      <RiCalendarCheckFill size={25} color={Colors.purple20} />
                    }
                    endAdornment={
                      <Button
                        type="ghost"
                        shape="circle"
                        icon={
                          <RiCloseCircleFill
                            size={25}
                            color={Colors.purple20}
                          />
                        }
                        onClick={() => {
                          setEventIdForRemove(event?._id);

                          showModalDeleteEvent();
                        }}
                      />
                    }
                    title={event.name}
                  />
                </>
              ))}

              <ModalConfirmDeletion
                title="Deseja remover o evento do time?"
                fetchingDelete={fetching}
                handleCancel={handleCancelDeleteEvent}
                handleOk={handleRemoveEventFromTeam}
                isModalVisibleConfirm={isModalVisibleDeleteEvent}
              />
            </ItemsSelectedContainer>
          </EventsContainer>

          <EventsContainer>
            <h3>Selecione os membros do time</h3>

            <FieldWrapper>
              <Autocomplete
                options={options}
                onSearch={setSearchValue}
                onSelect={handleSelect}
                placeholder="Insira nome ou e-mail do usuário..."
              >
                <InputWrapper>
                  <Input />
                </InputWrapper>
              </Autocomplete>

              {submitCount > 0 && watchUsers.length <= 0 && (
                <span className="error">Você precisa informar um membro</span>
              )}
            </FieldWrapper>

            <ItemsSelectedContainer>
              {watchUsers?.map((i, index) => (
                <>
                  <ItemSelected
                    startAdornment={
                      <BiUserCircle size={25} color={Colors.purple20} />
                    }
                    endAdornment={
                      <Button
                        type="ghost"
                        shape="circle"
                        icon={
                          <RiCloseCircleFill
                            size={25}
                            color={Colors.purple20}
                          />
                        }
                        onClick={showModalDeleteUser}
                      />
                    }
                    title={authUser(i._id)?.name ?? ""}
                    description={authUser(i._id)?.email ?? ""}
                  />

                  <ModalConfirmDeletion
                    title="Deseja remover o usuário do time?"
                    fetchingDelete={fetching}
                    handleCancel={handleCancelDeleteUser}
                    handleOk={() => handleRemoveUserFromTeam(i?._id, index)}
                    isModalVisibleConfirm={isModalVisibleDeleteUser}
                  />
                </>
              ))}
            </ItemsSelectedContainer>
          </EventsContainer>

          <Divider />

          <EmptyCollaboratorsContainer>
            <h4>Não encontrou quem estava procurando?</h4>

            <NewCollaboratorButton
              to={`/teams/${teamId}/collaborators/new`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RiAddLine />
              Cadastrar novo colaborador
            </NewCollaboratorButton>
          </EmptyCollaboratorsContainer>

          <Divider />

          <TeamColorWrapper>
            <h4>Cor do time</h4>

            <TeamColorContainer>
              <TeamColorButton
                color="#F55C5C"
                type="button"
                onClick={() => setValue("color", "#F55C5C")}
                className={
                  currentColor === "#F55C5C" ? "is-selected" : undefined
                }
              />

              <TeamColorButton
                color="#F2F55C"
                type="button"
                onClick={() => setValue("color", "#F2F55C")}
                className={
                  currentColor === "#F2F55C" ? "is-selected" : undefined
                }
              />

              <TeamColorButton
                color="#5CBEF5"
                type="button"
                onClick={() => setValue("color", "#5CBEF5")}
                className={
                  currentColor === "#5CBEF5" ? "is-selected" : undefined
                }
              />

              <TeamColorButton
                color="#C45CF5"
                type="button"
                onClick={() => setValue("color", "#C45CF5")}
                className={
                  currentColor === "#C45CF5" ? "is-selected" : undefined
                }
              />

              <TeamColorButton
                color={customHexColor}
                type="button"
                onClick={() => setValue("color", customHexColor)}
                className={
                  currentColor === customHexColor ? "is-selected" : undefined
                }
              />

              <TeamColorButton
                color={Colors.white}
                type="button"
                onClick={handleShowColorPicker}
              >
                <RiAddFill size={25} color={Colors.secondary30} />
              </TeamColorButton>

              <HiddenColorPicker
                type="color"
                ref={colorInputRef}
                onChange={handleColorChange}
              />
            </TeamColorContainer>

            {errors.color?.message && (
              <span className="error">{errors.color.message}</span>
            )}
          </TeamColorWrapper>
        </Box>

        <ActionsContainer>
          <CancelButton to="/teams">Cancelar</CancelButton>
          <ConfirmButton type="submit" disabled={fetching}>
            Salvar
          </ConfirmButton>
        </ActionsContainer>
      </Container>

      <Footer />

      <ModalFilterByEvent
        onSelectEvents={setSelectedEvents}
        onClearEvents={handleClearEvents}
        handleClick={handleAddEventToTeam}
      />

      <ModalSuccess
        title="Time editado com sucesso!"
        subtitle="Agora você já pode adicionar colaboradores em seu novo time."
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
      />

      <ModalStyled
        width={857}
        visible={isOpenModalSelectUserRole}
        onOk={handleToggleModalSelectUserRole}
        onCancel={handleToggleModalSelectUserRole}
        style={{ borderRadius: "20px" }}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "34px" }}
            size={43}
            color={Colors.black40}
          />
        }
        footer={null}
      >
        <ModalSelectUserRole onSelect={handleApplyUserRole} />
      </ModalStyled>
    </Spin>
  );
}

export default TeamEdit;
