import { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import { RefundPurchase } from "services/Hook/User";
import { useDisclosure } from "hooks/useDisclosure";
import type { Transaction } from "types";

import {
  Container,
  ContainerItems,
  ContainerAllItems,
  CardTerms,
  ContainerButtonsCancel,
  ArticleContP,
  ArticleContSpan,
  ArticleContPLast,
  HiddenResponsive,
  ShowResponsive,
  ModalStyled,
  ContainerModalConfirmReverse,
  ButtonConfirm,
} from "./styles";

import { Footer } from "components/Footer";
import { Header } from "components/Header";

import { ModalConfirmReverse } from "components/ModalConfirmReverse";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { ConfirmReverse } from "components/ConfirmReverse";

import img_reverse_success from "assets/img-reverse-sucess.svg";
import img_reverse_error from "assets/img-reverse-error.svg";
import { Colors } from "constants/colors";
import { useFinancialTransactionById } from "services/Hook/Financial";
import { ContainerError } from "components/ContainerError";

export default function TermsCancelByOrganizer() {
  const navigate = useNavigate();

  const [showReverse, setShowReverse] = useState(false);
  const [isLoadingReverse, setIsLoadingReverse] = useState(false);
  const [messageError, setMessageError] = useState("");

  const {
    handleCancel: handleCancelReverse,
    handleOk: handleOkReverse,
    isModalVisible: isModalVisibleReverse,
    showModal: showModalReverse,
  } = useDisclosure();

  const {
    handleCancel: handleCancelConfirm,
    handleOk: handleOkConfirm,
    isModalVisible: isModalVisibleConfirm,
    showModal: showModalConfirm,
  } = useDisclosure();

  const {
    handleCancel: handleCancelError,
    handleOk: handleOkError,
    isModalVisible: isModalVisibleError,
    showModal: showModalError,
  } = useDisclosure();

  const { purchase_id } = useParams();

  const { data, error, isValidating } =
    useFinancialTransactionById<Transaction>(purchase_id ?? "");

  const PAYMENT_DONT_REVERSE = data?.payment_status !== "PAID";
  const PAYMENT_HAS_BOLETO = data?.payment_type === "boleto";

  if (!isValidating && !error && PAYMENT_DONT_REVERSE) {
    return <div>Esta compra não pode ser Estornada !</div>;
  }

  if (error) {
    return (
      <ContainerError
        title="Erro ao carregar essa compra"
        subtitle={JSON.stringify(error)}
        href="/financial?tab_name=transactions"
      />
    );
  }

  const handleCancelPurchase = async () => {
    try {
      const credentials = {
        type_account: 1,
      };

      setIsLoadingReverse(true);

      await RefundPurchase({ purchaseId: String(purchase_id), credentials });

      showModalConfirm();
    } catch (err: any) {
      setIsLoadingReverse(false);
      setMessageError(JSON.stringify(err.response?.data));
      showModalError();
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <>
      <Header isProfile />

      <Container>
        <ContainerItems>
          <HiddenResponsive>
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Perfil", link: "/profile/my-shopping" },
                { name: "Termo de cancelamento", link: "/" },
              ]}
            />

            <div className="header-title">
              <h1 className="title">Cancelamento de compra</h1>
            </div>

            <ContainerAllItems>
              <CardTerms>
                <div className="header">
                  <h4>Termos de cancelamento da compra</h4>

                  <div className="date-header">
                    <p>03/08/21 - Atualizado</p>
                  </div>
                </div>

                <ArticleContP>
                  1. Em caso de arrependimento do cliente, para compras
                  realizadas via site ou aplicativo, o reembolso do valor da
                  compra será efetuado, desde que esse direito seja requisitado
                  em até 07 (sete) dias da data da compra e que esteja em até 02
                  (dois) dias de antecedência à data do evento. Para compras
                  realizadas com menos de 07 (sete) dias de antecedência ao
                  evento, os ingressos só poderão ser cancelados em até 02
                  (dois) dias antes da apresentação. A devolução dos valores
                  pagos seguirá o procedimento abaixo descrito, de acordo com a
                  forma de pagamento utilizada:
                </ArticleContP>

                <ArticleContP>
                  1.2 Cartão de crédito: o estorno do valor será solicitado à
                  operadora do cartão, que disponibilizará o crédito em fatura
                  seguinte ou subsequente. Após o cancelamento da compra, seu
                  reembolso aparecerá na fatura do mesmo cartão usado durante a
                  compra em até 90 dias. Essa regra vale tanto para cartões
                  físicos quanto virtuais. O reembolso de uma compra paga com
                  cartão de crédito é feito sempre para a operadora do cartão,
                  que normalmente devolve o valor diretamente na fatura do
                  cartão usado na compra. Caso você não tenha mais o cartão
                  usado na compra, a operadora do seu cartão pode optar por te
                  reembolsar das seguintes formas: Caso exista saldo devedor em
                  outro cartão da mesma operadora, o valor poderá ser
                  reembolsado na fatura deste outro cartão Caso um novo cartão
                  tenha sido emitido por questões de upgrade ou vencimento, o
                  valor poderá ser reembolsado na fatura do cartão atual Se
                  nenhuma das opções acima se aplica ao seu caso, sugerimos que
                  entre em contato com a operadora do seu cartão para checar
                  como seu reembolso será feito.
                </ArticleContP>

                <ArticleContP>
                  1.3 Boleto ou Pix: depósito bancário em até 90 dias úteis.
                  Após o cancelamento da compra, você será direcionado para
                  preencher um formulário de reembolso. É possível receber o
                  reembolso tanto em conta corrente quanto conta poupança. Após
                  preencher o formulário de reembolso, basta aguardar um prazo
                  de até 90 dias. Atenção! É preciso informar uma conta
                  associada ao mesmo CPF ou CNPJ informado no momento da compra.
                  Caso você informe uma conta com CPF ou CNPJ incorreto, você
                  receberá uma mensagem de falha e poderá tentar novamente. Caso
                  você não tenha uma conta bancária para receber o valor ou
                  tenha ficado com alguma dúvida, fale com a gente! Informamos
                  que os prazos discriminados no item 1.2 para estornos via
                  cartão de crédito, pode variar, de acordo com o fechamento de
                  sua fatura e/ou seu banco emissor.
                </ArticleContP>

                <ArticleContSpan>Importante!</ArticleContSpan>

                <ArticleContPLast>
                  Os eventos presenciais alterados ou cancelados devido à
                  pandemia da COVID-19 podem ter uma política de reembolso
                  diferente da citada neste texto, com base na Lei 14.046/2020.
                  Para conferir qual é a política aplicada no seu evento,
                  lembre-se de acessar a página do evento ou entre em contato
                  com nosso time.
                </ArticleContPLast>
              </CardTerms>
            </ContainerAllItems>

            <ContainerButtonsCancel>
              <button
                className="cancel"
                onClick={() => navigate("/financial?tab_name=transactions")}
              >
                <span>Desistir do cancelamento</span>
              </button>

              <button
                className="confirm"
                onClick={
                  PAYMENT_HAS_BOLETO
                    ? () => showModalReverse()
                    : () => handleCancelPurchase()
                }
                disabled={isLoadingReverse}
              >
                <Spin spinning={isLoadingReverse} indicator={antIcon} />

                <span>Cancelar compra</span>
              </button>
            </ContainerButtonsCancel>
          </HiddenResponsive>

          <ShowResponsive>
            {!showReverse ? (
              <>
                <div className="header-title">
                  <h1 className="title">Cancelamento de compra</h1>
                </div>

                <ContainerAllItems>
                  <CardTerms>
                    <div className="header">
                      <h4>Termos de cancelamento da compra</h4>

                      <div className="date-header">
                        <p>03/08/21 - Atualizado</p>
                      </div>
                    </div>

                    <ArticleContP>
                      1. Em caso de arrependimento do cliente, para compras
                      realizadas via site ou aplicativo, o reembolso do valor da
                      compra será efetuado, desde que esse direito seja
                      requisitado em até 07 (sete) dias da data da compra e que
                      esteja em até 02 (dois) dias de antecedência à data do
                      evento. Para compras realizadas com menos de 07 (sete)
                      dias de antecedência ao evento, os ingressos só poderão
                      ser cancelados em até 02 (dois) dias antes da
                      apresentação. A devolução dos valores pagos seguirá o
                      procedimento abaixo descrito, de acordo com a forma de
                      pagamento utilizada:
                    </ArticleContP>

                    <ArticleContP>
                      1.2 Cartão de crédito: o estorno do valor será solicitado
                      à operadora do cartão, que disponibilizará o crédito em
                      fatura seguinte ou subsequente. Após o cancelamento da
                      compra, seu reembolso aparecerá na fatura do mesmo cartão
                      usado durante a compra em até 90 dias. Essa regra vale
                      tanto para cartões físicos quanto virtuais. O reembolso de
                      uma compra paga com cartão de crédito é feito sempre para
                      a operadora do cartão, que normalmente devolve o valor
                      diretamente na fatura do cartão usado na compra. Caso você
                      não tenha mais o cartão usado na compra, a operadora do
                      seu cartão pode optar por te reembolsar das seguintes
                      formas: Caso exista saldo devedor em outro cartão da mesma
                      operadora, o valor poderá ser reembolsado na fatura deste
                      outro cartão Caso um novo cartão tenha sido emitido por
                      questões de upgrade ou vencimento, o valor poderá ser
                      reembolsado na fatura do cartão atual Se nenhuma das
                      opções acima se aplica ao seu caso, sugerimos que entre em
                      contato com a operadora do seu cartão para checar como seu
                      reembolso será feito.
                    </ArticleContP>

                    <ArticleContP>
                      1.3 Boleto ou Pix: depósito bancário em até 90 dias úteis.
                      Após o cancelamento da compra, você será direcionado para
                      preencher um formulário de reembolso. É possível receber o
                      reembolso tanto em conta corrente quanto conta poupança.
                      Após preencher o formulário de reembolso, basta aguardar
                      um prazo de até 90 dias. Atenção! É preciso informar uma
                      conta associada ao mesmo CPF ou CNPJ informado no momento
                      da compra. Caso você informe uma conta com CPF ou CNPJ
                      incorreto, você receberá uma mensagem de falha e poderá
                      tentar novamente. Caso você não tenha uma conta bancária
                      para receber o valor ou tenha ficado com alguma dúvida,
                      fale com a gente! Informamos que os prazos discriminados
                      no item 1.2 para estornos via cartão de crédito, pode
                      variar, de acordo com o fechamento de sua fatura e/ou seu
                      banco emissor.
                    </ArticleContP>

                    <ArticleContSpan>Importante!</ArticleContSpan>

                    <ArticleContPLast>
                      Os eventos presenciais alterados ou cancelados devido à
                      pandemia da COVID-19 podem ter uma política de reembolso
                      diferente da citada neste texto, com base na Lei
                      14.046/2020. Para conferir qual é a política aplicada no
                      seu evento, lembre-se de acessar a página do evento ou
                      entre em contato com nosso time.
                    </ArticleContPLast>
                  </CardTerms>
                </ContainerAllItems>

                <ContainerButtonsCancel>
                  <button
                    className="cancel"
                    onClick={() => navigate("/financial?tab_name=transactions")}
                  >
                    <span>Desistir do cancelamento</span>
                  </button>

                  <button
                    className="confirm"
                    onClick={
                      PAYMENT_HAS_BOLETO
                        ? () => setShowReverse(true)
                        : () => handleCancelPurchase()
                    }
                    disabled={isLoadingReverse}
                  >
                    <Spin spinning={isLoadingReverse} indicator={antIcon} />

                    <span>Cancelar compra</span>
                  </button>
                </ContainerButtonsCancel>
              </>
            ) : null}

            {showReverse ? (
              <>
                <ConfirmReverse
                  purchase={data}
                  showModalConfirm={showModalConfirm}
                  showModalError={showModalError}
                  closeShowReverse={() => setShowReverse(false)}
                />
              </>
            ) : null}
          </ShowResponsive>
        </ContainerItems>

        {data ? (
          <ModalConfirmReverse
            purchase={data}
            handleOk={handleOkReverse}
            handleCancel={handleCancelReverse}
            showModalConfirm={showModalConfirm}
            showModalError={showModalError}
            isModalVisible={isModalVisibleReverse}
          />
        ) : null}

        <ModalStyled
          width={642}
          visible={isModalVisibleError}
          onOk={handleOkError}
          onCancel={handleCancelError}
          closeIcon={null}
          footer={null}
        >
          <ContainerModalConfirmReverse>
            <img
              src={img_reverse_error}
              width={189}
              height={189}
              alt="confirm-cancel"
            />

            <div className="texts-confirm">
              <h3>Falha no estorno!</h3>

              <p>{messageError}</p>
            </div>

            <div className="buttons">
              <ButtonConfirm
                onClick={() => {
                  handleOkError();
                }}
              >
                <span>Ok</span>
              </ButtonConfirm>
            </div>
          </ContainerModalConfirmReverse>
        </ModalStyled>

        <ModalStyled
          width={642}
          visible={isModalVisibleConfirm}
          onOk={handleOkConfirm}
          onCancel={handleCancelConfirm}
          closeIcon={null}
          footer={null}
        >
          <ContainerModalConfirmReverse>
            <img
              src={img_reverse_success}
              width={189}
              height={189}
              alt="confirm-cancel"
            />

            <div className="texts-confirm">
              {data?.type !== "eventx-checkout" ? (
                <h3>Cancelamento realizado!</h3>
              ) : (
                <h3>Estorno realizado!</h3>
              )}

              {data?.type !== "eventx-checkout" ? (
                <p>
                  O cancelamento foi realizado com sucesso. Esta compra não teve
                  pagamento via Eventx, não havendo reembolso.
                </p>
              ) : (
                <p>
                  O estorno foi realizado com sucesso. O valor da sua compra
                  será devolvido à sua fatura em até 90 dias úteis.
                </p>
              )}
            </div>

            <div className="buttons">
              <ButtonConfirm
                onClick={() => {
                  handleOkConfirm();
                  navigate("/financial?tab_name=transactions");
                }}
              >
                <span>Ok</span>
              </ButtonConfirm>
            </div>
          </ContainerModalConfirmReverse>
        </ModalStyled>
      </Container>
      <Footer />
    </>
  );
}
