import { useSearchParams } from "react-router-dom";
import { TicketEvent } from "types";
import { useFiltersContext } from "contexts/FiltersContext";
import { KeySort } from "constants/filters";
import { Colors } from "constants/colors";
import SelectV2 from "components/SelectV2";
import { RiCalendarCheckFill, RiCoupon2Line } from "react-icons/ri";
import { useAuthTicketsByEventsId } from "services/Hook/Event/Ticket";
import { Container } from "./styles";

interface SelectFilterModalPropsV2 {
  title: string;
  sortKey: KeySort;
  label?: string;
}

interface EventTicketInterface {
  results: TicketEvent[];
  count: number;
}

export function SelectTicketFilterV2({ title, label }: SelectFilterModalPropsV2) {
  const [searchParams] = useSearchParams();

  const { setIsOpenFilterModalTicket } = useFiltersContext();

  const event = searchParams.get("tickets") ?? "";

  const { data: dataTickets } = useAuthTicketsByEventsId<EventTicketInterface>([
    String(event ?? ""),
  ]);

  const tickets = searchParams.get("tickets") ?? "";

  const handleOpenModal = () => {
    setIsOpenFilterModalTicket(true);
  };

  const value = dataTickets?.results?.filter(e => tickets.split("%").includes(String(e._id))) ?? [];

  return (
    <Container>
      <SelectV2
        boxIcon={<RiCalendarCheckFill color={Colors.secondary30} />}
        icon={
          <RiCoupon2Line
            color={Colors.primary100}
            size={18}
          />
        }
        options={[]}
        placeholder={title}
        label={label ?? ""}
        open={false}
        asModalOpener
        onClick={handleOpenModal}
        value={value?.length > 0 ? value?.map(val => val.name).join(",") : undefined}
      />
    </Container>
  );
}
