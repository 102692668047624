export enum EAdminStatus {
  PENDING = "pending",
  REJECTED = "rejected",
  REQUIRED_CHANGES = "required_changes",
  AVAILABLE = "available",
}

export type Category = {
  id: number;
  _id: string;
  name: string;
  created_at: string;
  updated_at: string;
  status: boolean;
  thumb: string;
  photo: string;
  cover: string;
  cover_thumb: string;
  icon: string;
  description: string;
};

export interface Categories {
  data: Category[];
}

export type EventType = {
  id: string;
  isOpen: boolean;
  name: string;
  photo: string;
  thumb: string;
  nickname: string;
  begin_hour: string;
  begin_date: string;
  formattedDate: string;
  formattedHour: string;
};

export type EventOrganizer = {
  _id: string;
  isOpen: boolean;
  address_city: string;
  address_country: string;
  address_number: string;
  address_state: string;
  address_district: string;
  address_street: string;
  address_zipcode: string;
  age: number;
  begin_date: string;
  begin_hour: string;
  checkout_pxpay_id: string;
  description: string;
  end_date: string;
  highlight: boolean;
  name: string;
  nickname: string;
  photo: string;
  privacity: string;
  use_terms: string;
  require_terms: boolean;
  status: boolean;
  type_event: number;
  user_id: string;
  address_name: string;
  streaming_id: null | string;
  streaming_name: null | string;
  streaming_url: null | string;
  thumb: string;
  support_email: null | string;
  support_whatsapp: null | string;
  slider: boolean | null;
  teams: [];
  categories: Category[];
  purchases: [];
  tickets: Array<string>;
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
  formattedDate: string;
  formattedHour: string;
  formattedDay: string;
  organizer: {
    _id: string;
    business_email: string;
    description: string;
    name: string;
    nickname: string;
  };
  admin_status?: EAdminStatus;
  admin_status_detail?: string;
};

export type EventTypeOrganizer = {
  id: string;
  isOpen: boolean;
  organizer_id: number;
  user_id: number;
  type_event: number;
  privacity: string;
  age: number;
  sales: number;
  photo: string;
  thumb: string;
  checkout_pxpay_id: string;
  name: string;
  description: string;
  begin_date: string;
  end_date: string;
  begin_hour: string;
  end_hour: string;
  support_whatsapp: string;
  support_email: string;
  address_name: string;
  address_zipcode: string;
  address_street: string;
  address_country: string;
  address_district: string;
  address_city: string;
  address_state: string;
  address_number: number;
  address_map: boolean;
  status: boolean;
  created_at: string;
  updated_at: string;
  nickname: string;
  creator: {
    name: string;
    email: string;
    cell_phone: string;
    last_name: string;
    id: number;
  };
  tickets: [
    {
      id: number;
      name: string;
      event_id: string;
    }
  ];
  organizer: {
    name: string;
    document: string;
    description: string;
    fee: number;
    id: number;
  };
  categories: [
    {
      id: number;
      name: string;
      status: boolean;
      thumb: string;
    }
  ];
  formattedDate: string;
  formattedHour: string;
  formattedDay: string;
};

export type Faq = {
  id: number;
  category: string;
  title: number;
  description: string;
  status: number;
  created_at: string;
  updated_at: boolean;
};

export type TicketEvent = {
  _id: string | number;
  id: number;
  name: string;
  description: string;
  price: number;
  created_at: string;
  formattedDate: string;
  quantity: number;
  isOpen: boolean;
  event_id: number;
  type_ticket: number;
  type_sale: number;
  discount: number;
  sale_ticket: number;
  sale_begin_date: string;
  sale_end_date: string;
  sale_begin_hour: string;
  sale_end_hour: string;
  begin_hour: string;
  formattedBeginHour: string;
  formattedEndHour: string;
  end_hour: string;
  transfer: boolean;
  available_in_cart: boolean;
  status: boolean;
  absorb_rate: boolean;
  create_half_price: boolean;
  half_price: string;
  half_quantity: number;
  quantity_max: number;
  quantity_min: number;
  archive: string | null;
  term_required?: boolean;
  term?: string;
  guest: boolean;
  guest_quantity: number | null;
  checked_has_arquive: boolean;
  type_arquive: number;
};

export type Event = {
  _id: any;
  id: number;
  name: string;
  nickname: string;
  type_event: number;
  streaming_id: string;
  streaming_name: string;
  streaming_url: string;
  support_email: string | null;
  support_whatsapp: string | null;
  age: number;
  privacity: string;
  photo: string;
  thumb: string;
  description: string;
  begin_date: string;
  begin_hour: string;
  end_date: string;
  end_hour: string;
  formattedBeginDate: string;
  formattedEndDate: string;
  private_code: string | null;
  tickets: TicketEvent[];
  address_name: string;
  address_zipcode: string;
  address_street: string;
  address_country: string;
  address_district: string;
  address_complement: string;
  address_city: string;
  address_state: string;
  address_number: number;
  formattedDate: string;
  formattedHour: string;
  formattedBeginHour: string;
  formattedEndHour: string;
  use_terms: string;
  require_terms: boolean;
  organizer: {
    _id: string;
    id: number;
    name: string;
    description: string;
  };
  creator: {
    email: string;
    cell_phone: string;
  };
  categories: Category[];
  fee: number;
  admin_status?: EAdminStatus;
  admin_status_detail?: string;
};

export interface Events {
  data: Event[];
}

export interface TicketType {
  deleted_at: string | null;
  _id: string;
  ticket_code: string;
  event: Event;
  user: string;
  event_ticket_id: {
    _id: string;
    name: string;
    description: string;
  };
  purchase_id: string;
  holder_name: string;
  holder_email: string;
  holder_cpf: string;
  checkin: boolean;
  checkin_at: string | null;
  checkout: boolean;
  checkout_at: string | null;
  status: boolean;
  type: number;
  ticket: number;
  isOpen: boolean;
  created_at: string;
  updated_at: string;
  formattedDate: string;
}

interface Ticket {
  price: number;
  discount: number;
}

type DiscountedEvent = {
  id: number;
  name: string;
  nickname: string;
  photo: string;
  thumb: string;
  tickets: Ticket[];
  categories: Category[];
};

export interface DiscountedEvents {
  data: DiscountedEvent[];
}

type CategoryType = {
  id: string;
  name: string;
};

export type FeaturedEvent = {
  id: number;
  name: string;
  nickname: string;
  photo: string;
  thumb: string;
  description: string;
  begin_date: string;
  begin_hour: string;
  address_state: string;
  type_event: number;
  address_city: string;
  formattedDate: string;
  formattedDay: string;
  categories: CategoryType[];
  tickets: Ticket[];
  organizer: {
    name: string;
  };
};

export interface FeaturedEvents {
  data: FeaturedEvent[];
}

export type Address = {
  _id: string;
  user_id: number;
  address_zipcode: string;
  address_street: string;
  address_country: string;
  address_district: string;
  address_number: string;
  address_state: string;
  address_city: string;
  address_complement: string;
  default: boolean;
  created_at: string;
  updated_at: string;
  address_title: string;
  status: boolean;
};

export interface Addresses {
  addresses: Address[];
}

interface EventPurchase {
  id: string;
  name: string;
  nickname: string;
  photo: string;
  begin_date: string;
}
interface MyPurchases {
  id: number;
  quantity: number;
  name: string;
  total: number;
}

// export interface Purchase {
//   id: number;
//   purchase_code: number;
//   purchase_status: string;
//   total: number;
//   total_fee: number;
//   total_wet: number;
//   type_payment: "credit-card" | "boleto" | "courtesy";
//   event: EventPurchase;
//   isAfter: boolean;
//   purchases: MyPurchases[];
//   created_at: string;
//   formattedDate: string;
//   formattedDay: string;
//   formattedHour: string;
// }

export interface Purchase {
  deleted_at: string | null;
  _id: string;
  user_id: string;
  user_ip: string | null | number;
  event_id: Event;
  purchase_code: string | null;
  checkout_url: string;
  coupon: boolean;
  total_fee: number;
  total_installments: number;
  total_without_rate: number;
  total_wet: number;
  total: number;
  payment_type:
    | "pix"
    | "credit-card"
    | "boleto"
    | "courtesy"
    | "webhook"
    | "manual"
    | "import";
  payment_status: "PAID" | "PENDING" | "REFUND" | "CHARGEBACK";
  created_at: string;
  refunded_at: string | null;
  updated_at: string;
  formattedDate: string;
  formattedDay: string;
  formattedHour: string;
  isAfter: boolean;
  type: string;
}

export interface City {
  nome: string;
}

export type Team = {
  _id: string;
  role?: string;
};

export type UserType = {
  _id: string;
  name: string;
  last_name: string;
  email: string;
  cell_phone: string;
  document: string;
  birthdate: string;
  formattedBirthdate: string;
  type_document: string;
  verified: boolean;
  deleted_at: string | null;
  gender: string;
  avatar: string;
  organizers: string[];
  teams: Team[];
  created_at: string;
  updated_at: string;
  role: string;
};

export type Meta = {
  total: number;
  last_page: number;
};

export interface InformationCreateEvent {
  name: string;
  image: string;
  nickname: string;
}

export interface DataCreateEvent {
  begin_date: string;
  end_date: string;
  begin_hour: string;
  end_hour: string;
}

export interface LocalCreateEvent {
  type_event: number;
  address_name: string;
  address_zipcode: string;
  address_complement: string;
  address_street: string;
  address_country: string;
  address_district: string;
  address_city: string;
  address_state: string;
  address_number: number;
  address_map: boolean;
  streaming_id: string;
  streaming_name: string;
  streaming_url: string;
}

export interface DescriptionCreateEvent {
  description: string;
  categories: Category[] | [];
  privacity: number;
  age: string;
  use_terms: string;
  require_terms: boolean;
}

export interface OrganizerCreateEvent {
  // organizer: {
  //   name: string;
  //   description: string;
  // };
  // creator: {
  //   email: string;
  //   cell_phone: string;
  // };
  checked_email: boolean;
  checked_whatsapp: boolean;
  support_email: string;
  support_whatsapp: string;
}
export interface TicketCreateEvent {
  name: string;
  price: number;
  quantity: number;
}

export interface EventResponse {
  id: string | null;
  nickname: string | null;
}

export interface Platform {
  id: string;
  name: string;
  image: string;
}

export type CreateLocalEventFormData = {
  type_event: number;
  streaming_id: string;
  streaming_url: string;
  streaming_name: string;
  address_name: string;
  address_zipcode: string;
  address_street: string;
  address_complement: string;
  address_country: string;
  address_district: string;
  address_city: string;
  address_state: string;
  address_number: number;
};

export type CreateAboutOrganizerEventFormData = {
  checked_email: boolean;
  checked_whatsapp: boolean;
  support_email: string;
  support_whatsapp: string;
  organizer: string;
};

export type CreateInformationEventFormData = {
  name: string;
  image: string;
  nickname: string;
};

export type CreateDataEventFormData = {
  begin_date: string;
  end_date: string;
  begin_hour: string;
  end_hour: string;
};

export type CreateDescriptionEventFormData = {
  description: string;
  categories: Category[] | [];
  privacity: number;
  age: string;
  require_terms: boolean;
  use_terms: string;
};

export type CreateTicketEventFormData = {
  event_id: number;
  type_ticket: number;
  type_sale: number;
  name: string;
  description: string;
  quantity: number;
  free_ticket: boolean;
  price: string;
  discount: number;
  sale_ticket: number;
  sale_begin_date: string;
  sale_end_date: string;
  sale_begin_hour: string;
  sale_end_hour: string;
  transfer: boolean;
  available_in_cart: boolean;
  status: boolean;
  absorb_rate: boolean;
  create_half_price: boolean;
  half_price: string;
  half_quantity: number;
  quantity_max: number;
  quantity_min: number;
  term_required: boolean;
  term: string;
  archive: string | null;
  guest: boolean;
  guest_quantity: number | null;
  checked_has_arquive: boolean;
  type_arquive: number;
};

export type ImageDocument = {
  name: string;
  encode: string | ArrayBuffer | null;
};

export type IdentificationResponseOrganizer = {
  organizerId?: string;
  name?: string;
};

export type IdentificationCreateOrganizer = {
  type_document: number;
  name?: string;
  nickname: string;
  document?: string;
  description?: string;

  agent_name?: string;
  agent_document?: string;
  agent_birthdate: string;
  agent_email: string;
  agent_phone: string;

  business_document?: string;
  document_name?: string;
  document_city?: string;

  address_zipcode?: string;
  address_country?: string;
  address_state?: string;
  address_disctrict?: string;
  address_city?: string;
  address_street?: string;
  address_number?: string;
  address_complement?: string;

  business_email?: string;
  business_phone?: string;
};

export type DocumentCreateOrganizer = {
  has_uploaded_identify_front: string;
  has_uploaded_identify_back: string;
  has_uploaded_proof_of_address: string;
  has_uploaded_selfie_holding_document: string;
};

export interface CreateBankDataOrganizerFormData {
  bank: string;
  type_account: string;
  agency: string;
  account: string;
  digit: string;
  holder_name: string;
}

export type Organizer = {
  id: number;
  _id: number;
  user_id: number;
  agent_name: string;
  agent_document: string;
  agent_birthdate: string;
  agent_email: string;
  agent_phone: string;
  name: string;
  description: string;
  type_document: number;
  document: string;
  document_name: string;
  document_city: string;
  address_zipcode: string;
  address_street: string;
  address_country: string;
  address_district: string;
  address_number: string;
  address_state: string;
  address_city: string;
  address_complement: string;
  business_name: string;
  business_email: string;
  business_phone: string;
  status: boolean;
  pxpay_id: number;
  created_at: string;
  updated_at: string;
  fee: 9;
  nickname: string;
};

export type OverviewTotal = {
  _id: "PAID" | "PENDING" | "REFUND";
  total: number;
  fee: number;
};

export type BalanceSummary = {
  PENDING: number;
  PAID: number;
  REFUND: number;
};

export type TransactionTicket = {
  id: string;
  ticket_id: number;
  purchase_id: number;
  ticket: {
    id: number;
    event_id: string;
    name: string;
    price: number;
    discount: number;
  };
};

export type ItemTransaction = {
  _id: string;
  purchase: string;
  event_ticket_id: string;
  name: string;
  price: number;
  fee: number;
  quantity: number;
  discount: number;
  total: number;
  status: boolean;
  deleted_at: null;
  created_at: string;
  updated_at: string;
  __v: number;
};

export type Transaction = {
  _id: string;
  id: number;
  affiliate_id: string;
  total: number;
  isOpen: boolean;
  formattedTotal: string;
  purchase_code: string;
  checkout_url: string;
  total_installments: number;
  formattedTotalInstallments: string;
  total_fee: number;
  total_paid: number;
  subtotal: number;
  formattedTotalFee: string;
  total_without_rate: number;
  formattedWithoutRate: string;
  formattedSubtotal: string;
  total_wet: number;
  formattedTotalWet: string;
  type_payment: string;
  payment_type: string;
  coupon: boolean;
  formattedDate: string;
  formattedDateAndTime: string;
  created_at: string;
  updated_at: string;
  refunded_at: string;
  event_id: {
    id: string;
    name: string;
    begin_date: string;
    formattedDate: string;
    formattedBeginTimeAndDate: string;
    end_date: string;
    begin_hour: string;
    end_hour: string;
    thumb: string;
    photo: string;
  };
  user_id: {
    _id: string;
    name: string;
    email: string;
    cell_phone: string;
    document: string;
  };
  tickets: Array<TransactionTicket>;
  purchase_items: ItemTransaction[];
  status: "PAID" | "PENDING" | "REFUND" | "CHARGEBACK";
  payment_status: "PAID" | "PENDING" | "REFUND" | "CHARGEBACK";
  type: string;
};

export interface Document {
  _id: string;
  type: string;
  patch: string;
  organizer: string;
  created_at: string;
  updated_at: string;
  __v: 0;
}

export interface OrganizerResponse {
  organizer: {
    _id: string;
    address_city: string;
    address_country: string;
    address_number: string;
    address_state: string;
    address_street: string;
    address_zipcode: string;
    agent_birthdate: string;
    formmatedAgentBirthdate: string;
    business_email: string;
    description: string;
    document: string;
    name: string;
    nickname: string;
    fee: number;
    type_document: number;
    user: string;
    address_complement: string;
    address_disctrict: string;
    agent_document: string;
    business_phone: string;
    business_document: string;
    document_city: string;
    agent_email: string;
    document_name: string;
    agent_name: string;
    agent_phone: string;
    manager_id: string;
    created_at: string;
    updated_at: string;
    __v: number;
  };
  bankAccounts: {
    _id: string;
    account: string;
    agency: string;
    bank_id: string;
    default: boolean;
    digit: string;
    holder_name: string;
    organizer_id: string;
    type_account: number;
  }[];
  documents: Document[];
}

export interface TicketUserByIdResponse {
  _id: string;
  ticket_code: string;
  user: {
    _id: string;
  };
  event: {
    _id: string;
    address_city: string;
    address_country: string;
    address_number: string;
    address_state: string;
    address_district: string;
    address_street: string;
    address_zipcode: string;
    age: number;
    begin_date: string;
    formattedDate: string;
    description: string;
    end_date: string;
    name: string;
    nickname: string;
    organizer: string;
    privacity: string;
    use_terms: string;
    require_terms: boolean;
    status: boolean;
    type_event: number;
    user_id: string;
    address_name: string;
    begin_hour: string;
    formattedHour: string;
    end_hour: string;
    streaming_id: string;
    streaming_name: string;
    streaming_url: string;
    support_email: string;
    support_whatsapp: string;
    deleted_at: null;
    created_at: string;
    updated_at: string;
    __v: 0;
    photo: string;
    thumb: string;
    purchases: string;
  };
  event_ticket_id: {
    name: string;
  };
  purchase_id: string;
  holder_name: string;
  holder_email: string;
  holder_cpf: string;
  checkin: boolean;
  checkout: boolean;
  status: boolean;
  type: number;
  deleted_at: null;
  created_at: string;
  updated_at: string;
}

export type OrganizerBalance = {
  id: string;
  availableAmount: number;
  pendingAmount: number;
};

export type BalanceOrganizer = {
  totalAvailableAmount: number;
  totalPendingAmount: number;
  organizer: OrganizerBalance[];
};

export type TicketChart = {
  checkin_percentage: number,
  checkout_percentage: number,
  event_id: string,
  event_name: string
}
