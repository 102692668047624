import { RiCheckboxCircleLine, RiTimeLine } from "react-icons/ri";

export const PAYMENT_STATUS_MOCK = [
  {
    label: "Pago",
    value: "PAID",
    icon: <RiCheckboxCircleLine color="#93C77B" size={18} />,
  },
  {
    label: "Pendente",
    value: "PENDING",
    icon: <RiTimeLine color="#EFA36C" size={18} />,
  },
];
