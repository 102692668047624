import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import MyEvents from "pages/MyEvent";
import MyEvent from "pages/MyEvent/Detail";
import MyEventIntegration from "pages/MyEvent/Detail/Integration";
import AccessData from "pages/Profile/AcessData";
import Address from "pages/Profile/Address";
import MyPurchase from "pages/Profile/MyPurchase";
import MyTicket from "pages/Profile/MyTicket";
import PersonalData from "pages/Profile/PersonalData";
import Profile from "pages/Profile";

import AddressCreate from "pages/Profile/Address/New";
import AddressEdit from "pages/Profile/Address/Edit";
import TermsCancel from "pages/TermCancel";
import EventNew from "pages/MyEvent/New";
import Financial from "pages/Financial";
import { FRONTLINE_URL } from "constants/environmentVariables";
import NotFound from "pages/NotFound";
import TicketOffice from "pages/TicketOffice";
import TransactionDetail from "pages/Financial/components/Tabs/Transaction/Detail";
import Edit from "pages/MyEvent/Edit";
import OrganizerCadaster from "pages/OrganizerCadaster";
import OrganizerData from "pages/OrganizerProfile/Data";
import OrganizerProfile from "pages/OrganizerProfile";
import OrganizerFinancial from "pages/OrganizerProfile/Financial";
import { NewOrganizerAccount } from "pages/OrganizerProfile/Financial/New";
import { EditOrganizerAccount } from "pages/OrganizerProfile/Financial/Edit";
import OrganizerDocument from "pages/OrganizerProfile/Document";
import { useSecureCookieStorage } from "hooks/useSecureCookieStorage";
import Teams from "pages/Teams";
import TeamRegister from "pages/TeamRegister";
import CollaboratorRegister from "pages/CollaboratorRegister";
import TeamEdit from "pages/TeamEdit";
import TermsCancelByOrganizer from "pages/TermCancelByOrganizer";
import CheckInOutNew from "pages/CheckInOutNew";

export default function RoutesPage() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/profile" />} />

      <Route path="/my-event" element={<PrivateOutlet />}>
        <Route index element={<MyEvents />} />
        <Route path=":id" element={<MyEvent />} />
        <Route path=":id/:ref" element={<MyEvent />} />
        <Route path=":nickname/integration" element={<MyEventIntegration />} />
        <Route path="new" element={<EventNew />} />
        <Route path=":nickname/edit" element={<Edit />} />
      </Route>

      <Route path="/financial">
        <Route index element={<Financial />} />
        <Route path=":transaction_id" element={<TransactionDetail />} />
      </Route>

      <Route path="profile" element={<PrivateOutlet />}>
        <Route index element={<Profile />} />
        <Route path="personal-data" element={<PersonalData />} />
        <Route path="access-data" element={<AccessData />} />
        <Route path="my-ticket" element={<MyTicket />} />
        <Route path="my-shopping" element={<MyPurchase />} />

        <Route path="terms-cancel/:purchase_id" element={<TermsCancel />} />

        <Route
          path="terms-cancel-by-organizer/:purchase_id"
          element={<TermsCancelByOrganizer />}
        />

        <Route path="address">
          <Route index element={<Address />} />
          <Route path="create" element={<AddressCreate />} />
          <Route path="edit/:id" element={<AddressEdit />} />
        </Route>
      </Route>

      <Route path="organizer" element={<PrivateOutlet />}>
        <Route path="new" element={<OrganizerCadaster />} />

        <Route path="profile">
          <Route path="organizer-data/:id" element={<OrganizerData />} />

          <Route path="financial/:id" element={<OrganizerFinancial />} />

          <Route path="main/:id" element={<OrganizerProfile />} />

          <Route path="document/:id" element={<OrganizerDocument />} />

          <Route
            path="financial/:id/create"
            element={<NewOrganizerAccount />}
          />

          <Route
            path="financial/:id/edit/:account_id"
            element={<EditOrganizerAccount />}
          />
        </Route>

        {/* <Route path="ticket-check" element={<CheckInOut />} /> */}

        <Route path="ticket-check" element={<CheckInOutNew />} />
      </Route>

      <Route path="/ticket-office" element={<TicketOffice />} />
      <Route path="*" element={<NotFound />} />
      <Route path="organizer" element={<PrivateOutlet />}>
        <Route path="new" element={<OrganizerCadaster />} />
      </Route>

      <Route path="/ticket-office" element={<TicketOffice />} />

      <Route path="teams" element={<PrivateOutlet />}>
        <Route index element={<Teams />} />
        <Route path="register" element={<TeamRegister />} />
        <Route path=":id/edit" element={<TeamEdit />} />
        <Route path=":id/collaborators">
          <Route path="new" element={<CollaboratorRegister />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function PrivateOutlet() {
  const secureCookieStorage = useSecureCookieStorage();

  const isAuthenticated = secureCookieStorage.getItem("@ventX-user_token");

  if (!isAuthenticated) {
    if (!FRONTLINE_URL) return null;

    window.location.href = `${FRONTLINE_URL}/signin?page=signin&to=account`;
  }

  return <Outlet />;
}
