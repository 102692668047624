import { useEffect } from "react";
import { ModalStyled, StyledIframe } from "./styles";

interface ModalPaymentProps {
  checkoutId: string;
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}

export function ModalPayment({
  checkoutId,
  handleCancel,
  handleOk,
  isModalVisible,
}: ModalPaymentProps) {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { action, url } = event.data;

      if (action === "redirect" && url) {
        handleCancel();

        window.location.href = url;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);
  }, [handleCancel]);

  return (
    <ModalStyled
      width={1200}
      visible={isModalVisible}
      style={{ borderRadius: "20px", marginTop: "-90px" }}
      onCancel={handleCancel}
      closeIcon={null}
      closable={false}
      footer={null}
    >
      <StyledIframe
        src={`${process.env.REACT_APP_PAYMENT_CHECKOUT_DOMAIN}/${checkoutId}`}
      />
    </ModalStyled>
  );
}
