import { SVGProps } from "react";

const PixIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#D40050"
      fillRule="evenodd"
      d="M16.481 7.282c.3.3.577.577.79.828.23.27.455.589.587.995.19.582.19 1.208 0 1.79-.132.407-.358.726-.587.995-.213.25-.49.528-.79.829l-3.763 3.762c-.265.274-.542.537-.828.79-.269.23-.589.455-.996.587a2.9 2.9 0 0 1-1.789 0c-.406-.132-.725-.358-.996-.587a15.07 15.07 0 0 1-.827-.79l-3.763-3.763a14.964 14.964 0 0 1-.79-.828c-.229-.269-.455-.588-.587-.996a2.892 2.892 0 0 1 0-1.789c.132-.406.358-.725.587-.995.212-.25.489-.527.79-.828l3.763-3.763c.3-.3.577-.577.827-.79.27-.229.59-.455.996-.587a2.892 2.892 0 0 1 1.79 0c.407.132.726.358.995.587.25.213.527.489.829.79l3.762 3.763Zm-1.953-.198 1.045 1.045c.655.655.983.983 1.105 1.36.109.332.109.69 0 1.022-.122.378-.45.706-1.105 1.36l-1.045 1.045h-1.26a.668.668 0 0 1-.499-.233l-1.621-1.784a1.55 1.55 0 0 0-2.295 0L7.23 12.683a.666.666 0 0 1-.497.233H5.47l-1.044-1.044c-.655-.655-.983-.983-1.105-1.361a1.666 1.666 0 0 1 0-1.022c.122-.378.45-.705 1.105-1.36L5.47 7.084h1.263a.67.67 0 0 1 .497.234L8.854 9.1a1.55 1.55 0 0 0 2.295 0l1.621-1.783a.666.666 0 0 1 .498-.234h1.26Zm-1.24-1.24h-.02c-.544 0-1.052.238-1.417.639l-1.62 1.784a.309.309 0 0 1-.46 0L8.15 6.484a1.917 1.917 0 0 0-1.417-.64h-.02l1.417-1.417c.655-.655.983-.983 1.36-1.105.333-.108.69-.108 1.022 0 .378.122.706.45 1.36 1.106l1.417 1.416Zm-.02 8.313h.02l-1.417 1.416c-.654.655-.982.983-1.36 1.105-.332.109-.69.109-1.022 0-.378-.122-.705-.45-1.36-1.105l-1.417-1.417h.02c.544 0 1.052-.238 1.417-.638l1.622-1.785a.309.309 0 0 1 .458 0l1.623 1.784a1.916 1.916 0 0 0 1.416.64"
      clipRule="evenodd"
    />
  </svg>
);

export default PixIconSmall;
