import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { IoCloseCircleSharp } from "react-icons/io5";

import { Colors } from "constants/colors";

import {
  BorderSelect,
  Container,
  DivIsSelected,
  Label,
  SelectStyledWithoutDrop,
} from "./styles";
import { KeySort } from "constants/filters";
import { useFiltersContext } from "contexts/FiltersContext";

interface SelectFilterModalProps {
  title: string;
  sortKey: KeySort;
  label?: string;
  onClearEvents?: () => void;
  showSelectedLenght?: boolean;
}

// pegar evento selecionado por parametro no caso -> event_name
export function SelectEventFilter({
  title,
  label,
  sortKey,
  onClearEvents,
  showSelectedLenght = true,
}: SelectFilterModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setIsOpenFilterModalEvent } = useFiltersContext();

  const events = searchParams.get("events") ?? "";

  const handleClearEventsFromFilter = useCallback(() => {
    searchParams.delete("events");
    onClearEvents!();
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams, onClearEvents]);

  const handleOpenModal = () => {
    setIsOpenFilterModalEvent(true);
  };

  const eventsLength = events?.split(",").length;

  return (
    <Container>
      {label ? <Label>{label}</Label> : null}
      {events === "" || !showSelectedLenght ? (
        <BorderSelect>
          <SelectStyledWithoutDrop
            bordered={false}
            labelInValue
            size="large"
            defaultValue={{ value: 0, label: title }}
            open={false}
            onClick={handleOpenModal}
            placeholder={title}
          />
        </BorderSelect>
      ) : (
        <DivIsSelected>
          <h3 className="selected">{eventsLength} Eventos selecionados</h3>

          <IoCloseCircleSharp
            onClick={handleClearEventsFromFilter}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      )}
    </Container>
  );
}
