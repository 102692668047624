import styled from "styled-components";

import { Colors } from "constants/colors";
import { Modal, Select } from "antd";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 44px 39px 64px 39px;

  @media (max-width: 800px) {
    padding: 24px 10px 24px 10px;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  h1 {
    width: 100%;
    color: ${Colors.secondary100};
    text-align: center;
    font-family: Poppins;
    font-size: 1.87rem;
    font-style: normal;
    font-weight: 600;

    @media (max-width: 800px) {
      font-size: 20px;
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  border-radius: 20px;
  border: 1px solid ${Colors.black10};
  background: ${Colors.white};
  margin-top: 2.62rem;
  padding: 2.34rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid ${Colors.secondary10};
  margin-top: 2.39rem;
`;

export const Title = styled.h2`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
`;

export const TitleLast = styled.h3`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.21rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 2.34rem;
`;

export const GridItems = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  margin-top: 1.4rem;
  grid-gap: 10px;
`;

export const Item = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  padding: 0.93rem 0.56rem 0.93rem 0.93rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.65rem;
  border: 1px solid ${Colors.black10};
  background: ${Colors.white};

  div.column-content {
    display: flex;
    flex-direction: column;
  }
`;

export const TitleTotal = styled.h4`
  color: ${Colors.black30};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
`;

export const TextTotal = styled.h5`
  color: ${Colors.secondary100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const TextTotalSmall = styled.h5`
  color: ${Colors.secondary100};
  font-family: Cairo;
  font-size: 1.21rem;
  font-style: normal;
  font-weight: 600;
`;

export const SubTitle = styled.h6`
  color: ${Colors.black50};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.5rem;
`;

export const ContainerTable = styled.div`
  margin-top: 1.4rem;

  table td {
    border-right-width: 0px;
    border-bottom-width: 0px;

    h5 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 0.93rem;
      margin-bottom: 0px;
      margin-top: 0px;

      color: ${Colors.black80};
    }
  }

  table {
    width: 100%;

    min-width: 600px;
    border-style: hidden;

    border-collapse: separate;
    border-spacing: 0 11px;

    th {
      background-color: ${Colors.white};
      padding: 1rem;
      text-align: left;
      color: ${Colors.white};
      font-size: 0.875rem;
      line-height: 1.6;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      margin-bottom: 0px;

      color: ${Colors.black30};

      &:first-child {
        border-top-left-radius: 8px;
        padding-left: 1.5rem;
      }

      &:last-child {
        border-top-right-radius: 8px;
        /* padding-right: 1.5rem; */
      }
    }

    td {
      padding: 11px;
      background: ${Colors.lightgray10};

      h6 {
        color: ${Colors.black70};
        font-family: Cairo;
        font-size: 0.93rem;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 0px;
      }

      h6.green {
        color: ${Colors.success};
        font-family: Open Sans;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0px;
      }
    }

    td:first-child {
      /* border-left-style: solid; */
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;

      img {
        border-radius: 6px;
      }

      div.row {
        display: flex;
        flex-direction: row;

        div.column {
          display: flex;
          flex-direction: column;
          margin-left: 7px;

          h5 {
            font-family: "Cairo";
            font-style: normal;
            font-weight: 700;
            font-size: 0.93rem;
            margin-bottom: 0px;
            margin-top: 0px;

            color: ${Colors.black80};
          }
        }
      }
    }

    td:last-child {
      border-bottom-right-radius: 11px;
      border-top-right-radius: 11px;
    }

    tbody {
      .table-link {
        display: contents;
      }

      tr {
        display: table-row;
        border-radius: 0.93rem 0px 0px 0.93rem;
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }
      tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }
    }

    td {
      margin-bottom: 20px;
      padding: 1rem;
      font-size: 0.875rem;
      line-height: 1.6;

      div.date {
        display: flex;
        flex-direction: column;

        h5 {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 700;
          font-size: 0.93rem;
          margin-bottom: 0px;
          margin-top: 0px;

          color: ${Colors.black80};
        }

        h6 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          margin-bottom: 0px;
          margin-top: 4px;
          color: ${Colors.black70};
        }
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        margin-bottom: 0px;

        color: ${Colors.black70};
      }

      margin-bottom: 0.56rem;

      &:first-child {
        padding-left: 1.5rem;
        border-top-left-radius: 8px;
      }

      &:last-child {
        padding-right: 0.5rem;
        border-radius: 0px 0.93rem 0.93rem 0px;
      }
    }
  }
`;

export const ListAccordion = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div + div {
    margin-top: 18px;
  }
`;

interface CardAccordionProps {
  active: boolean;
}

export const CardAccordion = styled.div<CardAccordionProps>`
  cursor: pointer;
  width: 100%;

  /* min-height: 111px; */
  height: 5.2rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: ${(props) => (props.active ? Colors.black5 : Colors.white)};

  border: 1px solid ${Colors.black10};
  box-shadow: 0px 4px 20px rgba(18, 25, 34, 0.05);
  border-radius: 0.93rem;

  padding: 0.98rem 1.92rem 1.17rem 1.96rem;

  div.content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    div.image {
      margin-top: 7px;
    }

    div.texts {
      height: 100%;
      display: flex;
      flex-direction: column;

      justify-content: flex-start;
      margin-left: 23px;

      h2 {
        font-weight: 700;
        font-size: 0.93rem;
        margin-bottom: 0px;

        color: ${Colors.secondary100};
      }

      p {
        font-weight: 600;
        font-size: 0.84rem;
        margin-bottom: 0px;
        color: ${Colors.secondary40};
      }
    }
  }
`;

export const DataDropDown = styled.div`
  width: 100%;
  z-index: 2;
  margin-bottom: 42px;
`;

export const ContainerBorder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.black10};
  box-sizing: border-box;
  border-top: 0;
  border-radius: 20px;
  padding-right: 20px;
  padding-left: 30px;
  margin-top: -45px;
  position: relative;

  div.all {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.4rem;

    div.content {
      max-width: 600px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 70px;
      height: 105px;
      padding-bottom: 23px;
      border-bottom: 1px solid ${Colors.secondary10};

      &:last-child {
        margin-right: 8px;
      }

      div.column {
        display: flex;
        flex-direction: column;

        h2 {
          font-weight: bold;
          font-size: 0.79rem;
          margin-bottom: 0px;
          color: ${Colors.black80};
          overflow-wrap: break-word;
        }

        p {
          font-weight: normal;
          font-size: 0.7rem;
          color: ${Colors.black70};
          overflow-wrap: break-word;
          word-break: break-all;
        }
      }
    }

    div.container-image {
      margin-top: 40px;
      margin-left: 10px;

      @media (max-width: 1200px) {
        padding-left: 10px;
      }

      img {
        border-radius: 10px;
      }
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;

  button {
    cursor: pointer;
  }
`;

export const ButtonVisualizationTicket = styled.button`
  width: 190px;
  height: 47px;
  cursor: pointer !important;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${Colors.primary100};
  border-radius: 14px;
  margin-right: 10px;

  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    color: ${Colors.white};
    margin-left: 10px;
  }
`;

export const ButtonDownload = styled.button`
  cursor: pointer;
  width: 190px;
  height: 47px;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${Colors.primary10};
  border-radius: 14px;
  margin-right: 10px;

  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    margin-left: 10px;
    color: ${Colors.primary100};
  }
`;

export const ContainerSelect = styled.div``;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`;

export const TitleMonth = styled.h3`
  color: ${Colors.black30};
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const TitlePriceMonth = styled.h4`
  color: ${Colors.black80};
  font-family: Cairo;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
`;

export const ListSales = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.03rem;

  li:nth-child(even) {
    background-color: ${Colors.gray70};
    border-radius: 10px;
  }
`;

export const Sale = styled.li`
  display: flex;
  width: 100%;
  height: 2.62rem;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
`;

export const TitleDataSale = styled.h3`
  color: ${Colors.black80};
  font-family: Open Sans;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
`;

export const TitleTotalSale = styled.h4`
  color: ${Colors.black80};
  font-family: Poppins;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
`;

export const TitleTotalSalePrice = styled.h5`
  color: ${Colors.black80};
  font-family: Cairo;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const ButtonReport = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 12.81rem;
  height: 2.81rem;

  background: ${Colors.primary10};
  border-radius: 0.65rem;

  @media (max-width: 670px) {
    display: none;
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-bottom: 0px;

    color: ${Colors.primary100};
    margin-left: 10px;
  }

  img {
    width: 1.12rem;
    height: 1.12rem;
  }
`;

export const ButtonLeft = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
  background: ${Colors.primary10};
`;

export const BorderSelect = styled.div`
  border: 2px solid ${Colors.secondary20};
  width: 100%;
  height: 2.81rem;
  border-radius: 0.37rem;
  margin-right: 0.51rem;
  display: flex;
  margin-top: 9px;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0.45rem;

    height: 32px;
    border-radius: 6px;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .ant-select-selection-item {
    color: ${Colors.secondary70};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const LableTitle = styled.h3`
  width: 100%;
  color: ${Colors.secondary40};
  font-family: Poppins;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 14px;
`;
