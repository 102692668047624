import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 80px;

  @media (max-width: 1024px) {
    padding: 24px 8px;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  > h1 {
    font-family: "Poppins";
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: ${Colors.secondary100};
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  border: 1px solid #e7e7ea;
  width: 100%;
  border-radius: 20px;
  padding: 50px;
  margin-top: 56px;

  > h2 {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: ${Colors.secondary100};
  }

  @media (max-width: 1024px) {
    border: none;
    padding: 0;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #e9e7ee;
  margin: 40px 0;
`;

export const Label = styled.span`
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: ${Colors.secondary100};

  margin-bottom: 20px;
`;

export const InputArea = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 40px;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  max-width: 745px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const FormActionContainer = styled.div`
  margin-top: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    row-gap: 16px;
  }
`;

export const ButtonBase = css`
  cursor: pointer;

  max-width: 250px;
  width: 100%;
  padding: 10px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  border-radius: 14px;

  font-family: "Cairo";
  font-size: 1.25rem;
  line-height: 2.6rem;
  font-weight: 700;
  border: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 720px) {
    height: 40px;
    padding: 0;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.2;
  }
`;

export const ConfirmButton = styled.button`
  ${ButtonBase}

  color: ${Colors.white};
  box-shadow: 0px 15px 30px 0px #d4005026;
  background-color: ${Colors.primary100};
`;

export const CancelButton = styled.button`
  ${ButtonBase}

  color: ${Colors.primary100};
  background-color: ${Colors.primary10};
`;
