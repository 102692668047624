import { Link } from "react-router-dom";

import { IoMdCloseCircle } from "react-icons/io";
import { MdCheckCircle } from "react-icons/md";

import { Colors } from "constants/colors";
import { EventOrganizer } from "types";

import {
  BadgeMy,
  CategoryMy,
  Container,
  InativeBadgeMy,
  StatusWrapper,
  StatusContainer,
  StatusComponent,
} from "./styles";

import eyes_event_icon from "assets/eyes-event-icon.svg";
import ri_pencil_line from "assets/ri_pencil-line.svg";
import integrations_event_icon from "assets/integrations-event-icon.svg";
import share_event_icon from "assets/share-event-icon.svg";
import { FRONTLINE_URL } from "constants/environmentVariables";
import EventThumb from "./EventThumb";
import { ADMIN_STATUS } from "constants/adminStatus";
import { DropDown } from "components/DropDown";
import { FiVideo } from "react-icons/fi";
import { Streamings } from "utils/event";

interface ListTableProps {
  events: EventOrganizer[] | undefined;
  showModal(event: EventOrganizer): void;
}

export function ListTable({ events, showModal }: ListTableProps) {
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>EVENTO E AUTOR</th>
            <th>DATA E HORÁRIO</th>
            <th>LOCAL</th>
            <th>ORGANIZADOR</th>
            <th>CATEGORIA</th>
            <th>STATUS</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {events?.map((event) => {
            return (
              <tr style={{ borderRadius: 20 }} key={event?._id}>
                <td>
                  <div className="row">
                    <Link to={`/my-event/${event?._id}`}>
                      <EventThumb img={event?.thumb} />
                    </Link>

                    <div className="column">
                      <Link to={`/my-event/${event?._id}`}>
                        <h5>{event?.name}</h5>
                      </Link>
                    </div>
                  </div>
                </td>

                <td>
                  <div className="date">
                    <h5>{event?.formattedDate}</h5>

                    <h6>{event?.formattedHour}</h6>
                  </div>
                </td>

                <td>
                  {event?.type_event !== 2 ||
                  String(event?.type_event) !== "2" ? (
                    <h3>
                      {event?.address_city}, {event?.address_state}
                    </h3>
                  ) : null}

                  {event?.type_event !== 1 && event?.streaming_id !== null ? (
                    <div className="local">
                      <FiVideo size={18} color={Colors.primary100} />

                      <h3>
                        {
                          Streamings[Number(event?.streaming_id) - 1 || 0]
                            ?.label
                        }
                      </h3>
                    </div>
                  ) : null}
                </td>

                <td>
                  <h3>{event?.organizer?.name}</h3>
                </td>

                <td>
                  {event.categories.map((category, index) => (
                    <CategoryMy hasUp={index > 0}>
                      <span>{category.name}</span>
                    </CategoryMy>
                  ))}
                </td>

                <td>
                  <StatusWrapper>
                    <StatusContainer>
                      {event?.status ? (
                        <BadgeMy>
                          <MdCheckCircle color={Colors.white} />

                          <span>ATIVO</span>
                        </BadgeMy>
                      ) : (
                        <InativeBadgeMy>
                          <IoMdCloseCircle color={Colors.black50} />

                          <span>INATIVO</span>
                        </InativeBadgeMy>
                      )}

                      {!event?.status ? (
                        <InativeBadgeMy>
                          <IoMdCloseCircle color={Colors.black50} />

                          <span>INATIVO</span>
                        </InativeBadgeMy>
                      ) : null}
                    </StatusContainer>
                    {event?.admin_status ? (
                      <StatusContainer>
                        <StatusComponent status={event.admin_status}>
                          <span>
                            {ADMIN_STATUS.find(
                              (a) =>
                                a.value === event.admin_status?.toLowerCase()
                            )?.label?.toUpperCase()}
                          </span>
                        </StatusComponent>
                      </StatusContainer>
                    ) : null}
                  </StatusWrapper>
                </td>

                <td>
                  <DropDown
                    links={[
                      {
                        type: "link",
                        image: eyes_event_icon,
                        title: "Vizualizar evento",
                        link: `/my-event/${event._id}`,
                      },
                      {
                        type: "link",
                        image: ri_pencil_line,
                        title: "Editar evento",
                        link: `/my-event/${event?._id}/edit`,
                      },
                      {
                        type: "blank",
                        image: eyes_event_icon,
                        title: "Vizualizar Link do Checkout",
                        link: `${FRONTLINE_URL}/${event.nickname}`,
                      },
                      {
                        type: "link",
                        image: integrations_event_icon,
                        title: "Gerenciar integrações",
                        link: `/my-event/${event._id}/integration`,
                      },
                      {
                        type: "action",
                        image: share_event_icon,
                        title: "Compartilhar evento",
                        onClick: () => showModal(event),
                      },
                    ]}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}
