import { BiPlus } from "react-icons/bi";

import { Colors } from "constants/colors";

import { useCreateTicket } from "hooks/useCreateTicket";

import { useAuthTicketsByEventsId } from "services/Hook/Event/Ticket";

import { Event } from "types";

import { useCreateAndEditTicket } from "contexts/CreateAndEditTicketContext";

import {
  HorizontalLine,
  FormContent,
  TitleForm,
  SubTitleForm,
  LabelForm,
  TitleTicket,
} from "../../styles";

import {
  ButtonAddTicket,
  ButtonAddTicketResponsive,
  Divider,
} from "pages/MyEvent/New/Steps/Ticket/styles";

import { ListTicketByEvent } from "components/ListTicketByEvent";
import { ModalCreateAndEditTicket } from "components/ModalCreateAndEditTicket";
import { DrawerCreateAndEditTicket } from "components/DrawerCreateAndEditTicket";
import { useForm } from "react-hook-form";

interface TicketsProps {
  activeStep: number;
  data: Event;
}

export function Tickets({ activeStep, data }: TicketsProps) {
  const { setIsOpenModalTicket, setIsOpenDrawerTicket, setSelectedTicket } =
    useCreateAndEditTicket();

  const { data: dataTickets } = useAuthTicketsByEventsId([String(data?._id)]);

  const { reset } = useCreateTicket({
    event_id: String(data?.id) ?? "",
  });

  const { handleSubmit } = useForm({
    shouldUseNativeValidation: true,
  });

  return (
    <>
      <ModalCreateAndEditTicket event_id={String(data?.id)} />

      <FormContent>
        <TitleForm>{activeStep}. Ingressos</TitleForm>

        <SubTitleForm>
          Defina os detalhes referentes aos ingressos do seu evento.
        </SubTitleForm>

        <HorizontalLine />

        <LabelForm>Crie os ingressos do seu evento</LabelForm>

        <ButtonAddTicket
          type="button"
          onClick={() => {
            setSelectedTicket(null);
            setIsOpenModalTicket(true);
            reset();
          }}
        >
          <BiPlus size={18} color={Colors.primary100} />

          <span>Criar ingresso</span>
        </ButtonAddTicket>

        <ButtonAddTicketResponsive
          type="button"
          onClick={() => {
            setSelectedTicket(null);
            setIsOpenDrawerTicket(true);
            reset();
          }}
        >
          <BiPlus size={18} color={Colors.primary100} />

          <span>Criar ingresso</span>
        </ButtonAddTicketResponsive>

        {dataTickets && dataTickets?.results?.length > 0 ? (
          <>
            <Divider />

            <TitleTicket>INGRESSOS SALVOS</TitleTicket>

            <ListTicketByEvent event_id={String(data?._id) ?? ""} />
          </>
        ) : null}

        <ModalCreateAndEditTicket event_id={String(data?.id) ?? ""} />

        <DrawerCreateAndEditTicket event_id={String(data?.id) ?? ""} />
      </FormContent>
    </>
  );
}
