import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  margin-top: 2rem;

  @media (max-width: 900px) {
    display: none;
  }

  div.pagination {
    width: 100%;

    @media (max-width: 900px) {
      display: none;
    }
  }

  table td {
    border-right-width: 0px;
    border-bottom-width: 0px;

    h5 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 0.93rem;
      margin-bottom: 0px;
      margin-top: 0px;

      color: ${Colors.black80};
    }
  }

  table {
    width: 100%;

    min-width: 600px;
    border-style: hidden;

    border-collapse: separate;
    border-spacing: 0 11px;

    th {
      background-color: ${Colors.white};
      padding: 1rem;
      text-align: left;
      color: ${Colors.white};
      font-size: 0.875rem;
      line-height: 1.6;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      margin-bottom: 0px;

      color: ${Colors.black30};

      &:first-child {
        border-top-left-radius: 8px;
        padding-left: 1.5rem;
      }

      &:last-child {
        border-top-right-radius: 8px;
        padding-right: 1.5rem;
      }
    }

    td {
      border: 1px solid ${Colors.border50};
      border-style: solid none;
      padding: 11px;
    }

    td:first-child {
      border-left-style: solid;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;

      img {
        border-radius: 6px;
      }

      div.row {
        display: flex;
        flex-direction: row;

        div.column {
          display: flex;
          flex-direction: column;
          margin-left: 7px;

          h5 {
            font-family: "Cairo";
            font-style: normal;
            font-weight: 700;
            font-size: 0.93rem;
            margin-bottom: 0px;
            margin-top: 0px;

            color: ${Colors.black80};
          }

          h6 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            margin-bottom: 0px;
            margin-top: 4px;
            color: ${Colors.black70};
          }
        }
      }
    }

    td:last-child {
      border-right-style: solid;
      border-bottom-right-radius: 11px;
      border-top-right-radius: 11px;
    }

    tbody {
      tr {
        display: table-row;
        border-radius: 0.93rem 0px 0px 0.93rem;
        border: 1px solid ${Colors.black10};

        div {
        }
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }
      tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }
    }

    td {
      margin-bottom: 20px;
      padding: 1rem;
      font-size: 0.875rem;
      line-height: 1.6;

      div.date {
        display: flex;
        flex-direction: column;

        h5 {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 700;
          font-size: 0.93rem;
          margin-bottom: 0px;
          margin-top: 0px;

          color: ${Colors.black80};
        }

        h6 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          margin-bottom: 0px;
          margin-top: 4px;
          color: ${Colors.black70};
        }
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        margin-bottom: 0px;

        color: ${Colors.black70};
      }

      margin-bottom: 0.56rem;

      &:first-child {
        padding-left: 1.5rem;

        border-top-left-radius: 8px;
      }

      &:last-child {
        padding-right: 1.5rem;
        border-radius: 0px 0.93rem 0.93rem 0px;

        border-right: 1px solid ${Colors.black10};
      }
    }
  }
`;

export const DropDownEvent = styled.div`
  position: absolute;
  background-color: ${Colors.white10};
  width: 15.93rem;

  height: fit-content;

  -webkit-tap-highlight-color: transparent;

  margin-top: 0.42rem;
  z-index: 95;

  padding-left: 0.56rem;
  padding-right: 0.56rem;

  margin-left: -218px;
  border: 1px solid ${Colors.border20};

  background-color: ${Colors.white};

  box-shadow: 0px 18px 33px rgba(108, 93, 211, 0.1);
  border-radius: 0.56rem;

  ul {
    position: relative;
    padding-top: 10px;
    padding-bottom: 0px !important;
    height: 100%;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
    }

    li {
      cursor: pointer;
      width: 100%;
      height: 2.43rem;
      list-style: none;

      border-bottom: 1px solid #e1e1e1;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0px !important;

      div.circle-icon {
        width: 1.5rem;
        height: 1.5rem;
        background: ${Colors.secondary10};
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 0.89rem;
          height: 0.75rem;
        }
      }

      div.circle-icon-remove {
        width: 1.5rem;
        height: 1.5rem;
        background: ${Colors.red0};
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 0.89rem;
          height: 0.75rem;
        }
      }

      a {
        color: ${Colors.black50};
        margin-left: 0.56rem;
      }

      span {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        margin-bottom: 0px;
        margin-left: 0.56rem;
        color: ${Colors.black50};
      }
    }

    li.last {
      border-bottom: 0px;
    }

    li.exit {
      border: 0;
      margin-bottom: 0px;

      span {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        margin-bottom: 0px;
        margin-left: 0.56rem;
        color: ${Colors.pink30};
      }
    }
  }
`;

interface BoxProps {
  active?: boolean;
}

export const Box = styled.div<BoxProps>`
  cursor: pointer;
  width: 2.43rem;
  height: 2.43rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${Colors.white};
  border: 1px solid
    ${(props) => (props.active ? Colors.primary100 : Colors.black10)};
  border-radius: 0.56rem;
`;
