import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Checkbox } from "antd";

import { RiCloseCircleLine, RiCoupon2Line } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";

import type { CheckboxValueType } from "antd/es/checkbox/Group";

import { Colors } from "constants/colors";

import {
  ButtonClear,
  ButtonOk,
  ButtonSearch,
  CheckboxStyled,
  ContainerInputSearch,
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  NormalCardBorder,
} from "./styles";

import { useFiltersContext } from "contexts/FiltersContext";
import { ContainerButtonsModal } from "components/ModalFilterByData/styles";
import { TicketEvent } from "types";
import { useAuthTicketsByEventsId } from "services/Hook/Event/Ticket";

interface TicketEventInterface {
  results: TicketEvent[];
  count: number;
}

interface ModalFilterByTicketProps {
  isMultiple?: boolean;
  onSelectTickets?: (events: Array<TicketEvent>) => void;
  onClearTickets?: () => void;
  defaultEvent?: string;
}

export function ModalFilterByTicket({
  isMultiple = true,
  onSelectTickets,
  onClearTickets,
  defaultEvent = "",
}: ModalFilterByTicketProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [textSearch, setTextSearch] = useState("");

  const { isOpenFilterModalModalTicket, setIsOpenFilterModalTicket } =
    useFiltersContext();

  const events = searchParams.get("events") ?? defaultEvent;
  const eventsBy = events?.split(",");

  const { data: dataTickets } =
    useAuthTicketsByEventsId<TicketEventInterface>(eventsBy);

  const closeModal = () => {
    setIsOpenFilterModalTicket(false);
  };

  const [selectedItems, setSelectedItems] = useState<CheckboxValueType[]>([]);
  const tickets = searchParams.get("tickets") ?? "";
  const MODAL_TITLE = isMultiple
    ? "Selecione um ou mais ingressos para a visualização dos resultados."
    : "Selecione um ingresso para a visualização dos resultados.";

  useEffect(() => {
    if (tickets) {
      const ticketsBy = tickets?.split("%");
      setSelectedItems(ticketsBy);
    }
  }, [tickets]);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    if (!isMultiple) {
      setSelectedItems(checkedValues.slice(-1));
    } else {
      setSelectedItems(checkedValues);
    }
  };

  const handleAddFilterTicket = () => {
    if (selectedItems.length === 0) {
      return;
    }

    const tickets = selectedItems.join("%");

    if (onSelectTickets && dataTickets) {
      const filteredTickets = dataTickets?.results?.filter((ticket) =>
        selectedItems.includes(ticket._id)
      );
      onSelectTickets(filteredTickets);
    }

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      tickets,
    });

    closeModal();
  };

  const clearTicket = () => {
    setSelectedItems([]);
    onClearTickets?.();

    searchParams.delete("tickets");
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  };

  const toggleSelection = (
    selectedItems: CheckboxValueType[],
    id: string | number
  ) => {
    if (selectedItems.includes(id)) {
      return selectedItems.filter((item) => item !== id);
    } else {
      return [...selectedItems, id];
    }
  };

  return (
    <ModalStyled
      width={857}
      visible={isOpenFilterModalModalTicket}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Filtrar por ingresso</h1>

        <h2>{MODAL_TITLE}</h2>

        <ContainerInputSearch>
          <input
            type="text"
            placeholder="Insira o nome do ingresso..."
            onChange={(e) => setTextSearch(e.target.value)}
          />
          <ButtonSearch onClick={() => {}}>
            <FiSearch size={18} color={Colors.primary100} />
          </ButtonSearch>
        </ContainerInputSearch>

        <ContainerSelect>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={selectedItems}
            onChange={onChange}
          >
            {dataTickets?.results?.map((ticket) => (
              <NormalCardBorder
                key={ticket?._id}
                onClick={() =>
                  onChange(toggleSelection(selectedItems, ticket?._id))
                }
              >
                <div className="check">
                  <CheckboxStyled value={ticket._id} />
                </div>

                <div className="circle-icon">
                  <RiCoupon2Line size={16} color={Colors.secondary100} />
                </div>

                <h3 className="normal">{ticket.name}</h3>
              </NormalCardBorder>
            ))}
          </Checkbox.Group>
        </ContainerSelect>

        <ContainerButtonsModal>
          <ButtonClear onClick={clearTicket}>
            <span>Limpar</span>
          </ButtonClear>

          <ButtonOk onClick={handleAddFilterTicket}>
            <span>Aplicar filtros</span>
          </ButtonOk>
        </ContainerButtonsModal>
      </ContainerModal>
    </ModalStyled>
  );
}
