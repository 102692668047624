import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { paramsToString } from "utils/paramsToString";

import { TicketChart } from "types";
import { useUserTicketChart } from "services/Hook/TicketOffice";


export function useSearchUserTicketChart() {
  const [searchParams] = useSearchParams();

  const events = searchParams.get("events") ?? "";

  const [paramsString, setParamsString] = useState<URLSearchParams>();

  const { data } = useUserTicketChart<TicketChart[]>({
    paramsString: String(paramsString),
    paramsObject: {
      ...(events && { events: events.split("%") }),
    },
  });

  useEffect(() => {
    const paramsString = new URLSearchParams(
      paramsToString({
        events,
      })
    );

    setParamsString(paramsString);
  }, [
    events
  ]);

  return {
    data,
  };
}
