import { createPortal } from "react-dom";
import { useCallback, useRef, useState } from "react";

import { AiOutlineMail } from "react-icons/ai";

import { useReactToPrint } from "react-to-print";

import { Container, ActionButton } from "./styles";
import { ButtonPrintTicket } from "pages/Profile/MyTicket/styles";

import print_ticket_icon from "assets/print-ticket-icon.svg";
import { TicketForPrint, TicketPrintType } from "components/TicketForPrint";

import {
  ResendUserTicket,
  UserTicketBalance,
} from "services/Hook/TicketOffice";
import {
  errorNotification,
  successNotification,
} from "components/Notification";

interface ListTableProps {
  leads: UserTicketBalance[] | undefined;
}

export function ResendTicketTable({ leads }: ListTableProps) {
  const [selectedTicket, setSelectedTicket] = useState<TicketPrintType | null>(
    null
  );

  const componentRef = useRef(null);

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const [fetchingResendTicket, setFetchingResendTicket] = useState(false);
  const handleResendTicket = async (userTicketId: string) => {
    try {
      setFetchingResendTicket(true);
      await ResendUserTicket({ userTicketId });
      successNotification("Ingresso reenviado com sucesso.");
    } catch {
      errorNotification("Ocorreu um erro ao reenviar o ingresso.");
    } finally {
      setFetchingResendTicket(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSelectedTicket = useCallback(
    async (ticket: TicketPrintType) => {
      setSelectedTicket(ticket);

      await sleep(1000);

      handlePrint();
    },
    [handlePrint]
  );

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>NOME E E-MAIL</th>
            <th>EVENTO</th>
            <th>INGRESSO</th>
            <th>CHECKIN</th>
            <th>CHECKOUT</th>
            <th>AÇÕES</th>
          </tr>
        </thead>

        <tbody>
          {leads?.map((lead) => {
            return (
              <tr style={{ borderRadius: 20 }} key={lead?._id}>
                <td>
                  <div className="row">
                    <div className="column">
                      <h5>{lead?.holder_name}</h5>
                      <h6>{lead?.holder_email}</h6>
                    </div>
                  </div>
                </td>

                <td>
                  <h5>{lead?.event.name}</h5>
                </td>

                <td>
                  <h5>{lead?.event_ticket_id.name}</h5>
                </td>

                <td>
                  <h5>{lead?.checkin ? lead?.formatedCheckinAt : "Não"}</h5>
                </td>

                <td>
                  <h5>{lead?.checkout ? lead?.formatedCheckouAt : "Não"}</h5>
                </td>

                <td>
                  <div className="button">
                    <ActionButton
                      type="button"
                      disabled={fetchingResendTicket}
                      onClick={() => handleResendTicket(lead._id)}
                    >
                      <AiOutlineMail /> Enviar por e-mail
                    </ActionButton>

                    <ButtonPrintTicket
                      type="button"
                      onClick={() =>
                        handleSelectedTicket({
                          event: lead?.event,
                          event_ticket_id: lead?.event_ticket_id,
                          holder_name: lead?.holder_name,
                          purchase_id: lead?.purchase_id,
                        })
                      }
                    >
                      <img
                        src={print_ticket_icon}
                        width="18px"
                        height="18px"
                        alt="print"
                      />

                      <span>Imprimir ingresso</span>
                    </ButtonPrintTicket>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {createPortal(
        <>
          {selectedTicket ? (
            <TicketForPrint
              ref={componentRef}
              selectedTicket={selectedTicket}
            />
          ) : null}
        </>,
        document.body
      )}
    </Container>
  );
}
