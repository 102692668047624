import { Colors } from "constants/colors";
import styled from "styled-components";
import { sizeConversor } from "utils/conversors";

export const Container = styled.button`
  box-shadow: 0px 15px 30px 0px #d4005026;
  background: #d40050;

  display: flex;
  width: ${sizeConversor(262)};
  height: ${sizeConversor(60)};
  padding: ${sizeConversor(14)} ${sizeConversor(27.5)};
  justify-content: center;
  align-items: center;
  gap: ${sizeConversor(10)};
  cursor: pointer;

  border-radius: ${sizeConversor(14)};

  outline: none;
  border: none;

  font-family: "Cairo";
  font-size: ${sizeConversor(20)};
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  color: ${Colors.white};

  @media (max-width: 670px) {
    cursor: pointer;
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0px;
    width: 25.735px;
    height: 25.735px;

    span {
      display: none;
    }
  }
`;
