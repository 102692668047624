import { Colors } from "constants/colors";
import { sizeConversor } from "utils/conversors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1276px) {
    justify-content: center;
  }
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;
  margin-top: 9.14rem;
  width: 100%;

  @media (max-width: 1024px) {
    margin-top: 70px;
    padding-left: 28px;
    padding-right: 28px;
  }

  div.header {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @media (max-width: 800px) {
      padding-right: 28px;
      margin-bottom: 26px;
    }

    h2 {
      margin-bottom: 0px !important;

      @media (max-width: 800px) {
        font-weight: bold;
      }
    }

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      div.dots {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 21px;
      }
    }
  }

  div.main-header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      display: none;
    }

    h1 {
      font-family: "Cairo";
      margin-top: 2.53rem;
      font-weight: 900;
      font-size: 1.87rem;
      margin-bottom: 0px;
      color: ${Colors.black100};
    }

    div {
      margin-top: 2.53rem;
    }
  }

  div.header-filter-mobile {
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 1024px) {
      display: none;
    }

    h1 {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      color: ${Colors.black100};
    }

    div.box {
      width: 25.73px;
      height: 25.73px;
      border-radius: 50%;
      background: ${Colors.white20};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }

  h2 {
    color: var(--preto-preto-100, #11142d);
    font-family: Cairo;
    font-size: ${sizeConversor(32)};
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }

  .rectangle {
    h3 {
      color: var(--preto-preto-30, #b8b9c0);
      font-family: Open Sans;
      font-size: ${sizeConversor(26)};
      font-style: normal;
      font-weight: 700;
    }
  }

  .leads-container {
    margin-top: ${sizeConversor(50)};
  }

  .overview-container {
    margin-top: ${sizeConversor(90)};

    .overview-items {
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: ${sizeConversor(11)};
      row-gap: ${sizeConversor(12)};
    }
  }

  .dashboard-container {
    display: flex;
    gap: ${sizeConversor(10)};

    margin-top: ${sizeConversor(60)};
  }

  hr {
    border-top: ${sizeConversor(1)} solid rgba(233, 231, 238, 1);
    width: 100%;
  }

  .rectangle {
    hr {
      margin: ${sizeConversor(80)} 0;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const ReportButton = styled.div`
  display: flex;
  width: ${sizeConversor(262)};
  height: ${sizeConversor(60)};
  padding: ${sizeConversor(14)} ${sizeConversor(51)};
  justify-content: center;
  align-items: center;
  gap: ${sizeConversor(10)};
  cursor: pointer;

  border-radius: ${sizeConversor(14)};
  background: var(--primaria-primaria-10, #fbe5ed);
  color: var(--primaria-primaria-100, #d40050);

  font-family: Cairo;
  font-size: ${sizeConversor(20)};
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
`;

export const LeadsContainer = styled.div`
  @media (min-width: 1024px) {
    > hr {
      display: none;
    }
  }
`;

export const SyntheticSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Poppins;
  font-size: ${sizeConversor(20)};
  font-weight: 400;

  width: ${sizeConversor(397)};
  height: ${sizeConversor(60)};
  border-radius: ${sizeConversor(8)};
  border: ${sizeConversor(2)} solid var(--secundaria-secundaria-20, #d3cfde);
  cursor: pointer;

  padding: ${sizeConversor(14)} ${sizeConversor(20)};
`;

export const OptionRectangle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: ${sizeConversor(120)};
  flex-shrink: 0;
  margin-top: ${sizeConversor(30)};
  padding: 0 ${sizeConversor(30)};
  color: rgba(167, 159, 188, 1);
  font-size: ${sizeConversor(26)};
  cursor: pointer;

  border-radius: 20px;
  border: ${sizeConversor(1)} solid var(--preto-preto-10, #e7e7ea);
  background: var(--sistema-branco, #fff);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);

  .option {
    display: flex;
    align-items: center;
    gap: ${sizeConversor(20)};
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${sizeConversor(60)};
    height: ${sizeConversor(60)};
    border-radius: ${sizeConversor(20)};
    background: var(--secundaria-secundaria-10, #e9e7ee);
  }

  p {
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
  }

  .arrow {
    display: flex;
    font-size: ${sizeConversor(40)};
  }

  :hover {
    color: var(--primaria-primaria-100, #d40050);

    .icon {
      background: var(--primaria-primaria-10, #fbe5ed);
    }
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  align-items: center;

  width: 37.5rem;
  height: ${sizeConversor(281)};

  border-radius: ${sizeConversor(14)};
  border: ${sizeConversor(1)} solid #f0f0f0;
  background: var(--white, #fff);
  box-shadow: 0px 10px 20px 0px rgba(108, 93, 211, 0.06);
`;

export const CardArrowLeft = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 24px;
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  background-color: ${Colors.secondary10};

  @media (max-width: 800px) {
    display: none;
  }
`;

export const CardArrowRight = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;
  border-radius: 50%;

  background-color: ${Colors.secondary10};
  border: 0;

  @media (max-width: 800px) {
    height: 24px;
    width: 24px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;
interface DotProps {
  active: boolean;
}

export const Dot = styled.div<DotProps>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? Colors.secondary100 : Colors.secondary20};
  margin-right: 16px;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const LeadsCardContainer = styled.div`
  margin-top: 24px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const HiddenResponsive = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;
