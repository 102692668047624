import { Modal } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 24px;
  }
`;

export const ContainerModal = styled.form`
  margin-top: 40px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 68px;
  padding-left: 68px;

  h2.title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-weight: 600;
    font-size: 1.87rem;
    margin-bottom: 0px;
    text-align: center;
    color: ${Colors.secondary100};
  }
`;

export const ContainerDetailIntegration = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 212px;

  padding: 49px 50px 44px 50px;
  margin-top: 2.62rem;

  background: ${Colors.white};
  border: 1px solid ${Colors.black10};
  box-sizing: border-box;
  border-radius: 0.93rem;
`;

export const ContainerHeaderIntegration = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  padding-bottom: 1.87rem;
  border-bottom: 1px solid ${Colors.secondary10};

  div.logo {
    display: flex;
    flex-direction: row;

    img {
      object-fit: contain;
    }

    h2.title-logo {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 1.21rem;

      font-style: normal;
      font-weight: 600;
      color: ${Colors.black100};

      margin-bottom: 0px;
      margin-left: 19px;
    }
  }

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-size: 1.03rem;
    font-weight: 400;
    margin-bottom: 0px;

    color: ${Colors.black70};
    margin-top: 1.12rem;

    a {
      font-family: "Open Sans";
      font-style: normal;
      font-size: 1.03rem;
      font-weight: 400;
      color: ${Colors.primary100};
    }
  }
`;

export const ContainerActiveIntegration = styled.div`
  margin-top: 1.87rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: flex-start;
  padding-bottom: 1.87rem;
  border-bottom: 1px solid ${Colors.secondary10};

  .ant-switch-checked {
    background-color: ${Colors.primary100};
  }

  h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.03rem;

    color: ${Colors.secondary100};
    margin-bottom: 0px;
    margin-left: 1.03rem;
  }
`;

export const ContainerDateIntegration = styled.div`
  margin-top: 1.87rem;

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.03rem;
    margin-bottom: 0px;

    color: ${Colors.secondary100};
  }
`;

interface FormFullProps {
  error?: boolean;
}

export const FormFull = styled.div<FormFullProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 1.68rem;

  div.all-input {
    display: flex;
    flex-direction: column;
    width: 94%;

    span {
      color: ${Colors.red10};
      font-size: 16px;
      margin-top: 4px;
    }
  }

  input {
    width: 96%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid ${Colors.secondary20};
    color: ${Colors.secondary70};
    font-size: 20px;
    line-height: 160%;
    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    flex-direction: column;

    div.all-input {
      width: 100%;
    }
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-left: 13px;
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

export const ContainerButtonsModal = styled.div`
  width: 100%;
  margin-top: 4.68rem;

  /* margin-bottom: 50px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ButtonCancel = styled.button`
  cursor: pointer;
  border: 0;
  width: 18.21rem;
  height: 3.84rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${Colors.primary10};
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-weight: 700;
    font-size: 1.21rem;
    text-align: center;
    color: ${Colors.primary100};
  }

  @media (max-width: 1140px) {
    width: 100%;
  }
`;

export const ButtonSave = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18.21rem;
  height: 3.84rem;

  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-weight: 700;
    font-size: 1.21rem;

    color: ${Colors.white};
    margin-left: 10px;
  }

  @media (max-width: 1140px) {
    width: 100%;

    margin-top: 20px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;

  .error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;
