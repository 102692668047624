export const returnStatusPurchase = (value: string) => {
  switch (value) {
    case "PAID":
      return "Pago";
    case "PENDING ":
      return "Pendente";
    case "REFUND":
      return "Estornado";

    case "CHARGEBACK":
      return "Cancelado";

    case "CANCEL":
      return "Cancelado";

    default:
      return "Pendente";
  }
};

export const returnStatus = (value: string) => {
  switch (value) {
    case "PAID":
      return "Paga";
    case "PENDING ":
      return "Pendente";
    case "REFUND":
      return "Estornada";

    case "CHARGEBACK":
      return "Cancelada";

    case "CANCEL":
        return "Cancelado";

    default:
      return "Pendente";
  }
};

export const returnStatusColorPurchase = (value: string) => {
  switch (value) {
    case "PAID":
      return "green";
    case "PENDING ":
      return "yellow";
    case "REFUND":
      return "gray";

    case "CHARGEBACK":
      return "gray";

    case "CANCEL":
      return "gray";

    default:
      return "yellow";
  }
};

export const returnTypePaymentPurchase = (value?: string | null) => {
  switch (value) {
    case "pix":
      return "PIX";

    case "credit-card":
      return "Cartão de Crédito";

    case "boleto":
      return "Boleto";

    case "courtesy":
      return "Cortesia";

    case "webhook":
      return "Webhook";

    case "manual":
      return "Manual";

    case "import":
      return "Importada";

    case null:
      return "";

    default:
      return "Pendente";
  }
};

export const purchaseSubtitleFactor = (purchase: any) => {
  let info = "Compra ";

  info += returnStatus(purchase?._id);

  if (purchase.purchase_code) {
    info += " - CÓDIGO: " + purchase?._id;
  }

  return info;
};
