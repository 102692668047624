import { authClient } from "services/authClient";
import {
  CreateTeamDTO,
  CreateTeamMemberDTO,
  ListTeam,
  UserResponse,
  TeamDetailResponse,
  EditTeamDTO,
  ListTeamDto,
  DeleteEventFromTeamDTO,
  DeleteUserFromTeamDTO,
  AddEventToTeamDTO,
} from "./types";

export type SearchUserResponse = Array<UserResponse>;

export async function doCreateTeam(body: CreateTeamDTO) {
  return authClient.post("/team", body);
}

export async function handleSearchMemberByEmail(
  email: string
): Promise<SearchUserResponse> {
  const { data } = await authClient.get<SearchUserResponse>(
    `/team/user/invite/search?email=${email}`
  );

  return data;
}

export async function doCreateTeamMember({
  email,
  role,
  teamId,
}: CreateTeamMemberDTO) {
  const { data } = await authClient.post(`/team/${teamId}/user`, {
    email,
    role,
  });

  return data;
}

export async function getTeams({
  id,
  page = 1,
  limit = 10,
}: ListTeamDto & { page?: number; limit?: number }): Promise<ListTeam> {
  const { data } = await authClient.get<ListTeam>(
    `/team?id=${id}&page=${page}&limit=${limit}`
  );

  return data;
}

export async function getTeamDetail(id: string): Promise<TeamDetailResponse> {
  const { data } = await authClient.get<TeamDetailResponse>(`/team/${id}`);

  return data;
}

export async function editTeam(id: string, body: EditTeamDTO) {
  const { data } = await authClient.patch(`/team/${id}`, body);

  return data;
}

export async function deleteTeam(id: string) {
  const { data } = await authClient.delete(`/team/${id}`);

  return data;
}

export async function deleteEventFromTeam({ eventId, teamId}: DeleteEventFromTeamDTO) {
  return authClient.delete(`/team/${teamId}/event/${eventId}`);
}

export async function deleteUserFromTeam({ userId, teamId}: DeleteUserFromTeamDTO) {
  return authClient.delete(`/team/${teamId}/user/${userId}`);
}

export async function addEventToTeam({ eventId,  teamId }: AddEventToTeamDTO) {
  return authClient.post(`/team/${teamId}/event`, { event_id: eventId });
}
