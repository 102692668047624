import { Header } from "components/Header";
import {
  AddTeamButtonMobile,
  AddTeamLink,
  Container,
  HeaderMobileContainer,
  ModalStyled,
  ResourceCard,
  ResourceContainer,
  ResourceHeader,
  ResourceHeaderBetween,
  ResourceIcon,
  ResourceList,
  TableContainer,
  TeamsMobileTableContainer,
} from "./styles";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import {
  RiAddFill,
  RiAddLine,
  RiCloseCircleLine,
  RiTeamLine,
} from "react-icons/ri";
import { Colors } from "constants/colors";
import { TeamsTable } from "./components/TeamsTable";
import { Footer } from "components/Footer";
import { useTeamsList } from "services/Hook/Team";
import { Pagination } from "components/Pagination";
import { useSearchTeamMember } from "hooks/useSearchTeamMember";
import { applyOpacityToHexColor } from "utils/applyOpacityToHexColor";
import { useCallback, useMemo, useState } from "react";
import {
  convertToTeamRole,
  Team,
  TeamUsersRoleEnum,
} from "services/teams/types";
import { MdSettings } from "react-icons/md";
import ModalTeamSettings from "./components/ModalTeamSettings";
import { useDisclosure } from "hooks/useDisclosure";
import { ModalSuccess } from "components/ModalSuccess";
import { deleteTeam } from "services/teams";
import { errorNotification } from "components/Notification";
import { useSearchParams } from "react-router-dom";
import { Filter } from "./components/Filter";
import LoadMoreItems from "components/LoadMoreItems";
import ResourceCardMobile from "./components/ResourceCardMobile";
import { useAuth } from "contexts/AuthContext";

const metaTags = {
  title: `Times - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

interface TableRow {
  id: string;
  name: string;
  email: string;
  whatsapp: string;
  event: string;
  team: string;
  role: string;
  color?: string;
}

function Teams() {
  const [searchParams] = useSearchParams();
  const filterText = searchParams.get("filter_text") ?? "";

  const { user } = useAuth();
  const { data, hasMoreTeams, loadMoreTeams, mutate } = useTeamsList({
    id: filterText,
  });
  const { onChange, page } = useSearchTeamMember();
  const [currentTeam, setCurrentTeam] = useState<Team | undefined>();
  const {
    handleCancel: handleCancelSettings,
    handleOk: handleOkSettings,
    isModalVisible: isModalVisibleSettings,
    showModal: showModalSettings,
  } = useDisclosure();
  const {
    handleCancel: handleCancelSuccess,
    handleOk: handleOkSuccess,
    isModalVisible: isModalVisibleSuccess,
    showModal: showModalSuccess,
  } = useDisclosure();

  const handleTransformUsers = useCallback((team: Team[]) => {
    const transformed: Array<TableRow> = [];

    team.forEach((t) => {
      const teamName = t.name;

      t.users.forEach((teamUser) => {
        const userId = teamUser._id._id;
        const userName = teamUser._id.name;
        const userEmail = teamUser._id.email;
        const userRole = convertToTeamRole(teamUser.role);
        const userWhatsapp = "-";
        const userEvent = t.events.length > 0 ? t.events[0]._id.name : "";

        const existingUserIndex = transformed.findIndex(
          (user) =>
            user.id === userId &&
            user.name === userName &&
            user.email === userEmail
        );

        if (existingUserIndex >= 0) {
          const existingUser = transformed[existingUserIndex];

          if (!existingUser.team.includes(teamName)) {
            existingUser.team += `, ${teamName}`;
          }

          if (userEvent && !existingUser.event.includes(userEvent)) {
            existingUser.event += `, ${userEvent}`;
          }

          if (userRole && !existingUser.role.includes(userRole)) {
            existingUser.role += `, ${userRole}`;
          }
        } else {
          transformed.push({
            id: userId,
            name: userName,
            email: userEmail,
            whatsapp: userWhatsapp,
            event: userEvent,
            team: teamName,
            role: userRole,
            color: t.color,
          });
        }
      });
    });

    return transformed;
  }, []);

  const dataRows = useMemo(() => {
    return handleTransformUsers(data.results);
  }, [data.results, handleTransformUsers]);

  const handleOpenModalSettings = (team: Team) => {
    setCurrentTeam(team);
    showModalSettings();
  };

  const handleDeleteTeam = useCallback(
    async (id: string) => {
      try {
        await deleteTeam(id);

        mutate();

        handleOkSettings();
        showModalSuccess();
      } catch {
        errorNotification(
          "Erro ao deletar o time.",
          "Tente novamente mais tarde."
        );
      }
    },
    [showModalSuccess, handleOkSettings, mutate]
  );

  const canEditOrDeleteTeam = useCallback(
    (team: Team): boolean => {
      const allowedPermissions = [
        TeamUsersRoleEnum.COORDINATOR,
        TeamUsersRoleEnum.LEADER,
        TeamUsersRoleEnum.MANAGER,
      ];
      const myself = team.users.find((u) => u._id._id === user?.id);

      if (!myself) return false;

      const myRole = myself.role;

      if (allowedPermissions.includes(myRole)) return true;

      return false;
    },
    [user?.id]
  );

  return (
    <>
      <Header metaTags={metaTags} />

      <Container>
        <div className="bread">
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Times", link: "/teams" },
            ]}
          />
        </div>

        <ResourceContainer>
          <HeaderMobileContainer>
            <h1>Painel do time</h1>

            <AddTeamButtonMobile to="/teams/register">
              <RiAddFill size={20} color={Colors.primary100} />
              Novo time
            </AddTeamButtonMobile>
          </HeaderMobileContainer>

          {data.results.length > 0 && (
            <ResourceHeader>
              <div className="content">
                <RiTeamLine color={Colors.black100} size={36} />

                <span>Times</span>
              </div>
            </ResourceHeader>
          )}

          <ResourceList>
            {data.results.map((item) => (
              <ResourceCard key={item._id} color={item.color}>
                <ResourceIcon color={applyOpacityToHexColor(item.color)}>
                  <RiTeamLine size={40} color={item.color} />
                </ResourceIcon>

                <div className="info-wrapper">
                  <span>{item.name}</span>
                </div>

                {canEditOrDeleteTeam(item) ? (
                  <button
                    onClick={() => handleOpenModalSettings(item)}
                    className="link-icon"
                    type="button"
                  >
                    <MdSettings color={Colors.secondary100} size={24} />
                  </button>
                ) : null}
              </ResourceCard>
            ))}
          </ResourceList>
        </ResourceContainer>

        <ResourceContainer>
          <ResourceHeader>
            <ResourceHeaderBetween>
              <span>Colaboradores</span>

              <AddTeamLink to="/teams/register">
                <RiAddLine />
                Adicionar novo
              </AddTeamLink>
            </ResourceHeaderBetween>
          </ResourceHeader>

          <div>
            <Filter />

            <TableContainer>
              <TeamsTable contributors={dataRows} />
            </TableContainer>
          </div>

          <TeamsMobileTableContainer>
            {dataRows.map((item) => {
              return (
                <ResourceCardMobile
                  name={item.name}
                  email={item.email}
                  phone={item.whatsapp}
                  event={item.event}
                  role={item.role}
                  team={{
                    name: item.team,
                    color: item?.color ?? "",
                  }}
                />
              );
            })}
          </TeamsMobileTableContainer>

          <LoadMoreItems
            handleLoadMore={loadMoreTeams}
            fetching={false}
            hasMoreItems={hasMoreTeams}
            length={data?.results.length}
            total={data?.count}
            context="time(s)"
          />

          {data && data?.results?.length > 0 ? (
            <div className="pagination">
              <Pagination
                title="registros"
                totalCountOfRegisters={data?.count}
                currentPage={Number(page)}
                onPageChange={onChange}
                registersPerPage={10}
              />
            </div>
          ) : null}
        </ResourceContainer>
      </Container>

      <Footer />

      <ModalSuccess
        handleCancel={handleCancelSuccess}
        handleOk={handleOkSuccess}
        isModalVisible={isModalVisibleSuccess}
        title="Time excluído com sucesso"
        subtitle="O time selecionado foi excluído com sucesso."
      />

      <ModalStyled
        width={857}
        visible={isModalVisibleSettings && !!currentTeam}
        onOk={handleOkSettings}
        onCancel={handleCancelSettings}
        style={{ borderRadius: "20px" }}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "34px" }}
            size={43}
            color={Colors.black40}
          />
        }
        footer={null}
      >
        <ModalTeamSettings
          onDelete={handleDeleteTeam}
          currentTeam={currentTeam}
        />
      </ModalStyled>
    </>
  );
}

export default Teams;
