import { Colors } from "constants/colors";
import styled from "styled-components";

import { Modal } from "antd";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerIcon = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentManualCheckIn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.56rem;
  margin-top: 1.12rem;

  h3 {
    font-family: Cairo;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: ${Colors.secondary100};
    margin-bottom: 0px;
  }

  span {
    font-family: Open Sans;
    font-size: 0.93rem;
    font-weight: 400;
    text-align: center;
    color: ${Colors.black90};
    margin-bottom: 0px;
  }
`;

export const AllInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    color: ${Colors.red10};
    font-size: 11px;
    margin-top: 4px;
  }
`;

interface InputContainerProps {
  error?: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  width: 100%;

  margin-top: 33px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 2px solid
    ${(props) => (props.error ? Colors.red10 : Colors.secondary20)};

  @media (max-width: 1200px) {
    width: 100%;
  }

  input {
    border: 0;
    outline: 0;
    margin-left: 24px;
    margin-bottom: 5px;
    width: 100%;
  }
`;

export const ContainerButtonProceed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
`;

export const ButtonProceed = styled.button`
  outline: 0;
  cursor: pointer;
  border: 0;
  width: 6.23rem;
  height: 2.81rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.65rem;
  background-color: ${Colors.primary100};

  span {
    font-family: Cairo;
    font-size: 0.93rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
    color: ${Colors.white};
  }
`;
