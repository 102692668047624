import { Link } from "react-router-dom";

import { Transaction } from "types";

import { Container } from "./styles";

import eyes_event_icon from "assets/eyes-event-icon.svg";
import { BadgePurchaseByStatus } from "components/BadgePurchaseByStatus";
import { returnTypePaymentPurchase } from "utils/purchase";
import { formatPrice } from "utils/formatPrice";
import { DropDown } from "components/DropDown";

interface TableTransactionsProps {
  purchases: Transaction[] | null;
}

export function TableTransactions({ purchases }: TableTransactionsProps) {
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>COMPRADOR</th>
            <th>DATA DA VENDA</th>
            <th>VALOR DA VENDA</th>
            <th>STATUS</th>
            <th>FORMA DE PAGAMENTO</th>
          </tr>
        </thead>

        <tbody>
          {purchases?.map((purchase) => {
            return (
              <tr style={{ borderRadius: 20 }} key={purchase?._id}>
                <td>
                  <div className="row">
                    <div className="column">
                      <Link to={`/financial/${purchase?._id}`}>
                        <h5>{purchase?._id}</h5>
                      </Link>
                    </div>
                  </div>
                </td>

                <td>
                  <div className="date">
                    <h5>{purchase?.user_id?.name}</h5>

                    <h6>{purchase?.user_id?.email}</h6>
                  </div>
                </td>

                <td>
                  <h5>{purchase?.formattedDate}</h5>
                </td>

                <td>
                  <h5>
                    {formatPrice(purchase?.total ? purchase?.total / 100 : 0)}
                  </h5>
                </td>

                <td>
                  <BadgePurchaseByStatus
                    status={purchase?.payment_status ?? ""}
                  />
                </td>

                <td>
                  <h5>{returnTypePaymentPurchase(purchase?.payment_type)}</h5>
                </td>

                <td>
                  <DropDown
                    links={[
                      {
                        type: "link",
                        image: eyes_event_icon,
                        title: "Ver detalhes",
                        link: `/financial/${purchase?._id}`,
                      },
                      ...(purchase?.payment_status === "PAID"
                        ? [
                            {
                              type: "link",
                              title: "Cancelar a compra",
                              link: `/profile/terms-cancel-by-organizer/${purchase?._id}`,
                            },
                          ]
                        : []),
                    ]}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}
