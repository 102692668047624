import { Link } from "react-router-dom";
import { Container } from "./styles";

interface ContainerErrorProps {
  title: string;
  subtitle: string;
  href?: string | null;
}

export function ContainerError({ title, subtitle, href }: ContainerErrorProps) {
  return (
    <Container>
      <div className="content">
        <h2>{title}</h2>

        <h3>{subtitle}</h3>
      </div>

      {href ? (
        <Link to={href}>
          <button>
            <span>Voltar</span>
          </button>
        </Link>
      ) : null}
    </Container>
  );
}
