import { Colors } from "constants/colors";
import styled from "styled-components";

interface BoxProps {
  active?: boolean;
}

export const Box = styled.div<BoxProps>`
  cursor: pointer;
  width: 2.43rem;
  height: 2.43rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${Colors.white};
  border: 1px solid
    ${(props) => (props.active ? Colors.primary100 : Colors.black10)};
  border-radius: 0.56rem;
`;

export const DropDownEvent = styled.div`
  position: absolute;
  background-color: ${Colors.white10};
  width: 15.93rem;

  background-color: red;

  margin-top: 0.42rem;
  z-index: 95;

  display: flex;
  flex-direction: column;

  background-color: purple;

  padding: 0.75rem 0.56rem;

  margin-left: -218px;
  border: 1px solid ${Colors.border20};

  background-color: ${Colors.white};

  box-shadow: 0px 13px 40px ${Colors.black30};
  border-radius: 0.56rem;

  ul {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.46rem;

    padding-bottom: 0px !important;
    margin-bottom: 0px !important;

    > :nth-child(n + 2) {
      border-top: 1px solid ${Colors.border};
      padding-top: 0.46rem;
    }

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
    }

    li {
      cursor: pointer;
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: row;

      align-items: center;
      margin-bottom: 0px;

      div.circle-icon {
        width: 1.5rem;
        height: 1.5rem;
        background: ${Colors.secondary10};
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 0.89rem;
          height: 0.75rem;
        }
      }

      div.circle-icon-remove {
        width: 1.5rem;
        height: 1.5rem;
        background: ${Colors.red0};
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 0.89rem;
          height: 0.75rem;
        }
      }

      a {
        color: ${Colors.black50};
        margin-left: 0.56rem;
      }

      span {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        margin-bottom: 0px;
        margin-left: 0.56rem;
        color: ${Colors.black50};
      }
    }

    li.exit {
      border: 0;
      margin-bottom: 0px;

      span {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        margin-bottom: 0px;
        margin-left: 0.56rem;
        color: ${Colors.pink30};
      }
    }
  }
`;
