import { RiCloseCircleLine } from "react-icons/ri";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";
import { sortingFilterFinancialTransactions } from "constants/filters";

import { FormFilterResponsive } from "components/FormFilterResponsive";

import { DrawerStyled } from "./styles";

export function DrawerFilterTransaction() {
  const { isOpenFilterDrawerTransactions, setIsOpenFilterDrawerTransactions } =
    useFiltersContext();

  const handleCloseDrawer = () => {
    setIsOpenFilterDrawerTransactions(false);
  };

  return (
    <DrawerStyled
      title="Filtros"
      className="custom-drawer"
      width={278}
      drawerStyle={{ color: Colors.secondary100 }}
      headerStyle={{
        paddingLeft: "14px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
        color: Colors.secondary100,
        fontWeight: "bold",
      }}
      closable={false}
      bodyStyle={{ padding: 0 }}
      placement="right"
      onClose={handleCloseDrawer}
      visible={isOpenFilterDrawerTransactions}
      extra={
        <RiCloseCircleLine
          size={24}
          color={Colors.secondary100}
          onClick={handleCloseDrawer}
        />
      }
    >
      <FormFilterResponsive
        setOpen={setIsOpenFilterDrawerTransactions}
        filters={sortingFilterFinancialTransactions}
      />
    </DrawerStyled>
  );
}
