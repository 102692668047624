import { ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import { Center } from './styles';
import { Spin } from 'antd';
import { Colors } from 'constants/colors';

interface LoadingWrapperFilterProps {
  children: ReactNode | Element | null;
  isLoading: boolean;
}

const antIcon = (
  <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
)

export function LoadingWrapperFilter({ children, isLoading }: LoadingWrapperFilterProps) {
  if (!isLoading) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <Center>
      <Spin spinning indicator={antIcon} />
    </Center>
  );
}
