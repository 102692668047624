import { RiCloseCircleLine } from "react-icons/ri";

import { useFiltersContext } from "contexts/FiltersContext";

import { sortingFilterFinancialGeneral } from "constants/filters";
import { Colors } from "constants/colors";

import { FormFilterResponsive } from "components/FormFilterResponsive";

import { DrawerStyled } from "./styles";

export function DrawerFilterGeneral() {
  const { isOpenFilterDrawerGeneral, setIsOpenFilterDrawerGeneral } =
    useFiltersContext();

  const handleCloseDrawer = () => {
    setIsOpenFilterDrawerGeneral(false);
  };

  return (
    <DrawerStyled
      title="Filtros"
      width={278}
      drawerStyle={{ color: Colors.secondary100 }}
      headerStyle={{
        paddingLeft: "14px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
        color: Colors.secondary100,
        fontWeight: "bold",
      }}
      closable={false}
      bodyStyle={{ padding: 0 }}
      placement="right"
      onClose={handleCloseDrawer}
      visible={isOpenFilterDrawerGeneral}
      extra={
        <RiCloseCircleLine
          size={24}
          color={Colors.secondary100}
          onClick={handleCloseDrawer}
        />
      }
    >
      <FormFilterResponsive
        setOpen={setIsOpenFilterDrawerGeneral}
        filters={sortingFilterFinancialGeneral}
      />
    </DrawerStyled>
  );
}
