import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    row-gap: 10px;
  }

  div.pagination {
    width: 100%;
    @media (max-width: 801px) {
      display: none;
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    border: 0;
  }

  div.border {
    margin-top: 3.21rem;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    div.line {
      padding-top: 2.81rem;
      border-top: 1px solid #e9e7ee;
      width: 80%;

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    button {
      cursor: pointer;
      border: 0;
      outline: 0;
      max-width: 22.17rem;
      width: 100%;
      height: 2.81rem;

      background: ${Colors.primary20};
      border-radius: 0.65rem;

      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-family: "Cairo";
        font-style: normal;
        font-weight: 700;
        font-size: 0.93rem;
        margin-left: 0.45rem;
        margin-bottom: 0px;

        color: ${Colors.primary100};
      }
    }
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 160%;

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.h3`
  color: ${Colors.black70};
  font-size: 1rem;
  font-family: Open Sans;
  line-height: 160%;
  margin-top: 0.93rem;

  @media (max-width: 670px) {
    font-size: 12px;
    font-family: Open Sans;
    margin-top: 8px;
  }
`;

export const LabelTitle = styled.h4`
  color: ${Colors.secondary100};
  font-size: 1.03rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 670px) {
    color: ${Colors.black100};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const ButtonAddImage = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  width: 24.98rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary10};
  margin-top: 1.68rem;

  span {
    color: ${Colors.primary100};
    font-size: 0.93rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
    margin-left: 0.46rem;

    @media (max-width: 670px) {
      font-size: 12px;
      font-weight: 600;
      margin-left: 7px;
    }
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`;

export const TitleTicket = styled.h3`
  color: ${Colors.black30};
  font-family: Open Sans;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

interface CardAccordionProps {
  active?: boolean;
}

export const CardAccordion = styled.div<CardAccordionProps>`
  cursor: pointer;
  width: 100%;
  min-height: 5.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.68rem;

  background: ${(props) => (props.active ? Colors.black5 : Colors.white)};

  z-index: 2;

  border: 1px solid ${Colors.border50};
  box-shadow: 0px 4px 20px rgba(18, 25, 34, 0.05);
  border-radius: 0.93rem;

  padding: 0.89rem 1.92rem 1.17rem 1.96rem;

  @media (max-width: 680px) {
    min-height: 44px;
  }

  div.content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    div.image {
      margin-top: 2px;

      @media (max-width: 800px) {
        margin-top: 5px;
      }
    }

    div.texts {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 23px;

      h2 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.03rem;

        margin-bottom: 0px;

        color: ${Colors.secondary100};

        @media (max-width: 800px) {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;

          color: ${Colors.black100};
        }
      }

      p {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        margin-bottom: 0px;

        color: ${Colors.secondary40};

        @media (max-width: 800px) {
          font-weight: bold;
          font-size: 10px;
          line-height: 19px;

          color: ${Colors.secondary40};
        }
      }
    }
  }

  @media (max-width: 800px) {
    height: auto;
    padding: 7px 21px 3px 19px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 5px;

    svg {
      width: 13px;
      height: 13px;
    }

    div.content {
      width: 100%;
    }

    ${({ active }) =>
      !active
        ? css`
            height: 49px;

            .texts h2 {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 150px;
            }
          `
        : ""}
  }
`;

export const DataDropDown = styled.div`
  display: block;
  position: relative;

  width: 100%;

  z-index: 1;
  margin-top: -35px;

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;
      }
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  min-height: 5.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${Colors.border50};
  border-top: 0;
  box-sizing: border-box;
  border-radius: 20px;
  padding-right: 20px;
  padding-bottom: 0.98rem;

  @media (max-width: 800px) {
    display: none;
  }

  div.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 70px;
    padding-left: 30px;
    width: 100%;

    div.column {
      display: flex;
      flex-direction: column;

      h2 {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        line-height: 160%;
        color: ${Colors.black80};
      }

      p {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 0.84rem;
        margin-bottom: 0px;
        color: ${Colors.black70};
      }
    }
  }

  div.container-image {
    margin-top: 40px;

    @media (max-width: 1200px) {
      padding-left: 10px;
    }

    img {
      border-radius: 10px;
    }
  }
`;

export const ContainerBorderMobile = styled.div`
  width: 100%;
  min-height: 180px;
  display: flex;
  flex-direction: column;

  border: 1px solid ${Colors.border50};
  border-top: 0;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  margin-top: 15px;
  padding: 17px 15px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const RowMobileFirst = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const RowMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const RowMobileBorder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  padding-bottom: 12px;

  border-bottom: 1px solid ${Colors.secondary10};

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;

  @media (max-width: 800px) {
    margin-top: 12px;
  }
`;

export const ButtonEditTicket = styled.button`
  width: 12.28rem;
  height: 2.81rem;

  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${Colors.white20};
  border-radius: 14px;
  margin-right: 10px;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;

    div.text {
      display: none;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.green10};
  }
`;

export const ButtonEditTicketResponsive = styled.button`
  width: 12.28rem;
  height: 2.81rem;

  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${Colors.white20};
  border-radius: 14px;
  margin-right: 10px;

  @media (min-width: 1024px) {
    display: none;
  }

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;

    div.text {
      display: none;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.green10};
  }
`;

export const ButtonPrint = styled.button`
  width: 12.28rem;
  height: 2.81rem;

  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 14px;

  background: ${Colors.white20};

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;

    div.text {
      display: none;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.red10};
  }
`;
