import { Switch } from "antd";
import { ButtonConnect, Container } from "./styles";
import { Colors } from "constants/colors";
import { MdEdit } from "react-icons/md";
import { IoMdEye, IoMdTrash } from "react-icons/io";

type IntegrationData = {
  event_id: string;
  _id: number | string;
  key: string;
  status: true;
  token: string;
  type: number;
  user_id: number;
  event_ticket_id: {
    _id: string;
    name: string;
  };
} | null;

interface CardIntegrationProps {
  id: string | number;
  thumb: string;
  integrationData?: IntegrationData | null;
  label: string;
  shortDescription: string;
  onConnect: () => void;
  onToggleSwitch: (status: boolean) => void;
  onEdit: () => void;
  onDelete: () => void;
}

function CardIntegration({
  id,
  label,
  shortDescription,
  thumb,
  integrationData,
  onConnect,
  onToggleSwitch,
  onEdit,
  onDelete,
}: CardIntegrationProps) {
  return (
    <Container key={id}>
      <div className="header-integration">
        <img src={thumb} width={50} height={50} alt="analytics" />

        {integrationData === null ? (
          <ButtonConnect onClick={onConnect}>
            <span>Conectar</span>
          </ButtonConnect>
        ) : null}

        {integrationData !== null ? (
          <div className="switch">
            <Switch
              checked={integrationData?.status ?? false}
              onChange={onToggleSwitch}
            />
          </div>
        ) : null}
      </div>

      <div className="content-integration">
        <div className="texts">
          <h2>
            {label}&nbsp;
            {integrationData?.event_ticket_id?.name
              ? `- ${integrationData.event_ticket_id.name}`
              : null}
          </h2>

          <span>{shortDescription}</span>
        </div>

        {integrationData !== null ? (
          <div className="actions-container">
            <div onClick={onEdit} className="badge">
              {id !== 3 ? (
                <MdEdit size={22} color={Colors.primary100} />
              ) : (
                <IoMdEye size={22} color={Colors.primary100} />
              )}
            </div>
            {id === 3 && (
              <div onClick={onDelete} className="badge">
                <IoMdTrash size={22} color={Colors.primary100} />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </Container>
  );
}

export default CardIntegration;
