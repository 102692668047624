import { authClient } from "services/authClient";

export interface DoManualSaleDTO {
  event_id: string;
  event_ticket_id: string;
  payment_status: string;
  holder_name: string;
  holder_email: string;
  holder_document: string;
  payment_type: string;
}

export async function doImportPurchases(body: FormData) {
  const { data } = await authClient.post(`/purchase/import`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
}

export async function doManualSale(body: DoManualSaleDTO) {
  const { data } = await authClient.post("/purchase/manual", body);

  return data;
}
