import { BiChevronDown } from "react-icons/bi";

import { Colors } from "constants/colors";

import { DrawerOverlay } from "components/DrawerOverlay";
import { CheckoutComponent } from "../CheckoutComponent";

interface DrawerCheckoutProps {
  isVisible: boolean;
  setIsVisible(value: boolean): void;
}

export function DrawerCheckout({
  isVisible,
  setIsVisible,
}: DrawerCheckoutProps) {
  return (
    <DrawerOverlay
      title="Check-out"
      icon={
        <BiChevronDown
          color={Colors.white}
          size={24}
          onClick={() => setIsVisible(false)}
        />
      }
      isVisible={isVisible}
      handleOk={() => setIsVisible(false)}
    >
      <CheckoutComponent key="checkout" />
    </DrawerOverlay>
  );
}
