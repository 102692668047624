import { Colors } from "constants/colors";

import {
  BackgroundTicket,
  ButtonBack,
  ButtonPrintTicket,
  CenterTitle,
  ContainerBottom,
  ContainerButtonBack,
  ContainerContentModalTicket,
  ContainerModalTicketDetail,
  DivBorder,
  DivLogo,
  ModalStyled,
} from "pages/Profile/MyTicket/styles";
import { FiMapPin } from "react-icons/fi";
import { RiCalendar2Line, RiCloseCircleLine } from "react-icons/ri";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";

import print_ticket_icon from "assets/print-ticket-icon.svg";
import event_ticket from "assets/event-ticket.svg";
import { TicketPrintType } from "components/TicketForPrint";

interface ModalTicketDetailProps {
  selectedTicket?: TicketPrintType | null;
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
  componentRef: any;
}

export function ModalTicketDetail({
  selectedTicket,
  isModalVisible,
  handleOk,
  handleCancel,
  componentRef,
}: ModalTicketDetailProps) {
  return (
    <ModalStyled
      width={705}
      visible={isModalVisible}
      onOk={handleOk}
      style={{ marginTop: "-60px" }}
      onCancel={handleCancel}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <>
        <CenterTitle>
          <h1 className="visualization">Visualizar ingresso</h1>
        </CenterTitle>

        <ContainerModalTicketDetail>
          <BackgroundTicket>
            <img src={selectedTicket?.event?.thumb} alt="Logo" />

            <div className="content">
              <h3>{selectedTicket?.event?.name}</h3>

              <div className="text">
                <FiMapPin size={18} color={Colors.white} />
                <h4>
                  {selectedTicket?.event?.address_name}{" "}
                  {selectedTicket?.event?.address_street
                    ? `| ${selectedTicket?.event?.address_street}`
                    : ""}
                </h4>
              </div>

              <div className="text">
                <RiCalendar2Line size={18} color={Colors.white} />
                <p>
                  {selectedTicket?.event?.begin_date},{" "}
                  {selectedTicket?.event?.begin_hour}
                </p>
              </div>
            </div>
          </BackgroundTicket>

          <DivBorder>
            <ContainerContentModalTicket>
              <div className="selectedTicket">
                <h3 className="selectedTicket">Dados do ingresso</h3>

                <div className="table">
                  <h5>Nome do comprador</h5>
                  <p>{selectedTicket?.holder_name}</p>
                </div>

                <div className="table">
                  <h5>Ingresso</h5>
                  <p>{selectedTicket?.event_ticket_id?.name}</p>
                </div>

                <div className="table">
                  <h5>Código da compra</h5>
                  <p>{selectedTicket?.event_ticket_id?._id}</p>
                </div>
              </div>

              <div className="qr">
                <div className="shadow">
                  <QRCode
                    size={150}
                    value={selectedTicket?.ticket_code || ""}
                  />
                </div>

                <h1>{selectedTicket?.ticket_code ?? ""}</h1>
              </div>

              <div className="qr-mobile">
                <QRCode size={90} value={selectedTicket?.ticket_code || ""} />
              </div>
            </ContainerContentModalTicket>
            <DivLogo>
              <div className="all-content">
                <img src={event_ticket} alt="Logo" />
                <span>A VIDA É UM EVENTO!</span>
              </div>
            </DivLogo>
          </DivBorder>

          <ContainerBottom>
            <ReactToPrint
              // documentTitle={
              //   data?.event && data?.event?.name
              //     ? data?.event.name
              //     : ""
              // }
              trigger={() => (
                <ButtonPrintTicket>
                  <img
                    src={print_ticket_icon}
                    width="18px"
                    height="18px"
                    alt="print"
                  />

                  <span>Imprimir ingresso</span>
                </ButtonPrintTicket>
              )}
              content={() => componentRef?.current}
            />
          </ContainerBottom>

          <ContainerButtonBack>
            <ButtonBack onClick={handleOk}>
              <span>Voltar</span>
            </ButtonBack>
          </ContainerButtonBack>
        </ContainerModalTicketDetail>
      </>
    </ModalStyled>
  );
}
