import { useEffect, useState } from "react";

import { Spin, Switch } from "antd";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Colors } from "constants/colors";

import { LoadingOutlined } from "@ant-design/icons";
import { RiCloseCircleLine, RiKeyLine } from "react-icons/ri";
import { BiCodeAlt } from "react-icons/bi";
import { MdSave } from "react-icons/md";

import { authClient } from "services/authClient";

import {
  ButtonCancel,
  ButtonSave,
  ContainerActiveIntegration,
  ContainerButtonsModal,
  ContainerDateIntegration,
  ContainerDetailIntegration,
  ContainerHeaderIntegration,
  ContainerModal,
  FieldWrapper,
  FormFull,
  FormLabel,
  ModalStyled,
} from "./styles";

import {
  errorNotification,
  successNotification,
} from "components/Notification";
import { Integration } from "contexts/IntegrationsContext";
import { SelectTicketFilter } from "components/FiltersSelect/SelectTicketFilter";
import { TicketEvent } from "types";
import { ModalFilterByTicket } from "components/ModalFilterByTicket";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

type IntegrationFormData = {
  key: string;
  token: string;
};

type XgrowIntegrationFormData = {
  status: boolean;
  ticketId: string;
};

const integrationFormSchema = yup.object().shape({
  key: yup.string().required("Key obrigatória"),
  token: yup.string().required("Token obrigatório"),
});

const xgrowIntegrationFormSchema = yup.object().shape({
  status: yup.boolean().required("Status obrigatório"),
  ticketId: yup.string().required("Ticket obrigatório"),
});

interface ModalAddIntegrationProps {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
  currentIntegration: Integration | null;
  event_id: string | number;
  getIntegrations(): void;
}

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
);

export function ModalAddIntegration({
  isModalVisible,
  handleOk,
  handleCancel,
  currentIntegration: selectedIntegration,
  event_id,
  getIntegrations,
}: ModalAddIntegrationProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<IntegrationFormData>({
    resolver: yupResolver(integrationFormSchema),
  });

  const {
    control: controlXgrow,
    resetField: resetFieldXgrow,
    formState: { errors: errorsXgrow, isSubmitting: isSubmittingXgrow },
    setValue: setValueXgrow,
    reset: resetXgrow,
    handleSubmit: handleSubmitXgrow,
    clearErrors: clearErrorsXgrow,
  } = useForm<XgrowIntegrationFormData>({
    resolver: yupResolver(xgrowIntegrationFormSchema),
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeSwitch, setActiveSwitch] = useState(true);
  const [selectedTickets, setSelectedTickets] = useState<Array<TicketEvent>>(
    []
  );

  const isXgrowIntegration = selectedIntegration?._id === 3;

  const handleClearTickets = () => {
    setSelectedTickets([]);
    resetFieldXgrow("ticketId");
    clearErrorsXgrow("ticketId");
  };

  const createIntegration: SubmitHandler<IntegrationFormData> = async ({
    key,
    token,
  }) => {
    try {
      const data = {
        type: selectedIntegration?._id,
        event_id: event_id,
        key,
        token,
        status: activeSwitch,
      };

      await authClient.post("/event-integration", {
        ...data,
      });

      reset();

      getIntegrations();

      handleOk();

      successNotification(
        "Configurações salvas! ",
        `As configurações da integração ${selectedIntegration?.label} foram salvas com sucesso.`,
        2
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorNotification(
          "Erro ao realizar integração",
          error?.response?.data?.message
        );

        return;
      }

      errorNotification(
        "Erro ao realizar integração",
        "Tente novamente mais tarde"
      );
    }
  };

  const createIntegrationXgrow: SubmitHandler<
    XgrowIntegrationFormData
  > = async ({ status, ticketId }) => {
    try {
      const body = {
        type: 3,
        event_id,
        event_ticket_id: ticketId,
        status,
      };

      await authClient.post("/event-integration", {
        ...body,
      });

      resetXgrow();
      handleClearTickets();
      getIntegrations();

      successNotification(
        "Configurações salvas!",
        `As configurações da integração ${selectedIntegration?.label} foram salvas com sucesso.`,
        2
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorNotification(
          "Erro ao realizar integração",
          error?.response?.data?.message
        );

        return;
      }

      errorNotification(
        "Erro ao realizar integração",
        "Tente novamente mais tarde"
      );
    } finally {
      resetXgrow();
      handleClearTickets();
      searchParams.delete("tickets");
      handleOk();
    }
  };

  const renderIntegrationForm = () => {
    if (selectedIntegration?._id === 3) {
      return (
        <>
          <ContainerActiveIntegration>
            <Controller
              control={controlXgrow}
              defaultValue={true}
              name="status"
              render={({ field: { onChange, value } }) => (
                <Switch
                  defaultChecked={true}
                  checked={value}
                  onChange={onChange}
                />
              )}
            />

            <h5>Ativar integração</h5>
          </ContainerActiveIntegration>

          <ContainerDateIntegration>
            <h3>Informe os dados abaixo</h3>

            <FormFull>
              <FieldWrapper>
                <SelectTicketFilter
                  title={
                    selectedTickets.length <= 0
                      ? "Selecione o ticket"
                      : selectedTickets[0]?.name
                  }
                  label="Selecione o ticket"
                  sortKey="events"
                />

                {errorsXgrow.ticketId?.message && (
                  <p className="error">{errorsXgrow.ticketId?.message}</p>
                )}
              </FieldWrapper>
            </FormFull>
          </ContainerDateIntegration>
        </>
      );
    }

    return (
      <>
        <ContainerDateIntegration>
          <h3>Informe os dados abaixo</h3>

          <FormFull>
            <div className="all-input">
              <FormLabel>
                <RiKeyLine size={23} color={Colors.secondary40} />
                <h2>ID do facebook pixel</h2>
              </FormLabel>

              <input type="text" {...register("key")} />

              {errors.key && <span>{errors.key.message}</span>}
            </div>

            <div className="all-input">
              <FormLabel>
                <BiCodeAlt size={23} color={Colors.secondary40} />
                <h2>Token de acesso</h2>
              </FormLabel>

              <input type="text" {...register("token")} />

              {errors.token && <span>{errors.token.message}</span>}
            </div>
          </FormFull>
        </ContainerDateIntegration>

        <ContainerActiveIntegration>
          <Switch
            defaultChecked={true}
            checked={activeSwitch}
            onChange={(e) => {
              setActiveSwitch(e);
            }}
          />

          <h5>Ativar integração</h5>
        </ContainerActiveIntegration>
      </>
    );
  };

  const handleSelectTicket = (e: Array<TicketEvent>) => {
    setSelectedTickets(e);
    setValueXgrow("ticketId", String(e[0]._id));
    clearErrorsXgrow("ticketId");
  };

  return (
    <>
      <ModalStyled
        className="modal-event"
        width={1000}
        visible={isModalVisible}
        onOk={handleOk}
        style={{ borderRadius: "20px" }}
        onCancel={() => {
          handleCancel();
        }}
        bodyStyle={{ borderRadius: 20 }}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "34px", marginLeft: "-34px" }}
            size={43}
            color={Colors.black40}
          />
        }
        footer={null}
      >
        <ContainerModal
          onSubmit={
            isXgrowIntegration
              ? handleSubmitXgrow(createIntegrationXgrow)
              : handleSubmit(createIntegration)
          }
        >
          <h2 className="title">Configurar integração</h2>

          <ContainerDetailIntegration>
            <ContainerHeaderIntegration>
              <div className="logo">
                <img
                  src={selectedIntegration?.thumb}
                  width={50}
                  height={50}
                  alt="thumb"
                />

                <h2 className="title-logo">{selectedIntegration?.label}</h2>
              </div>

              <p>
                {selectedIntegration?.description}{" "}
                <a
                  target="_blank"
                  href={selectedIntegration?.link}
                  rel="noreferrer"
                >
                  Saiba mais sobre o {selectedIntegration?.label}.
                </a>
              </p>
            </ContainerHeaderIntegration>

            {renderIntegrationForm()}
          </ContainerDetailIntegration>

          <ContainerButtonsModal>
            <ButtonCancel
              onClick={() => {
                handleCancel();
              }}
            >
              <span>Cancelar</span>
            </ButtonCancel>

            <ButtonSave type="submit">
              {isSubmitting || isSubmittingXgrow ? (
                <Spin spinning={isSubmitting} indicator={antIcon} />
              ) : null}
              {!isSubmitting || !isSubmittingXgrow ? (
                <MdSave size={28} color={Colors.white} />
              ) : null}
              <span>Salvar</span>
            </ButtonSave>
          </ContainerButtonsModal>
        </ContainerModal>
      </ModalStyled>

      <ModalFilterByTicket
        isMultiple={false}
        onSelectTickets={handleSelectTicket}
        onClearTickets={handleClearTickets}
        defaultEvent={String(event_id)}
      />
    </>
  );
}
