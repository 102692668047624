import styled from "styled-components";

import { Colors } from "constants/colors";
import { Modal } from "antd";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 44px 39px 64px 39px;

  @media (max-width: 800px) {
    padding: 24px 10px 24px 10px;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  h1 {
    width: 100%;
    color: ${Colors.secondary100};
    text-align: center;
    font-family: Poppins;
    font-size: 1.87rem;
    font-style: normal;
    font-weight: 600;

    @media (max-width: 800px) {
      font-size: 20px;
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  border-radius: 20px;
  border: 1px solid ${Colors.black10};
  background: ${Colors.white};
  margin-top: 2.62rem;
  padding: 2.34rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid ${Colors.secondary10};
  margin-top: 1.87rem;
  margin-bottom: 1.87rem;
`;

export const DividerWithMargin = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid ${Colors.secondary10};
  margin-top: 1.87rem;
  margin-bottom: 1.87rem;
`;

export const Title = styled.h2`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
`;

export const TitleLast = styled.h3`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.21rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 2.34rem;
`;

export const GridItems = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.4rem;
  grid-gap: 10px;

  a {
    height: 100%;
  }
`;

export const Item = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  padding: 0.93rem 0.56rem 0.93rem 0.93rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.65rem;
  border: 1px solid ${Colors.black10};
  background: ${Colors.white};

  div.column-content {
    display: flex;
    flex-direction: column;
  }
`;

export const TitleTotal = styled.h4`
  color: ${Colors.black30};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
`;

export const TextTotal = styled.h5`
  color: ${Colors.secondary100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const TextTotalSmall = styled.h5`
  color: ${Colors.secondary100};
  font-family: Cairo;
  font-size: 1.21rem;
  font-style: normal;
  font-weight: 600;
`;

export const SubTitle = styled.h6`
  color: ${Colors.black50};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.5rem;
`;

export const ContainerTable = styled.div`
  margin-top: 1.4rem;

  table td {
    border-right-width: 0px;
    border-bottom-width: 0px;

    h5 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 0.93rem;
      margin-bottom: 0px;
      margin-top: 0px;

      color: ${Colors.black80};
    }
  }

  table {
    width: 100%;

    min-width: 600px;
    border-style: hidden;

    border-collapse: separate;
    border-spacing: 0 11px;

    th {
      background-color: ${Colors.white};
      padding: 1rem;
      text-align: left;
      color: ${Colors.white};
      font-size: 0.875rem;
      line-height: 1.6;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      margin-bottom: 0px;

      color: ${Colors.black30};

      &:first-child {
        border-top-left-radius: 8px;
        padding-left: 1.5rem;
      }

      &:last-child {
        border-top-right-radius: 8px;
        /* padding-right: 1.5rem; */
      }
    }

    td {
      padding: 11px;
      background: ${Colors.lightgray10};

      h6 {
        color: ${Colors.black70};
        font-family: Cairo;
        font-size: 0.93rem;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 0px;
      }

      h6.green {
        color: ${Colors.success};
        font-family: Open Sans;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0px;
      }
    }

    td:first-child {
      /* border-left-style: solid; */
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;

      img {
        border-radius: 6px;
      }

      div.row {
        display: flex;
        flex-direction: row;

        div.column {
          display: flex;
          flex-direction: column;
          margin-left: 7px;

          h5 {
            font-family: "Cairo";
            font-style: normal;
            font-weight: 700;
            font-size: 0.93rem;
            margin-bottom: 0px;
            margin-top: 0px;

            color: ${Colors.black80};
          }
        }
      }
    }

    td:last-child {
      border-bottom-right-radius: 11px;
      border-top-right-radius: 11px;
    }

    tbody {
      tr {
        display: table-row;
        border-radius: 0.93rem 0px 0px 0.93rem;
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }
      tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }
    }

    td {
      margin-bottom: 20px;
      padding: 1rem;
      font-size: 0.875rem;
      line-height: 1.6;

      div.date {
        display: flex;
        flex-direction: column;

        h5 {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 700;
          font-size: 0.93rem;
          margin-bottom: 0px;
          margin-top: 0px;

          color: ${Colors.black80};
        }

        h6 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          margin-bottom: 0px;
          margin-top: 4px;
          color: ${Colors.black70};
        }
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        margin-bottom: 0px;

        color: ${Colors.black70};
      }

      margin-bottom: 0.56rem;

      &:first-child {
        padding-left: 1.5rem;
        border-top-left-radius: 8px;
      }

      &:last-child {
        padding-right: 0.5rem;
        border-radius: 0px 0.93rem 0.93rem 0px;
      }
    }
  }
`;

export const ButtonLeft = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
  background: ${Colors.primary10};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.34rem;
`;

export const RowTitle = styled.h3`
  color: ${Colors.black100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;
`;

export const RowTitleContent = styled.h2`
  color: ${Colors.black60};
  font-family: Cairo;
  font-size: 1.31rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
`;

export const SubTitleDetails = styled.h4`
  color: ${Colors.black60};
  font-family: Open Sans;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.75rem;
  margin-bottom: 0px;
`;
