import { editOrganizerIdentificationFormSchema } from "lib/validations/editOrganizer";
import { IdentificationCreateOrganizer, OrganizerResponse } from "types";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { Select, Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import axios from "axios";
import {
  ButtonCancel,
  ButtonEdit,
  ButtonSave,
  Container,
  ContainerButtonsEdit,
  ContainerButtonsSave,
  Divider,
} from "./styles";

import {
  GridInputs,
  TitleWithMargin,
  TitleWithMarginTop,
} from "pages/OrganizerCadaster/Steps/Identification/styles";
import { InputForm } from "components/Form/InputForm";
import {
  RiBuilding4Line,
  RiCalendar2Line,
  RiCommunityLine,
  RiContactsLine,
  RiMapPinAddLine,
  RiUserLine,
} from "react-icons/ri";
import { Colors } from "constants/colors";
import { AiOutlineMail } from "react-icons/ai";
import { CgSmartphone } from "react-icons/cg";
import { useCallback, useEffect, useState } from "react";
import { FaPen } from "react-icons/fa";
import { MdSave } from "react-icons/md";
import { useParams } from "react-router-dom";

import { EditOrganizer, useAuthOrganizerById } from "services/Hook/Organizer";
import moment from "moment";
import { ModalSuccess } from "components/ModalSuccess";
import { useDisclosure } from "hooks/useDisclosure";
import {
  BorderSelect,
  ContainerSelect,
  LabelSelect,
  StyledSelect,
} from "pages/MyEvent/New/Steps/Local/Form/styles";
import { ufs } from "constants/ufs";
import { errorNotification } from "components/Notification";

const { Option } = Select;

export interface Organizer {
  _id: string;
  address_city: string;
  address_country: string;
  address_number: string;
  address_state: string;
  address_street: string;
  address_zipcode: string;
  agent_birthdate: string;
  formmatedAgentBirthdate: string;
  business_email: string;
  description: string;
  document: string;
  name: string;
  nickname: string;
  pxpay_id: number;
  fee: number;
  type_document: number;
  user: string;
  address_complement: string | null;
  address_disctrict: string;
  agent_document: string;
  business_phone: string;
  document_city: string | null;
  agent_email: string;
  document_name: string | null;
  agent_name: string;
  agent_phone: string;
}

export function Form() {
  const [isEditing, setIsEditing] = useState(false);

  const { id } = useParams();

  const {
    control,
    register,
    watch,
    setFocus,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm<IdentificationCreateOrganizer>({
    resolver: yupResolver(editOrganizerIdentificationFormSchema),
  });

  const typeDocument = watch("type_document");

  const { data } = useAuthOrganizerById<OrganizerResponse>(String(id));

  const { handleOk, isModalVisible, showModal } = useDisclosure();

  useEffect(() => {
    reset({
      address_city: data?.organizer?.address_city,
      address_country: data?.organizer?.address_country,
      address_disctrict: data?.organizer?.address_disctrict,
      address_number: data?.organizer?.address_number,
      address_state: data?.organizer?.address_state,
      address_street: data?.organizer?.address_street,
      address_zipcode: data?.organizer?.address_zipcode,
      agent_birthdate: data?.organizer?.formmatedAgentBirthdate,
      agent_document: data?.organizer?.agent_document,
      agent_email: data?.organizer?.agent_email,
      agent_name: data?.organizer?.agent_name,
      agent_phone: data?.organizer?.agent_phone,
      business_document: data?.organizer?.business_document,
      business_email: data?.organizer?.business_email,
      business_phone: data?.organizer?.business_phone,
      description: data?.organizer?.description,
      document: data?.organizer?.document,
      document_city: data?.organizer?.document_city ?? "",
      document_name: data?.organizer?.document_name ?? "",
      name: data?.organizer?.name,
      nickname: data?.organizer?.nickname,
      type_document: data?.organizer?.type_document ?? 1,
    });
  }, [data, reset]);

  const addressZipCode = watch("address_zipcode");

  const handleSearchAddress = useCallback(async () => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        try {
          const response = await axios.get(
            `https://viacep.com.br/ws/${addressZipCode}/json/`
          );

          const { logradouro, bairro, localidade, uf } = response.data;

          if (bairro === undefined) {
            setError("address_zipcode", {
              type: "manual",
              message: "Não foi possivel encontrar o Cep",
            });
            return;
          }

          clearErrors("address_zipcode");

          setValue("address_street", logradouro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_disctrict", bairro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_city", localidade, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_state", uf, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } catch (err) {
          setError("address_zipcode", {
            type: "required",
            message: "Não foi possivel encontrar o Cep",
          });
        }
      }
    }
  }, [addressZipCode, clearErrors, setError, setValue]);

  const handleEditIdentification: SubmitHandler<
    IdentificationCreateOrganizer
  > = async ({ ...rest }) => {
    const {
      address_zipcode,
      address_number,
      address_street,
      address_city,
      address_disctrict,
      address_state,
    } = rest;

    const {
      agent_email,
      agent_birthdate,
      agent_phone,
      type_document,
      agent_name,
      business_email,
      business_phone,
      description,
      document,
      name,
      nickname,
      document_city,
      document_name,
      business_document,
    } = rest;

    try {
      const dateBirthdate = moment(agent_birthdate, "DD/MM/YYYY");

      const dateWithComas = dateBirthdate.format("YYYY-MM-DD");

      const credentials = {
        type_document,
        agent_name: type_document === 2 ? agent_name : name,
        agent_document: document,
        document: type_document === 1 ? document : business_document,
        agent_birthdate: dateWithComas,
        agent_email: agent_email ?? "",
        agent_phone: agent_phone ?? "",
        name: type_document === 1 ? name : agent_name,
        description: description ?? "Este é um organizador",
        document_name: type_document === 2 ? document_name : name,
        document_city: document_city ?? address_city,
        address_zipcode,
        address_street,
        address_country: "BR",
        address_disctrict,
        address_number: address_number ?? "1",
        address_state,
        address_city,
        address_complement: "",
        business_email: business_email ?? agent_email,
        business_phone: type_document === 1 ? agent_phone : business_phone,
        nickname,
      };

      await EditOrganizer({ id: String(id), credentials });

      showModal();
    } catch (err: any) {
      if (err.response.data.message === "Documento já cadastrado") {
        errorNotification(
          "Erro ao cadastrar organizador",
          "Documento já cadastrado"
        );

        setError("document", {
          type: "required",
          message: "Documento já cadastrado",
        });

        setFocus("document");

        return;
      }

      if (err.response.data.error.category === "duplicate_taxpayer_id") {
        errorNotification(
          "Erro ao cadastrar organizador",
          "Não foi possivel cadastrar o organizador, tente novamente"
        );

        if (typeDocument === 1) {
          setError("document", {
            type: "required",
            message: "Documento já cadastrado",
          });

          setFocus("document");
        }
      }

      if (err.response.data.message === "Nickname já existe" || err.response.data.message.includes("Link para página eventx indisponível")) {
        errorNotification(
          "Erro ao cadastrar organizador",
          "Não foi possivel cadastrar o organizador, tente novamente"
        );

        setError("nickname", {
          type: "required",
          message: "Nickname já existe",
        });
        setFocus("nickname");
      }
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  useEffect(() => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        handleSearchAddress();
      }
    }
  }, [addressZipCode, handleSearchAddress]);

  return (
    <Container
      autoComplete="off"
      onSubmit={handleSubmit(handleEditIdentification)}
    >
      {typeDocument === 1 ? (
        <TitleWithMargin>Seus dados de identificação</TitleWithMargin>
      ) : null}

      {typeDocument === 2 ? (
        <TitleWithMargin>Dados do representante da empresa</TitleWithMargin>
      ) : null}

      {typeDocument === 1 ? (
        <InputForm
          isFull
          {...register("name")}
          icon={<RiUserLine size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          error={errors.name}
          label="Nome completo"
        />
      ) : null}

      {typeDocument === 2 ? (
        <InputForm
          isFull
          {...register("agent_name")}
          icon={<RiUserLine size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          error={errors.name}
          label="Nome completo"
        />
      ) : null}

      <GridInputs>
        <InputForm
          isFull
          {...register("document")}
          mask={typeDocument === 1 ? "cpf" : "cnpj"}
          icon={<RiContactsLine size={17} color={Colors.secondary40} />}
          isEditing={false}
          disabled
          error={errors.document}
          label={typeDocument === 1 ? "CPF" : "CNPJ"}
        />

        <InputForm
          isFull
          {...register("agent_birthdate")}
          mask="birthdate"
          icon={<RiCalendar2Line size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          error={errors.agent_birthdate}
          label="Data de nascimento"
        />
      </GridInputs>

      <GridInputs>
        <InputForm
          isFull
          {...register("agent_email")}
          icon={<AiOutlineMail size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          error={errors.agent_email}
          label="E-mail"
        />

        <InputForm
          isFull
          {...register("agent_phone")}
          mask="phone"
          icon={<CgSmartphone size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          error={errors.agent_phone}
          label="Telefone"
        />
      </GridInputs>

      <GridInputs>
        <InputForm
          isFull
          {...register("address_zipcode")}
          placeholder="Insira o CEP do local..."
          mask="cep"
          error={errors.address_zipcode}
          icon={<RiMapPinAddLine size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          label="CEP"
        />

        <InputForm
          isFull
          {...register("address_street")}
          placeholder="Insira o endereço do local..."
          error={errors.address_street}
          icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          label="Endereço"
        />
      </GridInputs>

      <GridInputs>
        <InputForm
          isFull
          {...register("address_number")}
          placeholder="Insira o número do local..."
          error={errors.address_number}
          icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          label="Número"
        />

        <InputForm
          isFull
          {...register("address_complement")}
          placeholder="Insira o complemento do endereço..."
          error={errors.address_complement}
          icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          label="Complemento"
        />
      </GridInputs>

      <GridInputs>
        <InputForm
          isFull
          {...register("address_disctrict")}
          placeholder="Insira o bairro do local..."
          error={errors.address_disctrict}
          icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          label="Bairro"
        />

        <InputForm
          isFull
          {...register("address_city")}
          placeholder="Insira a cidade do endereço..."
          error={errors.address_city}
          icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
          isEditing={isEditing}
          label="Cidade"
        />
      </GridInputs>

      <ContainerSelect>
        <LabelSelect>
          <h4>Estado</h4>
        </LabelSelect>

        <Controller
          control={control}
          name="address_state"
          rules={{ required: "Salutation is required" }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <BorderSelect>
              <StyledSelect
                size="large"
                labelInValue
                bordered={false}
                placeholder="Escolha o estado"
                value={value}
                disabled={isEditing}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                onChange={(e: any) => {
                  onChange(e.value);
                }}
              >
                {ufs.map((uf) => {
                  return (
                    <Option value={uf.sigla} key={uf.id}>
                      {uf.nome}
                    </Option>
                  );
                })}
              </StyledSelect>
            </BorderSelect>
          )}
        />
      </ContainerSelect>

      <InputForm
        isFull
        {...register("nickname")}
        mask="especial"
        hasPrefix
        prefix="eventx.com.br/"
        icon={<RiUserLine size={17} color={Colors.secondary40} />}
        isEditing={isEditing}
        error={errors.nickname}
        label="Link Eventx"
      />

      {typeDocument === 2 ? (
        <>
          <TitleWithMarginTop>Dados da empresa</TitleWithMarginTop>

          <GridInputs>
            <InputForm
              isFull
              {...register("business_document")}
              mask="cnpj"
              icon={<RiContactsLine size={17} color={Colors.secondary40} />}
              isEditing={false}
              disabled
              error={errors.business_document}
              label="CNPJ"
            />

            <InputForm
              isFull
              {...register("document_name")}
              icon={<RiBuilding4Line size={17} color={Colors.secondary40} />}
              isEditing={isEditing}
              error={errors.document_name}
              label="Razão social"
            />
          </GridInputs>

          <GridInputs>
            <InputForm
              isFull
              {...register("document_name")}
              icon={<RiBuilding4Line size={17} color={Colors.secondary40} />}
              isEditing={isEditing}
              error={errors.document_name}
              label="Nome fantasia"
            />

            <InputForm
              isFull
              {...register("document_city")}
              icon={<RiBuilding4Line size={17} color={Colors.secondary40} />}
              isEditing={isEditing}
              error={errors.document_city}
              label="Inscrição municipal"
            />
          </GridInputs>

          <GridInputs>
            <InputForm
              isFull
              {...register("business_phone")}
              mask="phone"
              icon={<AiOutlineMail size={17} color={Colors.secondary40} />}
              isEditing={isEditing}
              error={errors.business_phone}
              label="Telefone comercial"
            />

            <InputForm
              isFull
              {...register("business_email")}
              icon={<CgSmartphone size={17} color={Colors.secondary40} />}
              isEditing={isEditing}
              error={errors.business_email}
              label="E-mail comercial"
            />
          </GridInputs>
        </>
      ) : null}

      <Divider />

      {!isEditing ? (
        <ContainerButtonsEdit>
          <ButtonEdit type="button" onClick={() => setIsEditing(true)}>
            <div className="icon">
              <FaPen width={18} height={18} color={Colors.primary100} />
            </div>

            <span>Editar</span>
          </ButtonEdit>
        </ContainerButtonsEdit>
      ) : null}

      {isEditing ? (
        <ContainerButtonsSave>
          <ButtonCancel
            type="button"
            onClick={() => {
              setIsEditing(false);
            }}
          >
            <span>Cancelar</span>
          </ButtonCancel>

          <ButtonSave type="submit">
            {!isSubmitting ? (
              <MdSave className="icon-d" size={23} color={Colors.white} />
            ) : (
              <Spin spinning={isSubmitting} indicator={antIcon} />
            )}
            <span>Salvar</span>
          </ButtonSave>
        </ContainerButtonsSave>
      ) : null}

      <ModalSuccess
        handleCancel={handleOk}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        title="Dados atualizados!"
        subtitle="Dados atualizados com sucesso! Clique em Ok para continuar."
      />
    </Container>
  );
}
