import { RiCloseCircleLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import { CheckoutComponent } from "../CheckoutComponent";

import { ModalStyled } from "./styles";

interface ModalCheckoutProps {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}

export function ModalCheckout({
  isModalVisible,
  handleOk,
  handleCancel,
}: ModalCheckoutProps) {
  return (
    <ModalStyled
      width={857}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          size={43}
          color={Colors.black40}
          style={{ marginTop: 30, marginRight: 60 }}
        />
      }
      footer={null}
    >
      <CheckoutComponent key="checkout" />
    </ModalStyled>
  );
}
