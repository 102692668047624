import { useState } from "react";

import { FiChevronRight } from "react-icons/fi";

import { Colors } from "constants/colors";

import {
  Column,
  Container,
  ContainerItems,
  Divider,
  Row,
  Title,
  TitlePrice,
  TitleTotal,
} from "./styles";

import { DrawerOverlay } from "components/DrawerOverlay";

import { useGeneralContext } from "contexts/GeneralContext";
import { BiChevronDown, BiChevronLeft } from "react-icons/bi";

import { formatPrice } from "utils/formatPrice";
import { Link } from "react-router-dom";

interface DrawerGeneralChargebackProps {
  total: number;
  fee: number;
}

export function DrawerGeneralChargeback({
  total,
  fee,
}: DrawerGeneralChargebackProps) {
  const [selectedPurchase] = useState<"resume" | "detail" | null>(null);

  const { isOpenDrawerGeneralChargeback, setIsOpenDrawerGeneralChargeback } =
    useGeneralContext();

  const title: { [key: string]: any } = {
    resume: "Resumo das vendas",
    detail: "Detalhes da venda",
    default: "Estornos",
  };

  return (
    <DrawerOverlay
      title={title[selectedPurchase ?? "default"]}
      icon={
        !selectedPurchase ? (
          <BiChevronDown
            color={Colors.white}
            size={24}
            onClick={() => setIsOpenDrawerGeneralChargeback(false)}
          />
        ) : (
          <BiChevronLeft color={Colors.white} size={24} onClick={() => {}} />
        )
      }
      isVisible={isOpenDrawerGeneralChargeback}
      handleOk={() => setIsOpenDrawerGeneralChargeback(false)}
    >
      {selectedPurchase === null ? (
        <Container>
          <ContainerItems>
            <Title>Vendas reembolsadas</Title>

            <Divider />

            <Link
              to="/financial?page=1&tab_name=transactions&data_type=choose&payment_status=REFUND&type=eventx-checkout"
              reloadDocument
            >
              <Row>
                <Column>
                  <TitleTotal>saldo estornado</TitleTotal>

                  <TitlePrice>{formatPrice(total ?? 0)}</TitlePrice>
                </Column>

                <FiChevronRight color={Colors.gray45} size={24} />
              </Row>
            </Link>
          </ContainerItems>
        </Container>
      ) : null}
    </DrawerOverlay>
  );
}
