import { createContext, useContext, useState } from "react";

type FiltersContextData = {
  isOpenFilterModalData: boolean;
  setIsOpenFilterModalData(value: boolean): void;
  isOpenFilterModalEvent: boolean;
  setIsOpenFilterModalEvent(value: boolean): void;
  isOpenFilterModalOrganizer: boolean;
  setIsOpenFilterModalOrganizer(value: boolean): void;
  isOpenFilterModalModalTicket: boolean;
  setIsOpenFilterModalTicket(value: boolean): void;
  isOpenFilterModalPrice: boolean;
  setIsOpenFilterModalPrice(value: boolean): void;
  isOpenFilterModalPayment: boolean;
  setIsOpenFilterModalPayment(value: boolean): void;
  isOpenFilterDrawerGeneral: boolean;
  setIsOpenFilterDrawerGeneral(value: boolean): void;
  isOpenFilterDrawerTransactions: boolean;
  setIsOpenFilterDrawerTransactions(value: boolean): void;
  isOpenFilterModalStatus: boolean;
  setIsOpenFilterModalStatus(value: boolean): void;
  isOpenModalFilterPurchaseType: boolean;
  setIsOpenModalFilterPurchaseType(value: boolean): void;
  isOpenDrawerFilterPurchaseType: boolean;
  setIsOpenDrawerFilterPurchaseType(value: boolean): void;
};

const FiltersContext = createContext<FiltersContextData>(
  {} as FiltersContextData
);

export const FiltersContextProvider: React.FC = ({ children }) => {
  const [isOpenFilterModalData, setIsOpenFilterModalData] = useState(false);
  const [isOpenFilterModalEvent, setIsOpenFilterModalEvent] = useState(false);
  const [isOpenFilterModalOrganizer, setIsOpenFilterModalOrganizer] =
    useState(false);
  const [isOpenFilterModalModalTicket, setIsOpenFilterModalTicket] =
    useState(false);
  const [isOpenFilterModalPrice, setIsOpenFilterModalPrice] = useState(false);
  const [isOpenFilterModalPayment, setIsOpenFilterModalPayment] =
    useState(false);
  const [isOpenFilterModalStatus, setIsOpenFilterModalStatus] = useState(false);

  const [isOpenFilterDrawerGeneral, setIsOpenFilterDrawerGeneral] =
    useState(false);
  const [isOpenFilterDrawerTransactions, setIsOpenFilterDrawerTransactions] =
    useState(false);

  const [isOpenModalFilterPurchaseType, setIsOpenModalFilterPurchaseType] =
    useState(false);
  const [isOpenDrawerFilterPurchaseType, setIsOpenDrawerFilterPurchaseType] =
    useState(false);

  return (
    <FiltersContext.Provider
      value={{
        isOpenFilterModalData,
        setIsOpenFilterModalData,
        isOpenFilterModalEvent,
        setIsOpenFilterModalEvent,
        isOpenFilterModalOrganizer,
        setIsOpenFilterModalOrganizer,
        isOpenFilterModalModalTicket,
        setIsOpenFilterModalTicket,
        isOpenFilterModalPrice,
        setIsOpenFilterModalPrice,
        isOpenFilterModalPayment,
        setIsOpenFilterModalPayment,
        isOpenFilterDrawerGeneral,
        setIsOpenFilterDrawerGeneral,
        isOpenFilterDrawerTransactions,
        setIsOpenFilterDrawerTransactions,
        isOpenFilterModalStatus,
        setIsOpenFilterModalStatus,
        isOpenModalFilterPurchaseType,
        setIsOpenModalFilterPurchaseType,
        isOpenDrawerFilterPurchaseType,
        setIsOpenDrawerFilterPurchaseType,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export function useFiltersContext(): FiltersContextData {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error(`useFiltersContext it depends FiltersContextProvider`);
  }

  return context;
}
