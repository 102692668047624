import { format, parseISO, addHours } from "date-fns";
import { ptBR } from "date-fns/locale";

const HOURS_TO_SUM = 3;

export function formatDateVanilla(inputDate: string) {
  try {
    const date = addHours(new Date(inputDate), HOURS_TO_SUM);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  } catch {
    return format(parseISO(inputDate), "dd/MM/y", {
      locale: ptBR,
    });
  }
}
