import { RiArrowLeftLine, RiCloseCircleLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  ButtonLeft,
  Card,
  ContainerHeader,
  ContainerModal,
  Divider,
  DividerWithMargin,
  GridItems,
  Item,
  ModalStyled,
  Row,
  RowTitle,
  SubTitle,
  SubTitleDetails,
  TextTotal,
  Title,
  TitleLast,
  TitleTotal,
} from "./styles";

import { useState } from "react";
import { useGeneralContext } from "contexts/GeneralContext";
import { FiChevronRight } from "react-icons/fi";
import { formatPrice } from "utils/formatPrice";
import { Link } from "react-router-dom";
import { Pagination } from "components/Pagination";
import { Transaction } from "types";
import { useSearchFinancialTransaction } from "hooks/useSearchFinancialTransaction";
import { BadgePurchaseByStatus } from "components/BadgePurchaseByStatus";
import { ContainerTable } from "components/ModalTotalPurchases/styles";
import { Center } from "pages/MyEvent/styles";
import { Loading } from "components/Loading";
import { EmptySearchComponent } from "components/EmptySearchComponent";

interface ModalGeneralTransferProps {
  total: number;
  fee: number;
}

export function ModalGeneralTransfer({
  total,
  fee,
}: ModalGeneralTransferProps) {
  const [selectedPurchase, setSelectedPurchase] = useState(false);

  const { data, page, onChange } = useSearchFinancialTransaction({
    statusFinancial: "PAID",
  });

  const { isOpenModalGeneralTransfer, setIsOpenModalGeneralTransfer } =
    useGeneralContext();

  const closeSelectedPurchase = () => {
    setSelectedPurchase(false);
  };

  const closeModal = () => {
    setIsOpenModalGeneralTransfer(false);
  };

  return (
    <ModalStyled
      width={1200}
      visible={isOpenModalGeneralTransfer}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <ContainerHeader>
          {selectedPurchase ? (
            <ButtonLeft onClick={closeSelectedPurchase}>
              <RiArrowLeftLine color={Colors.primary100} size={22} />
            </ButtonLeft>
          ) : null}

          <h1>Repasses da EventX</h1>
        </ContainerHeader>

        {!selectedPurchase ? (
          <Card>
            <Title>Taxas da EventX</Title>

            <GridItems>
              <Link
                to="/financial?page=1&tab_name=transactions&data_type=choose&payment_status=PAID&type=eventx-checkout"
                reloadDocument
              >
                <Item>
                  <div className="column-content">
                    <TitleTotal>TOTAL DE REPASSES</TitleTotal>

                    <TextTotal>{formatPrice(fee ?? 0)}</TextTotal>

                    <SubTitle>Vendas online dos ingressos</SubTitle>
                  </div>
                </Item>
              </Link>

              <Link
                to="/financial?page=1&tab_name=transactions&data_type=choose&payment_status=PAID&type=eventx-checkout"
                reloadDocument
              >
                <Item>
                  <div className="column-content">
                    <TitleTotal>VENDAS TOTAIS</TitleTotal>

                    <TextTotal>{formatPrice(total ?? 0)}</TextTotal>

                    <SubTitle>Vendas online dos ingressos</SubTitle>
                  </div>

                  <FiChevronRight color={Colors.gray45} size={24} />
                </Item>
              </Link>
            </GridItems>

            <Divider />

            {!data ? (
              <Center>
                <Loading />
              </Center>
            ) : null}

            {data && data?.results?.length === 0 ? (
              <EmptySearchComponent />
            ) : null}

            {data && data?.results?.length > 0 ? (
              <>
                <TitleLast>Taxas</TitleLast>

                <ContainerTable>
                  <table>
                    <thead>
                      <tr>
                        <th>EVENTO</th>
                        <th>STATUS</th>
                        <th>TAXA</th>
                        <th>DATA</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.results?.map((purchase: Transaction) => (
                        <Link
                          style={{ width: "100%" }}
                          to={`/financial/${purchase?._id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="table-link"
                        >
                          <tr style={{ cursor: "pointer" }}>
                            <td>
                              <h6>{purchase?.event_id?.name}</h6>
                            </td>

                            <td>
                              <BadgePurchaseByStatus
                                status={purchase?.payment_status}
                              />
                            </td>

                            <td>
                              <h6>
                                {formatPrice(
                                  purchase?.total_fee
                                    ? purchase?.total_fee / 100
                                    : 0
                                )}
                              </h6>
                            </td>

                            <td>
                              <h6>{purchase?.formattedDate}</h6>
                            </td>

                            <td>
                              <FiChevronRight color={Colors.gray45} size={24} />
                            </td>
                          </tr>
                        </Link>
                      ))}
                    </tbody>
                  </table>
                </ContainerTable>

                <div className="pagination">
                  <Pagination
                    title="registros"
                    totalCountOfRegisters={data?.count}
                    currentPage={Number(page)}
                    onPageChange={onChange}
                    registersPerPage={10}
                  />
                </div>
              </>
            ) : null}
          </Card>
        ) : null}

        {selectedPurchase ? (
          <Card>
            <Title>Detalhes dos repasses da EventX</Title>

            <SubTitleDetails>18 ABR 2021 - 13:21h</SubTitleDetails>

            <DividerWithMargin />

            <Title>Receita</Title>

            <SubTitleDetails>
              Apenas os valores de pagamentos transacionados pela Event X.
            </SubTitleDetails>

            <Row>
              <RowTitle>Venda dos ingressos online</RowTitle>
            </Row>

            <Divider />

            <Title>Repasses</Title>

            <SubTitleDetails>
              Taxa de venda dos ingressos online (9%).
            </SubTitleDetails>

            <Row>
              <RowTitle>Taxa de venda</RowTitle>
            </Row>

            <Divider />

            <Title>Subtotal</Title>

            <SubTitleDetails>
              Valor total a receber, subtraindo taxas de repasse.
            </SubTitleDetails>

            <Row>
              <RowTitle>Total a receber</RowTitle>
            </Row>
          </Card>
        ) : null}
      </ContainerModal>
    </ModalStyled>
  );
}
