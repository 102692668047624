import { Drawer as DrawerAntd } from "antd";
import { Sidebar } from "components/Header/styles";
import { Colors } from "constants/colors";
import { Link } from "react-router-dom";
import { buildMicroRoute } from "utils/urlBuilder";

interface DrawerProps {
  onCloseDrawer: () => void;
  visible: boolean;
  user?: any;
  hasOrganizer?: boolean;
  organizerId?: string;
  signOut: () => void;
  canCheckInOut?: boolean;
}

function Drawer({
  onCloseDrawer,
  visible,
  user,
  hasOrganizer,
  organizerId,
  signOut,
  canCheckInOut = false,
}: DrawerProps) {
  return (
    <DrawerAntd
      title="Basic Drawer"
      width={200}
      placement="left"
      onClose={onCloseDrawer}
      visible={visible && !!user}
      style={{ zIndex: 96, padding: 0 }}
      bodyStyle={{ zIndex: 96, padding: 0, background: Colors.white }}
    >
      <Sidebar>
        <div className="main">
          <div className="links">
            <div>
              <a href={buildMicroRoute("/")}>
                <h2>Buscar</h2>
              </a>

              <Link to="/profile">
                <h2>Meu Perfil</h2>
              </Link>

              <Link to="/profile/my-shopping">
                <h2>Minhas compras</h2>
              </Link>

              <Link to="/profile/my-ticket">
                <h2>Meus ingressos</h2>
              </Link>

              {hasOrganizer ? (
                <>
                  <a
                    style={{ textDecoration: "none" }}
                    href={`/organizer/profile/main/${organizerId}`}
                  >
                    <h3>Perfil do organizador</h3>
                  </a>

                  <a style={{ textDecoration: "none" }} href={"/my-event"}>
                    <h3>Meus eventos</h3>
                  </a>
                </>
              ) : (
                <a
                  style={{ textDecoration: "none", color: Colors.primary100 }}
                  href={buildMicroRoute("/organize-seu-evento")}
                >
                  <h3>Organize seu evento</h3>
                </a>
              )}

              {hasOrganizer && (
                <>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: Colors.primary100,
                    }}
                    to="/financial"
                  >
                    <h3>Financeiro</h3>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: Colors.primary100,
                    }}
                    to="/ticket-office"
                  >
                    <h3>Bilheteria</h3>
                  </Link>

                  <Link
                    style={{
                      textDecoration: "none",
                      color: Colors.primary100,
                    }}
                    to="/teams"
                  >
                    <h3>Meus times</h3>
                  </Link>
                </>
              )}

              {canCheckInOut ? (
                <Link
                  style={{
                    textDecoration: "none",
                    color: Colors.primary100,
                  }}
                  to="/organizer/ticket-check"
                >
                  <h3>Ticket Check-in</h3>
                </Link>
              ) : null}

              <a href={buildMicroRoute("/faq")}>
                <h2>Suporte</h2>
              </a>
            </div>
          </div>

          <div className="login">
            <div className="clickLink" onClick={() => signOut()}>
              <button className="cart">Sair</button>
            </div>
          </div>
        </div>
      </Sidebar>
    </DrawerAntd>
  );
}

export default Drawer;
