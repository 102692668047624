import { useState } from "react";
import {
  CollapseContent,
  CollapseHeader,
  CollapseHeaderContent,
  Container,
  TeamLabelContainer,
} from "./styles";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Divider } from "antd";
import { RiMailLine, RiPhoneLine } from "react-icons/ri";

interface ResourceCardMobileProps {
  name: string;
  email: string;
  phone: string;
  event: string;
  role: string;
  team: {
    name: string;
    color: string;
  };
}

function ResourceCardMobile({
  name,
  email,
  event,
  phone,
  role,
  team,
}: ResourceCardMobileProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleCollapse = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Container>
      <CollapseHeader
        className={`${isOpen ? "active" : ""}`}
        onClick={handleToggleCollapse}
      >
        <CollapseHeaderContent>
          <p>{name}</p>

          <TeamLabelContainer color={team.color}>
            <span>{team.name}</span>
          </TeamLabelContainer>
        </CollapseHeaderContent>

        {isOpen ? (
          <BiChevronUp size={22} color="#AAAAAA" />
        ) : (
          <BiChevronDown size={22} color="#AAAAAA" />
        )}
      </CollapseHeader>

      {isOpen && (
        <CollapseContent>
          <div className="items">
            <p className="item-title">Evento:</p>
            <p className="item-description">{event}</p>
          </div>

          <div className="items">
            <p className="item-title">Tipo de acesso:</p>
            <p className="item-description">{role}</p>
          </div>

          <Divider />

          <div className="items">
            <p className="item-title">
              <RiMailLine size={14} />
            </p>
            <p className="item-description">{email}</p>
          </div>

          {phone === "-" ? null : (
            <div className="items">
              <p className="item-title">
                <RiPhoneLine size={14} />
              </p>
              <p className="item-description">{phone}</p>
            </div>
          )}
        </CollapseContent>
      )}
    </Container>
  );
}

export default ResourceCardMobile;
