import { createContext, useContext, useState } from "react";

type GeneralContextData = {
  isOpenModalTotalPurchases: boolean;
  setIsOpenModalTotalPurchases(value: boolean): void;
  isOpenModalGeneralTransfer: boolean;
  setIsOpenModalGeneralTransfer(value: boolean): void;
  isOpenModalPendingBalance: boolean;
  setIsOpenModalPendingBalance(value: boolean): void;
  isOpenDrawerTotalPurchases: boolean;
  setIsOpenDrawerTotalPurchases(value: boolean): void;
  isOpenDrawerGeneralTransfer: boolean;
  setIsOpenDrawerGeneralTransfer(value: boolean): void;
  isOpenDrawerPendingBalance: boolean;
  setIsOpenDrawerPendingBalance(value: boolean): void;
  isOpenModalGeneralChargeback: boolean;
  setIsOpenModalGeneralChargeback(value: boolean): void;
  isOpenDrawerGeneralChargeback: boolean;
  setIsOpenDrawerGeneralChargeback(value: boolean): void;
  isOpenModalGeneralBalanceReceivable: boolean;
  setIsOpenModalGeneralBalanceReceivable(value: boolean): void;
  isOpenDrawerGeneralBalanceReceivable: boolean;
  setIsOpenDrawerGeneralBalanceReceivable(value: boolean): void;
  isOpenModalGeneralBalanceAvailable: boolean;
  setIsOpenModalGeneralBalanceAvailable(value: boolean): void;
  isOpenDrawerGeneralBalanceAvailable: boolean;
  setIsOpenDrawerGeneralBalanceAvailable(value: boolean): void;
  isOpenModalTotalPurchasesPaid: boolean;
  setIsOpenModalTotalPurchasesPaid(value: boolean): void;
  isOpenDrawerTotalPurchasesPaid: boolean;
  setIsOpenDrawerTotalPurchasesPaid(value: boolean): void;
};

const GeneralContext = createContext<GeneralContextData>(
  {} as GeneralContextData
);

export const GeneralContextProvider: React.FC = ({ children }) => {
  const [isOpenModalTotalPurchases, setIsOpenModalTotalPurchases] =
    useState(false);
  const [isOpenModalGeneralTransfer, setIsOpenModalGeneralTransfer] =
    useState(false);
  const [isOpenModalPendingBalance, setIsOpenModalPendingBalance] =
    useState(false);
  const [isOpenModalGeneralChargeback, setIsOpenModalGeneralChargeback] =
    useState(false);
  const [
    isOpenModalGeneralBalanceReceivable,
    setIsOpenModalGeneralBalanceReceivable,
  ] = useState(false);

  const [isOpenDrawerTotalPurchases, setIsOpenDrawerTotalPurchases] =
    useState(false);
  const [isOpenDrawerGeneralTransfer, setIsOpenDrawerGeneralTransfer] =
    useState(false);
  const [isOpenDrawerPendingBalance, setIsOpenDrawerPendingBalance] =
    useState(false);
  const [isOpenDrawerGeneralChargeback, setIsOpenDrawerGeneralChargeback] =
    useState(false);
  const [
    isOpenDrawerGeneralBalanceReceivable,
    setIsOpenDrawerGeneralBalanceReceivable,
  ] = useState(false);
  const [
    isOpenModalGeneralBalanceAvailable,
    setIsOpenModalGeneralBalanceAvailable,
  ] = useState(false);
  const [
    isOpenDrawerGeneralBalanceAvailable,
    setIsOpenDrawerGeneralBalanceAvailable,
  ] = useState(false);

  const [isOpenModalTotalPurchasesPaid, setIsOpenModalTotalPurchasesPaid] =
    useState(false);
  const [isOpenDrawerTotalPurchasesPaid, setIsOpenDrawerTotalPurchasesPaid] =
    useState(false);

  return (
    <GeneralContext.Provider
      value={{
        isOpenModalTotalPurchases,
        setIsOpenModalTotalPurchases,
        isOpenModalGeneralTransfer,
        setIsOpenModalGeneralTransfer,
        isOpenDrawerTotalPurchases,
        setIsOpenDrawerTotalPurchases,
        isOpenDrawerGeneralTransfer,
        setIsOpenDrawerGeneralTransfer,
        isOpenModalPendingBalance,
        setIsOpenModalPendingBalance,
        isOpenDrawerPendingBalance,
        setIsOpenDrawerPendingBalance,
        isOpenModalGeneralChargeback,
        setIsOpenModalGeneralChargeback,
        isOpenDrawerGeneralChargeback,
        setIsOpenDrawerGeneralChargeback,
        isOpenModalGeneralBalanceReceivable,
        setIsOpenModalGeneralBalanceReceivable,
        isOpenDrawerGeneralBalanceReceivable,
        setIsOpenDrawerGeneralBalanceReceivable,
        isOpenModalGeneralBalanceAvailable,
        setIsOpenModalGeneralBalanceAvailable,
        isOpenDrawerGeneralBalanceAvailable,
        setIsOpenDrawerGeneralBalanceAvailable,
        isOpenModalTotalPurchasesPaid,
        setIsOpenModalTotalPurchasesPaid,
        isOpenDrawerTotalPurchasesPaid,
        setIsOpenDrawerTotalPurchasesPaid,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export function useGeneralContext(): GeneralContextData {
  const context = useContext(GeneralContext);

  if (!context) {
    throw new Error(`useGeneralContext it depends GeneralContextProvider`);
  }

  return context;
}
