import { Modal } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerTicketsMobile = styled.div`
  display: flex;
  flex-direction: column;

  div.accordion + div.accordion {
    margin-top: 13px;
  }

  div.header-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 16px;
    margin-bottom: 26px;

    h1 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      margin-top: 0px;
      margin-bottom: 0px;
      text-transform: uppercase;
      color: ${Colors.gray20};
      opacity: 0.75;
    }

    div.box {
      width: 25.73px;
      height: 25.73px;
      border-radius: 50%;
      background: ${Colors.white20};

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }
`;

export const Center = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface CardAccordionProps {
  active: boolean;
}

export const CardAccordionMobile = styled.div<CardAccordionProps>`
  cursor: pointer;
  width: 100%;

  min-height: 49px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  z-index: 8;
  position: relative;

  background: ${(props) => (props.active ? Colors.black5 : Colors.white)};

  border: 1px solid ${Colors.black10};
  box-shadow: 0px 4px 20px rgba(18, 25, 34, 0.05);
  border-radius: 5px;

  padding: 7px 21px 10px 19px;

  .svg-chevron {
    min-width: 12px;
    min-height: 12px;
  }

  div.content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    div.image {
      margin-top: 4.5px;
    }

    div.texts {
      min-height: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 23px;

      div.first {
        h2.text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 0px;

          color: ${Colors.black100};

          max-width: 180px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      span {
        font-family: "Cairo";
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        margin-bottom: 0px;

        color: ${Colors.black50};
        margin-top: 2px;
      }
    }
  }
`;

export const DataDropDownMobile = styled.div`
  display: block;
  position: relative;

  width: 100%;
  z-index: 1;
  margin-top: -35px;
  margin-bottom: 14px;

  height: 238px;

  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  border-radius: 5px;

  padding-left: 16px;
  padding-right: 16px;

  div.content-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 42px;

    padding-bottom: 25px;

    border-bottom: 1px solid ${Colors.secondary10};

    div.container-image {
      img {
        border-radius: 6px;
        object-fit: cover;
      }
    }

    div.texts-ticket {
      display: flex;
      flex-direction: column;

      div + div {
        margin-top: 13px;
      }

      div.data {
        display: flex;
        flex-direction: column;

        h2.text-data {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;

          margin-bottom: 0px;
          color: ${Colors.black100};
        }

        span {
          font-family: "Poppins";
          font-weight: normal;
          font-size: 10px;
          line-height: 15px;
          margin-top: 2px;
          color: ${Colors.black50};
        }
      }
    }
  }
`;

export const ContainerButtonMobile = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const ButtonVisualizationTicketMobile = styled.div`
  cursor: pointer;
  width: 100%;
  height: 32px;

  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${Colors.primary100};
  box-shadow: 0px 3px 20px rgba(212, 0, 80, 0.1);
  border-radius: 8px;

  span {
    font-family: "Cairo";
    font-weight: 900;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.white};
  }
`;

export const ContainerModalTicketDetail = styled.div`
  width: 100%;
  height: 105%;
  margin-top: 16px;

  @media (max-width: 800px) {
    border-radius: 10px;
  }
`;

export const BackgroundTicket = styled.div`
  width: 100%;
  height: 338px;

  position: relative;

  position: relative;
  overflow: hidden;

  border-radius: 24px 24px 0px 0px;
  padding: bottom;
  display: flex;
  flex-direction: column;

  justify-content: flex-end;

  @media (max-width: 800px) {
    height: 134px;

    border-radius: 10px 10px 0px 0px;
  }

  img {
    filter: brightness(25%);
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  div.content {
    height: 100%;
    width: 100%;
    z-index: 2;
    position: relative;
    padding-left: 60px;
    padding-bottom: 10px;

    @media (max-width: 800px) {
      padding-left: 12px;
      padding-bottom: 0px;
    }

    div.text {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;

      svg {
        margin-right: 10px;
      }
      @media (max-width: 800px) {
        svg {
          max-height: 15px;
          max-width: 15px;
        }
      }
    }

    h3 {
      font-family: "Cairo";
      font-weight: bold;
      font-size: 35px;
      line-height: 160%;

      color: ${Colors.white};

      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

      @media (max-width: 800px) {
        font-size: 16px;
        line-height: 30px;
      }
    }

    h4 {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 21px;
      line-height: 160%;

      color: ${Colors.white};
      margin-bottom: 0px;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);

      @media (max-width: 800px) {
        font-size: 10px;
        line-height: 14px;
        width: 90%;
      }
    }

    p {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 21px;
      line-height: 160%;

      color: ${Colors.white};
      margin-bottom: 0px;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);

      @media (max-width: 800px) {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;

export const ContainerContentModalTicket = styled.div`
  width: 100%;
  padding: 50px 60px 0px 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border: 1px solid ${Colors.border20};

  border-radius: 0px 0px 10px 10px;

  @media (max-width: 800px) {
    padding: 25px 0px 0px 15px;
  }

  div.data {
    h3.data {
      font-family: "Cairo";
      font-weight: 900;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.black100};
      margin-bottom: 20px;

      @media (max-width: 800px) {
        font-size: 14px;
        line-height: 25px;
        margin-bottom: 10px;
      }
    }

    div.table {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      @media (max-width: 800px) {
        margin-top: 0px;
      }

      h5 {
        font-family: "Cairo";
        font-weight: 700;
        font-size: 26px;
        line-height: 160%;

        color: ${Colors.black100};
        margin-bottom: 0px;

        @media (max-width: 800px) {
          font-weight: 600;
          font-size: 14px;
          line-height: 26px;
        }
      }

      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 24px;
        line-height: 160%;

        color: ${Colors.black50};

        @media (max-width: 800px) {
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  div.qr {
    width: 320px;
    height: 391px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 26px 26px 0px 26px;

    @media (max-width: 800px) {
      display: none;
    }

    div.center {
      width: 268px;
      height: 268px;
    }

    h1 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      letter-spacing: 0.1em;

      color: ${Colors.black100};
      margin-top: 20px;
    }
  }

  div.qr-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 16px;

    button {
      border: 0;
      margin-top: 12px;
      width: 84px;
      height: 31px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      background: ${Colors.primary10};
      border-radius: 6px;

      font-weight: 600;
      font-size: 10px;
      line-height: 19px;

      color: ${Colors.primary100};
    }

    @media (min-width: 800px) {
      display: none;
    }
  }
`;

export const DivLogo = styled.div`
  width: 100%;

  margin-top: 40px;

  height: 180px;

  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 30px;

  @media (max-width: 800px) {
    display: none;
  }

  div.all-content {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    border-top: 1px solid ${Colors.secondary10};
  }

  span {
    color: ${Colors.gray50};
    letter-spacing: 10px;
  }
`;

export const DivBorderMobile = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  margin-top: 15px;

  @media (min-width: 800px) {
    display: none;
  }

  div.item {
    border-top: 1px solid ${Colors.secondary10};
    padding-top: 25px;

    h3 {
      font-family: "Cairo";
      font-weight: 700;
      font-size: 14px;
      line-height: 25px;

      color: ${Colors.black100};
    }

    div.border {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      width: 100%;
      height: 38px;
      padding-left: 19px;
      padding-right: 20px;

      background: #ffffff;
      border: 1px solid #f0f0f0;
      border-radius: 8px;
      margin-top: 10px;

      div.row {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          font-family: "Poppins";
          margin-bottom: 0px;
          margin-left: 12px;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;

          color: ${Colors.primary100};
        }
      }
    }
  }
`;

export const ContainerButtonModalTicket = styled.div`
  margin-top: 60px;
  margin-bottom: 35px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
`;

export const ButtonChangeOwnership = styled.button`
  border: 0;

  width: 100%;
  height: 38px;
  background: ${Colors.primary100};
  box-shadow: 0px 5px 30px rgba(212, 0, 80, 0.25);
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: ${Colors.white};
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border: 5px solid ${Colors.border20};

    box-shadow: 4px 8px 30px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  h5.code {
    text-align: center;
    margin-top: 23px;
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;

    color: #000000;
  }

  @media (max-width: 800px) {
    padding: 0px;
  }
`;

export const ContainerQrCodeMobile = styled.div`
  margin-top: 29px;
  width: 100%;
  left: 44px;
  top: 138px;
  display: flex;
  justify-content: center;

  background: ${Colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  border: 0;
  margin-top: 12px;
  width: 84px;
  height: 31px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: ${Colors.primary10};
  border-radius: 6px;

  font-weight: 600;
  font-size: 10px;
  line-height: 19px;

  color: ${Colors.primary100};
`;
