import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Container,
  FormContainer,
  Header,
  Button,
  FieldWrapper,
  FormInputWrapper,
  Footer,
  FormWrapper,
  UploadHeaderContainer,
} from "./styles";
import UploadFile from "../UploadFile";
import { ModalFilterByEvent } from "components/ModalFilterByEvent";
import { useCallback, useEffect, useState } from "react";
import { EventOrganizer, TicketEvent } from "types";
import { ModalFilterByTicket } from "components/ModalFilterByTicket";
import { Divider, Spin, UploadProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Colors } from "constants/colors";
import { errorNotification } from "components/Notification";
import { doImportPurchases } from "services/purchases";
import axios from "axios";
import { SelectEventFilterV2 } from "components/FiltersSelect/SelectEventFilterV2";
import { SelectTicketFilterV2 } from "components/FiltersSelect/SelectTicketFilterV2";

interface ImportPurchasesModalProps {
  onSuccessUpload: () => void;
  onClose: () => void;
}

interface FormProps {
  eventId: string;
  ticketId: string;
  file: any;
}

const formSchema = yup.object().shape({
  eventId: yup.string().required("Selecione um evento"),
  ticketId: yup.string().required("Selecione um ingresso"),
  file: yup.mixed().required("Selecione um arquivo"),
});

const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: Colors.white }} spin />
);

function ImportPurchasesModal({ onSuccessUpload, onClose }: ImportPurchasesModalProps) {
  const [selectedEvents, setSelectedEvents] = useState<Array<EventOrganizer>>(
    []
  );
  const [selectedTickets, setSelectedTickets] = useState<Array<TicketEvent>>(
    []
  );
  const [fetching, setFetching] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
  } = useForm<FormProps>({
    resolver: yupResolver(formSchema),
  });

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    action: "",
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        setValue("file", info.file);
      }
    },
    accept: ".csv",
    customRequest: (options) => {
      options?.onSuccess!("Ok");
    },
    maxCount: 1,
  };

  const handleClearEvents = () => {
    setSelectedEvents([]);
    resetField("eventId");
  };

  const handleClearTickets = () => {
    setSelectedTickets([]);
    resetField("ticketId");
  };

  const onSubmit = useCallback(
    async (values: FormProps) => {
      setFetching(true);

      try {
        const { eventId, ticketId, file } = values;

        if (!eventId || !ticketId || !file) return;

        const formData = new FormData();

        formData.append("ticket_id", ticketId);
        formData.append("archive", file?.originFileObj || file);

        await doImportPurchases(formData);

        resetField("eventId");
        resetField("ticketId");
        resetField("file");

        onSuccessUpload();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          errorNotification(
            "Erro ao importar as compras",
            error?.response?.data?.message
          );

          return;
        }

        errorNotification(
          "Erro ao importar as compras",
          "Tente novamente mais tarde"
        );
      } finally {
        setFetching(false);
      }
    },
    [resetField, onSuccessUpload]
  );

  useEffect(() => {
    if (selectedEvents.length > 0) {
      setValue("eventId", selectedEvents[0]?._id);
    }
  }, [selectedEvents, setValue]);

  useEffect(() => {
    if (selectedTickets.length > 0 && !!selectedTickets[0]?._id) {
      setValue("ticketId", String(selectedTickets[0]?._id));
    }
  }, [selectedTickets, setValue]);

  return (
    <>
      <Container>
        <Header>
          <h1>Importar planilha de vendas</h1>
        </Header>


        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <span>Selecione o evento e o tipo de ingresso</span>

            <FormInputWrapper>
              <FieldWrapper>
                <SelectEventFilterV2
                  title={
                    selectedEvents.length <= 0
                      ? "Selecione um evento"
                      : selectedEvents[0]?.name
                  }
                  label="Evento"
                />

                {errors.eventId?.message && (
                  <p className="error">{errors.eventId?.message}</p>
                )}
              </FieldWrapper>

              <FieldWrapper>
                <SelectTicketFilterV2
                  title={
                    selectedTickets.length <= 0
                      ? "Selecione um ingresso"
                      : selectedTickets[0]?.name
                  }
                  label="Ticket"
                  sortKey="events"
                />

                {errors.ticketId?.message && (
                  <p className="error">{errors.ticketId?.message}</p>
                )}
              </FieldWrapper>
            </FormInputWrapper>

            <Divider />

            <FieldWrapper>
              <UploadHeaderContainer>
                <h3>Upload da planilha</h3>

                <span>
                  <b>Especificações:</b>&nbsp;arquivo do tipo <b>CSV</b>,
                  com tamanho máximo recomendado de <b>4MB</b>.
                </span>
              </UploadHeaderContainer>

              <UploadFile label="Planilha de vendas" {...uploadProps} />

              {errors.file?.message && (
                <p className="error">{errors.file?.message}</p>
              )}
            </FieldWrapper>

          </FormWrapper>

          <Footer>
            <Button
              disabled={fetching}
              type="button"
              onClick={onClose}
              className="secondary"
            >
              Cancelar
            </Button>

            <Button
              disabled={fetching}
              type="submit"
            >
              {fetching && <Spin spinning indicator={antIcon} />}
              Concluir importação
            </Button>
          </Footer>
        </FormContainer>
      </Container>

      <ModalFilterByEvent
        onSelectEvents={setSelectedEvents}
        onClearEvents={handleClearEvents}
        isMultiple={false}
      />

      <ModalFilterByTicket
        isMultiple={false}
        onSelectTickets={setSelectedTickets}
        onClearTickets={handleClearTickets}
      />
    </>
  );
}

export default ImportPurchasesModal;
