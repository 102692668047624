import { useCallback, useEffect, useState } from "react";

import { BiPlus } from "react-icons/bi";
import { RiCalendarCheckFill } from "react-icons/ri";

import { Radio, RadioChangeEvent, Space } from "antd";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { useCreateEvent } from "contexts/CreateEventContext";

import { useDisclosure } from "hooks/useDisclosure";

import {
  Category as CategoryItem,
  CreateDescriptionEventFormData,
} from "types";

import { useCategories } from "services/Hook/Event/Category";

import { Colors } from "constants/colors";

import {
  ButtonAddLink,
  ButtonClose,
  ButtonOk,
  Card,
  CardContentModal,
  CategoryPill,
  Container,
  ContainerAge,
  ContainerButtonsModal,
  ContainerCategories,
  ContainerUseTerms,
  Divider,
  LabelTitle,
  SubLabelTitle,
  SubTitle,
  Title,
  TitleModal,
} from "./styles";

import { ButtonNext, ButtonPrev, ContainerButtons } from "../../styles";

import { InputForm } from "components/Form/InputForm";
import { ModalComponent } from "components/ModalComponent";
import { TextAreaForm } from "components/Form/TextAreaForm";

const createDescriptionEventFormSchema = yup.object().shape({
  description: yup.string().required("Descrição obrigatória"),
  privacity: yup.number().required("Privacidade obrigatória"),
  age: yup.string().required("Classificação etária obrigatória"),
  require_terms: yup.boolean().required("Requerimento obrigatório"),
  use_terms: yup
    .string()
    .notRequired()
    .nullable()
    .when("require_terms", {
      is: true,
      then: yup.string().required("Termos obrigatórios"),
    }),
});

interface CategoriesInterface {
  results: CategoryItem[];
}

export function Description() {
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryItem[] | []
  >([]);

  const { data } = useCategories<CategoriesInterface>();

  const { showModal, handleCancel, handleOk, isModalVisible } = useDisclosure();

  const { event } = useCreateEvent();

  const {
    register,
    reset,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateDescriptionEventFormData>({
    resolver: yupResolver(createDescriptionEventFormSchema),
  });

  useEffect(() => {
    reset({
      description: event.description?.description,
      age: event.description?.age,
      categories: event.description?.categories ?? [],
      privacity: event.description?.privacity,
      require_terms: event.description?.require_terms,
      use_terms: event.description?.use_terms,
    });

    setSelectedCategories(event.description?.categories ?? []);
  }, [event.description, reset]);

  const handleAddDescription: SubmitHandler<
    CreateDescriptionEventFormData
  > = async ({ age, description, privacity, use_terms, require_terms }) => {
    event.createDescription({
      age,
      categories: selectedCategories,
      description,
      privacity,
      require_terms,
      use_terms,
    });

    event.goToNextStep();
  };

  const handleSelectedCategory = useCallback(
    (category: CategoryItem) => {
      const categoryHasSelected = selectedCategories.some(
        (item) => item._id === category._id
      );

      if (categoryHasSelected) {
        const filterCategories = selectedCategories.filter(
          (item) => item._id !== category._id
        );

        setSelectedCategories(filterCategories);

        return;
      }

      if (selectedCategories.length >= 2) {
        alert(
          "Limite de categorias atingido, Caso queira mudar desmarque uma categoria selecionada"
        );

        return;
      }

      setSelectedCategories([...selectedCategories, category]);
    },
    [selectedCategories]
  );

  const require_terms = watch("require_terms");

  return (
    <>
      <Container onSubmit={handleSubmit(handleAddDescription)}>
        <Card>
          <Title>4. Descrição e definições gerais do evento</Title>
          <SubTitle>
            Descreva os detalhes do seu evento, defina a categoria em que ele se
            encaixa e qual será sua visibilidade.
          </SubTitle>

          <Divider />

          <LabelTitle>Adicione uma descrição para o seu evento</LabelTitle>

          <TextAreaForm
            {...register("description")}
            error={errors.description}
            placeholder="Insira aqui a descrição do seu evento..."
            isFull
          />

          <Divider />

          <LabelTitle>Qual a categoria do seu evento?</LabelTitle>

          <SubLabelTitle>Escolha até duas categorias.</SubLabelTitle>

          <ContainerCategories>
            {data?.results?.map((category) => (
              <CategoryPill
                active={selectedCategories.some(
                  (item) => item._id === category._id
                )}
                haveCursorPointer
                onClick={() => handleSelectedCategory(category)}
              >
                <h6>{category?.name}</h6>
              </CategoryPill>
            ))}
          </ContainerCategories>

          <Divider />

          <LabelTitle>Visibilidade do evento</LabelTitle>

          <ContainerAge>
            <Controller
              control={control}
              name="privacity"
              defaultValue={1}
              render={({ field: { onChange, value } }) => (
                <Radio.Group
                  onChange={(e: RadioChangeEvent) => {
                    onChange(e.target.value);
                  }}
                  value={value}
                >
                  <Space direction="horizontal">
                    <Radio value={1}>Público</Radio>
                    <Radio value={2}>Privado</Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          </ContainerAge>

          <Divider />

          <LabelTitle>Classificação etária</LabelTitle>

          <ContainerAge>
            <Controller
              control={control}
              name="age"
              defaultValue="1"
              render={({ field: { onChange, value } }) => (
                <Radio.Group
                  onChange={(e: RadioChangeEvent) => {
                    onChange(e.target.value);
                  }}
                  value={value}
                >
                  <Space direction="vertical">
                    <Radio value="0">Livre para todas as idades</Radio>
                    <Radio value="1">10 anos ou mais</Radio>
                    <Radio value="2">12 anos ou mais</Radio>
                    <Radio value="3">14 anos ou mais</Radio>
                    <Radio value="4">16 anos ou mais</Radio>
                    <Radio value="5">18 anos ou mais</Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          </ContainerAge>

          <Divider />

          <LabelTitle>Links de redirecionamento</LabelTitle>

          <SubLabelTitle>
            Adicione links de redirecionamento, pode ser o link do site do seu
            evento ou de alguma informação que você deseja que seus usuários
            saibam, entre outros.
          </SubLabelTitle>

          <ButtonAddLink type="button" onClick={showModal}>
            <BiPlus size={18} color={Colors.primary100} />

            <span>Adicionar link</span>
          </ButtonAddLink>

          <Divider />

          <ContainerUseTerms>
            <LabelTitle>Termos de uso do evento</LabelTitle>

            <Controller
              control={control}
              name="require_terms"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <Radio.Group
                  value={value}
                  onChange={(e: RadioChangeEvent) => {
                    onChange(e.target.value);
                  }}
                >
                  <Space direction="horizontal">
                    <Radio value={true}>Exigir aceite dos termos</Radio>
                    <Radio value={false}>Não exigir aceite dos termos</Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          </ContainerUseTerms>

          {require_terms ? (
            <div>
              <LabelTitle>Adicione os termos do seu evento</LabelTitle>

              <TextAreaForm
                {...register("use_terms")}
                error={errors.use_terms}
                placeholder="Insira aqui os termos do seu evento..."
                isFull
              />
            </div>
          ) : null}
        </Card>

        <ContainerButtons>
          <ButtonPrev active type="button" onClick={event.goToBackStep}>
            <span>Voltar</span>
          </ButtonPrev>

          <ButtonNext type="submit">
            <p>Prosseguir</p>
          </ButtonNext>
        </ContainerButtons>
      </Container>

      <ModalComponent
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        title="Link de redirecionamento"
      >
        <>
          <CardContentModal>
            <TitleModal>Informações do link</TitleModal>

            <Divider />

            <InputForm
              name="name"
              isFull
              icon={
                <RiCalendarCheckFill size={17} color={Colors.secondary40} />
              }
              label="Nome do link"
            />

            <InputForm
              name="name"
              isFull
              icon={
                <RiCalendarCheckFill size={17} color={Colors.secondary40} />
              }
              label="URL"
            />
          </CardContentModal>

          <ContainerButtonsModal>
            <ButtonClose onClick={handleCancel}>
              <span>Cancelar</span>
            </ButtonClose>

            <ButtonOk onClick={handleOk}>
              <span>Salvar link</span>
            </ButtonOk>
          </ContainerButtonsModal>
        </>
      </ModalComponent>
    </>
  );
}
