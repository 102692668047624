import { RiArrowLeftLine, RiCloseCircleLine } from "react-icons/ri";
import { FiChevronRight } from "react-icons/fi";

import { Colors } from "constants/colors";

import {
  ButtonLeft,
  Card,
  ContainerHeader,
  ContainerModal,
  ContainerTable,
  Divider,
  GridItems,
  Item,
  ModalStyled,
  SubTitle,
  TextTotal,
  Title,
  TitleLast,
  TitleTotal,
} from "./styles";

import { Pagination } from "components/Pagination";

import { useState } from "react";
import { useGeneralContext } from "contexts/GeneralContext";
import { useSearchFinancialTransaction } from "hooks/useSearchFinancialTransaction";
import { BadgePurchaseByStatus } from "components/BadgePurchaseByStatus";
import { formatPrice } from "utils/formatPrice";
import { Link } from "react-router-dom";
import { Center } from "pages/MyEvent/styles";
import { Loading } from "components/Loading";
import { EmptySearchComponent } from "components/EmptySearchComponent";

interface ModalPendingBalanceProps {
  total: number;
  fee: number;
}

export function ModalPendingBalance({ total, fee }: ModalPendingBalanceProps) {
  const [selectedPurchase, setSelectedPurchase] = useState<
    "resume" | "detail" | null
  >(null);

  const { data, page, onChange } = useSearchFinancialTransaction({
    statusFinancial: "PENDING",
  });

  const { isOpenModalPendingBalance, setIsOpenModalPendingBalance } =
    useGeneralContext();

  const closeSelectedPurchase = () => {
    setSelectedPurchase(null);
  };

  const closeModal = () => {
    setIsOpenModalPendingBalance(false);
  };

  const title: { [key: string]: any } = {
    resume: "Resumo das vendas",
    detail: "Detalhes da venda",
    default: "Saldo pendente",
  };

  return (
    <ModalStyled
      width={1200}
      visible={isOpenModalPendingBalance}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <ContainerHeader>
          {selectedPurchase ? (
            <ButtonLeft onClick={closeSelectedPurchase}>
              <RiArrowLeftLine color={Colors.primary100} size={22} />
            </ButtonLeft>
          ) : null}

          <h1>{title[selectedPurchase ?? "default"]}</h1>
        </ContainerHeader>

        {selectedPurchase === null ? (
          <Card>
            <Title>Vendas em processamento</Title>

            <GridItems>
              <Link
                to="/financial?page=1&tab_name=transactions&data_type=choose&payment_status=PENDING&type=eventx-checkout"
                reloadDocument
              >
                <Item>
                  <div className="column-content">
                    <TitleTotal>SALDO PENDENTE</TitleTotal>

                    <TextTotal>{formatPrice(total ?? 0)}</TextTotal>

                    <SubTitle>Aguardando processo de pagamento</SubTitle>
                  </div>

                  <FiChevronRight color={Colors.gray45} size={24} />
                </Item>
              </Link>
            </GridItems>

            <Divider />

            {!data ? (
              <Center>
                <Loading />
              </Center>
            ) : null}

            {data && data?.results?.length === 0 ? (
              <EmptySearchComponent />
            ) : null}

            {data && data?.results?.length > 0 ? (
              <>
                <TitleLast>Últimas vendas</TitleLast>

                <ContainerTable>
                  <table>
                    <thead>
                      <tr>
                        <th>EVENTO</th>
                        <th>STATUS</th>
                        <th>VALOR</th>
                        <th>DATA</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.results?.map((purchase) => (
                        <Link
                          style={{ width: "100%" }}
                          to={`/financial/${purchase?._id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="table-link"
                        >
                          <tr
                            style={{ cursor: "pointer", width: "100%" }}
                            onClick={() => {}}
                          >
                            <td>
                              <h6>{purchase?.event_id?.name}</h6>
                            </td>

                            <td>
                              <BadgePurchaseByStatus
                                status={purchase?.payment_status}
                              />
                            </td>

                            <td>
                              <h6>
                                {formatPrice(
                                  purchase?.total ? purchase?.total / 100 : 0
                                )}
                              </h6>
                            </td>

                            <td>
                              <h6>{purchase?.formattedDate}</h6>
                            </td>

                            <td>
                              <FiChevronRight color={Colors.gray45} size={24} />
                            </td>
                          </tr>
                        </Link>
                      ))}
                    </tbody>
                  </table>
                </ContainerTable>

                <div className="pagination">
                  <Pagination
                    title="registros"
                    totalCountOfRegisters={data?.count}
                    currentPage={Number(page)}
                    onPageChange={onChange}
                    registersPerPage={10}
                  />
                </div>
              </>
            ) : null}
          </Card>
        ) : null}
      </ContainerModal>
    </ModalStyled>
  );
}
