import { useSearchParams } from "react-router-dom";
import { useDisclosure } from "./useDisclosure";
import { ReportPurchase } from "services/Hook/Financial";
import { useGetDateAgo } from "./useGetDateAgo";

export function useGenerateFinancialReport() {
  const [searchParams] = useSearchParams();

  const { handleOk, handleCancel, showModal, isModalVisible } = useDisclosure();

  const { daysFilter } = useGetDateAgo();

  const organizers = searchParams.get("organizer") ?? "";
  const events = searchParams.get("events") ?? "";
  const tickets = searchParams.get("tickets") ?? "";
  const data_gte = searchParams.get("data_gte") ?? "";
  const data_lte = searchParams.get("data_lte") ?? "";
  const data_type = searchParams.get("data_type") ?? "";
  const payment_type = searchParams.get("payment_type") ?? "";
  const payment_status = searchParams.get("payment_status") ?? "";
  const type = searchParams.get("type") ?? "";

  async function handleGenerateReport() {
    try {
      await ReportPurchase({
        paramsObject: {
          ...(organizers && { organizers: [organizers] }),
          ...(events && { events: events.split("%") }),
          ...(tickets && { event_tickets: tickets.split("%") }),
          ...(data_gte && { date_end: data_lte }),
          ...(data_lte && { date_start: data_gte }),
          ...(data_type &&
            data_type !== "choose" && {
              date_end:
                daysFilter.find((day) => String(day.id) === data_type)
                  ?.date_end ?? daysFilter[0].date_end,
              date_start:
                daysFilter.find((day) => String(day.id) === data_type)
                  ?.date_start ?? daysFilter[0].date_start,
            }),
          ...(payment_type && { payment_type }),
          ...(payment_status && { payment_status }),
          ...(type && { type }),
        },
      });

      showModal();
    } catch (err) {}
  }

  return {
    handleGenerateReport,
    handleOk,
    handleCancel,
    showModal,
    isModalVisible,
  };
}
