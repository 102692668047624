import { useState } from "react";

import { RiErrorWarningLine, RiInformationLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  CardReport,
  Column,
  ColumnContentReport,
  ColumnContentTotal,
  Container,
  ContainerItems,
  ContainerOverlay,
  ContainerTax,
  Divider,
  Hr,
  Row,
  RowItemTotal,
  Title,
  TitlePrice,
  TitlePriceSmall,
  TitleTax,
  TitleTotal,
  TitleTotalSmall,
} from "./styles";

import { DrawerOverlay } from "components/DrawerOverlay";

import { useGeneralContext } from "contexts/GeneralContext";
import { BiChevronDown, BiChevronLeft } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import { formatPrice } from "utils/formatPrice";
import { useSearchFinancialTransaction } from "hooks/useSearchFinancialTransaction";
import {
  ColumnContent,
  ColumnContentFlexEnd,
  ColumnLineIcon,
  ContainerItemsReceipts,
  ContainerLastReceipts,
  ContentFlex,
  ItemReceipt,
  ListReceipts,
  LoadMoreContainer,
  RowContent,
  TitleContentItem,
  TitleDataSmall,
  TitleItemReceipt,
} from "components/DrawerTotalPurchases/styles";
import { Transaction } from "types";
import { Link } from "react-router-dom";
import { Icon, Text } from "components/BadgePurchaseByStatus";
import LoadMoreItems from "components/LoadMoreItems";

interface DrawerGeneralTransferProps {
  total: number;
  fee: number;
}

export function DrawerGeneralTransfer({
  total,
  fee,
}: DrawerGeneralTransferProps) {
  const [selectedPurchase, setSelectedPurchase] = useState(false);

  const {
    data,
    handleLoadMorePurchasingsMobile,
    hasMoreItems,
    loadingMoreItems,
  } = useSearchFinancialTransaction({
    statusFinancial: "PAID",
  });

  const { isOpenDrawerGeneralTransfer, setIsOpenDrawerGeneralTransfer } =
    useGeneralContext();

  return (
    <DrawerOverlay
      title={
        !!selectedPurchase ? "Repasses da EventX" : "Detalhes dos repasses"
      }
      icon={
        !selectedPurchase ? (
          <BiChevronDown
            color={Colors.white}
            size={24}
            onClick={() => setIsOpenDrawerGeneralTransfer(false)}
          />
        ) : (
          <BiChevronLeft
            color={Colors.white}
            size={24}
            onClick={() => setSelectedPurchase(false)}
          />
        )
      }
      isVisible={isOpenDrawerGeneralTransfer}
      handleOk={() => setIsOpenDrawerGeneralTransfer(false)}
    >
      {!selectedPurchase ? (
        <Container>
          <ContainerItems>
            <Title>Taxas da EventX</Title>

            <Divider />

            <Link
              to="/financial?page=1&tab_name=transactions&data_type=choose&payment_status=PAID&type=eventx-checkout"
              reloadDocument
            >
              <Row>
                <Column>
                  <TitleTotal>total de repasses</TitleTotal>

                  <TitlePrice>{formatPrice(fee ?? 0)}</TitlePrice>
                </Column>

                <FiChevronRight color={Colors.gray45} size={24} />
              </Row>
            </Link>

            <ContainerTax>
              <RiInformationLine color={Colors.black50} size={12} />

              <TitleTax>Vendas online dos ingressos</TitleTax>
            </ContainerTax>

            <ContainerOverlay>
              <Link
                to="/financial?page=1&tab_name=transactions&data_type=choose&payment_status=PAID&type=eventx-checkout"
                reloadDocument
              >
                <RowItemTotal>
                  <ColumnContentTotal>
                    <TitleTotalSmall>vendas totais</TitleTotalSmall>

                    <TitlePriceSmall>{formatPrice(total ?? 0)}</TitlePriceSmall>
                  </ColumnContentTotal>

                  <FiChevronRight color={Colors.gray45} size={24} />
                </RowItemTotal>
              </Link>
            </ContainerOverlay>
          </ContainerItems>

          <ContainerLastReceipts>
            <ContainerItemsReceipts>
              <TitleTotal>TAXAS</TitleTotal>

              <ListReceipts>
                {data?.results?.map((purchase: Transaction) => (
                  <Link
                    style={{ width: "100%" }}
                    to={`/financial/${purchase?._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="table-link"
                  >
                    <ItemReceipt>
                      <ColumnLineIcon>
                        <Icon status={purchase?.payment_status} />

                        <Hr />
                      </ColumnLineIcon>

                      <RowContent>
                        <ColumnContent>
                          <TitleItemReceipt>
                            {<Text status={purchase?.payment_status} />}
                          </TitleItemReceipt>

                          <TitleContentItem>
                            {purchase?.event_id?.name}
                          </TitleContentItem>
                        </ColumnContent>

                        <ColumnContentFlexEnd>
                          <TitleDataSmall>
                            {purchase?.formattedDate}
                          </TitleDataSmall>

                          <ContentFlex>
                            <TitleContentItem>
                              {formatPrice(
                                purchase?.total_fee
                                  ? purchase?.total_fee / 100
                                  : 0
                              )}
                            </TitleContentItem>

                            <FiChevronRight color={Colors.gray45} size={14} />
                          </ContentFlex>
                        </ColumnContentFlexEnd>
                      </RowContent>
                    </ItemReceipt>
                  </Link>
                ))}
              </ListReceipts>
            </ContainerItemsReceipts>
          </ContainerLastReceipts>

          <LoadMoreContainer>
            <LoadMoreItems
              handleLoadMore={handleLoadMorePurchasingsMobile}
              fetching={loadingMoreItems}
              hasMoreItems={hasMoreItems}
              length={data?.results?.length}
              total={data?.count}
            />
          </LoadMoreContainer>
        </Container>
      ) : null}

      {selectedPurchase ? (
        <Container>
          <ContainerItems>
            <CardReport>
              <Column>
                <ColumnContentReport>
                  <RiErrorWarningLine color={Colors.primary100} size={20} />

                  <h3>Reportar</h3>
                </ColumnContentReport>
              </Column>
            </CardReport>
          </ContainerItems>
        </Container>
      ) : null}
    </DrawerOverlay>
  );
}
