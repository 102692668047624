import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  padding: 16px 24px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;

  > h1 {
    font-family: "Poppins";
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: ${Colors.secondary100};
    text-align: center;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormWrapper = styled.div`
  border: 1px solid #E7E7EA;
  padding: 50px;
  border-radius: 20px;

  > span {
    color: ${Colors.secondary100};
    font-family: "Poppins";
    font-size: 22px;
    line-height: 35.2px;
    font-weight: 500;
    text-align: left;
  }
`;

export const FormInputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 16px;

  margin-top: 20px;

  width: 100%;
`

export const ButtonBase = css`
  cursor: pointer;

  max-width: 390px;
  width: 100%;
  padding: 10px 0;

  border-radius: 14px;

  font-family: "Cairo";
  font-size: 1.25rem;
  line-height: 2.6rem;
  font-weight: 700;
  border: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 720px) {
    height: 40px;
    padding: 0;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.2;
  }
`;

export const Button = styled.button`
  ${ButtonBase}

  margin-top: 24px;
  column-gap: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${Colors.white};
  box-shadow: 0px 15px 30px 0px #d4005026;
  background-color: ${Colors.primary100};

  &.secondary {
    background-color: ${Colors.primary10};
    border: none;
    color: ${Colors.primary100};
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;

  .error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 24px;
`;

export const UploadHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  > h3 {
    font-family: "Poppins";
    font-size: 22px;
    line-height: 35.2px;
    color: ${Colors.secondary100};
  }

  > span {
    font-family: "Open Sans";
    font-size: 16px;
    font-style: italic;
    font-size: 16px;
    line-height: 25.6px;
    color: ${Colors.secondary100};

    margin-bottom: 32px;
  }
`;
