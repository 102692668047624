import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;

  margin-top: 9.14rem;

  width: 100%;

  @media (max-width: 1024px) {
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }

  h1 {
    font-weight: bold;
    font-size: 1.87rem;
    margin-bottom: 0px;
    color: ${Colors.black100};

    @media (max-width: 1024px) {
      margin-left: 20px;
    }

    @media (max-width: 800px) {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;

      color: ${Colors.black100};
      margin-left: 20px;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const HeaderEvent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 54px;

  div.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }

    button {
      cursor: pointer;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 12.81rem;
      height: 2.81rem;

      background: ${Colors.primary10};
      border-radius: 0.65rem;

      span {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        margin-bottom: 0px;

        color: ${Colors.primary100};
        margin-left: 10px;
      }
    }

    button.second {
      margin-left: 12px;
    }
  }
`;

export const ContainerTabs = styled.div`
  margin-top: 1.5rem;

  .ant-tabs-tab {
    font-size: 1.03rem;
    font-family: "Poppins";
    font-weight: normal;
    margin-bottom: 0px;
    color: ${Colors.black70};
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-active {
    color: ${Colors.primary100} !important;
    font-weight: 700;
    font-size: 1.03rem;
    margin-bottom: 0px;
    color: ${Colors.primary100};
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-btn {
    font-family: "Poppins";
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: "Poppins";
    color: ${Colors.primary100} !important;
  }

  @media (max-width: 1230px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 800px) {
    margin-top: 20px;

    .ant-tabs-tab {
      font-family: "Poppins";
      font-weight: normal;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;

      color: ${Colors.black70};
    }

    .ant-tabs-tab-active {
      color: ${Colors.primary100} !important;

      font-weight: bold;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;

      color: ${Colors.primary100};
    }

    .ant-tabs-ink-bar {
      color: ${Colors.primary100};
      background: ${Colors.primary100};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${Colors.primary100} !important;
      font-weight: 500;
    }

    .ant-tabs-nav {
      width: 100% !important;
    }

    .ant-tabs-tab {
      display: block;
      flex: 1;
      text-align: center;
    }

    .ant-tabs-nav > div:nth-of-type(1) {
      display: unset !important;
      width: 100% !important;
    }

    .ant-tabs-tab-active {
      color: ${Colors.primary100} !important;
    }
    .ant-tabs-ink-bar {
      color: ${Colors.primary100};
      background: ${Colors.primary100};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${Colors.primary100} !important;
      font-weight: 500;
    }
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.81rem 2.34rem;

  border: 1px solid #f0f0f0;
  border-bottom-left-radius: 0.93rem;
  border-bottom-right-radius: 0.93rem;
`;

export const Title = styled.h2`
  font-family: Cairo;
  font-size: 1.87rem;
  font-weight: 700;
  line-height: 64px;
  text-align: left;
  color: #11142d;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.4rem;
  gap: 0.84rem;
`;

export const RowTexts = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.03rem;
`;

export const RowTextsCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.37rem;
`;

export const Status = styled.div`
  width: 0.93rem;
  height: 0.93rem;
  border-radius: 100%;
  background-color: #93c77b;
`;

export const TitleRow = styled.h3`
  font-family: Open Sans;
  font-size: 1.21rem;
  font-weight: 400;
  line-height: 1.95rem;
  text-align: left;
  margin-bottom: 0px;
  color: #585b6c;
`;

export const TitleStrongRow = styled.h3`
  font-family: Open Sans;
  font-size: 1.21rem;
  font-weight: 600;
  line-height: 1.95rem;
  text-align: left;
  margin-bottom: 0px;
  color: #11142d;
`;

export const Divider = styled.div``;

export const ContainerBottom = styled.div`
  margin-top: 30px;
  padding-top: 30px;

  border-top: 1px solid #e9e7ee;
`;

export const TitleBottom = styled.div`
  font-family: Open Sans;
  font-size: 1.03rem;
  font-weight: 400;
  line-height: 1.4rem;
  text-align: left;
  color: #888996;
`;

export const ContainerGraphs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Graph = styled.div``;

export const ContainerHeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ButtonGenerateReport = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  width: 12rem;
  height: 2.81rem;
  padding-left: 0.93rem;
  display: flex;
  align-items: center;
  gap: 0px;
  border-radius: 14px;
  background-color: ${Colors.primary10};

  span {
    margin-left: 7px;
    font-family: Cairo;
    font-size: 0.93rem;
    font-weight: 700;
    color: ${Colors.primary100};
  }

  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
`;

export const ButtonMakeCheckinOut = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  width: 12rem;
  height: 2.81rem;
  padding-left: 0.93rem;
  gap: 0px;
  border-radius: 14px;
  background-color: ${Colors.primary100};

  span {
    margin-left: 7px;
    font-family: Cairo;
    font-size: 0.93rem;
    font-weight: 700;
    color: ${Colors.white};
  }

  @media (max-width: 800px) {
    width: 100%;
    border-radius: 8px;
  }
`;


export const ContainerNeed = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 3.75rem;

  @media (max-width: 670px) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const TitleNeed = styled.h3`
  color: ${Colors.black30};
  font-family: Open Sans;
  font-size: 1.21rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;

  @media (max-width: 670px) {
    color: ${Colors.gray20};
    font-family: Open Sans;
    font-size: 0.62rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 5.62rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem;

  margin-top: 1.4rem;
  border-radius: 0.93rem;
  border: 1px solid ${Colors.black10};
  background: ${Colors.white};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 670px) {
    height: 2.37rem;
    margin-top: 0.62rem;
    border-radius: 0.5rem;
    padding: 1.12rem 0.62rem;

    svg {
      width: 0.98rem;
      height: 0.98rem;
    }
  }

  div.content {
    display: flex;
    align-items: center;

    h5 {
      color: ${Colors.secondary40};
      font-family: Poppins;
      font-size: 1.21rem;
      font-style: normal;
      font-weight: 400;
      margin-left: 0.93rem;
      margin-bottom: 0px;

      @media (max-width: 670px) {
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin-left: 0.75rem;
      }
    }
  }
`;

export const CircleIcon = styled.div`
  width: 2.81rem;
  height: 2.81rem;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.93rem;
  background: ${Colors.secondary10};

  @media (max-width: 670px) {
    background: ${Colors.white};
    width: 1.12rem;
    height: 1.12rem;

    img {
      width: 1.12rem;
      height: 1.12rem;
    }
  }
`;
