import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContainerItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-right: 1.75rem;
  padding-left: 1.68rem;

  a {
    width: 100%;
  }
`;

export const Title = styled.h1`
  width: 100%;
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 0px;
  text-align: left;
`;

export const TitleMain = styled.h2`
  width: 100%;
  text-align: left;
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.87rem;
  margin-bottom: 0.75rem;
  background: ${Colors.secondary10};
`;

export const DividerWithMargin = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.87rem;
  margin-bottom: 1.87rem;
  background: ${Colors.secondary10};
`;

export const TitleTotal = styled.h2`
  color: ${Colors.gray20};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  text-align: normal;
  line-height: normal;
  text-transform: uppercase;
`;

export const TitlePrice = styled.h3`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.56rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
`;

export const TitleDescription = styled.h4`
  width: 100%;
  color: ${Colors.gray10};
  font-family: Open Sans;
  text-align: left;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const RowSpaceSm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 49px;
`;

export const TitleRowName = styled.h3`
  color: var(--Preto, #11142d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TitleRowValue = styled.h4`
  color: var(--Preto, #11142d);
  font-family: Cairo;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TitleRowValuePrice = styled.h4`
  color: var(--Preto, #11142d);
  font-family: Cairo;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const RowSpaceMd = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
`;

export const ContainerTax = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.1rem;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid ${Colors.border20};
  background: ${Colors.white};
  padding-left: 0.62rem;
  margin-top: 0.81rem;
`;

export const TitleTax = styled.p`
  color: ${Colors.black50};
  font-family: Open Sans;
  font-size: 0.62rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 0.25rem;

  strong {
    color: ${Colors.black50};
    font-family: Open Sans;
    font-size: 0.62rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const ContainerOverlay = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  gap: 35px;
`;

export const RowItemTotal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColumnContentTotal = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleTotalSmall = styled.h4`
  color: ${Colors.gray20};
  font-family: Open Sans;
  font-size: 0.56rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0px;
`;

export const TitlePriceSmall = styled.h5`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.12rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
`;

export const ContainerLastReceipts = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${Colors.lightgray10};
  border-top: 1px solid ${Colors.secondary10};
  padding-top: 1.25rem;
  margin-top: 1.68rem;
`;

export const ContainerItemsReceipts = styled.div`
  display: flex;
  width: 100%;
  /* height: 100%; */

  flex-direction: column;
  padding-right: 1.75rem;
  padding-left: 1.68rem;
`;

export const ListReceipts = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
`;

export const ItemReceipt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.93rem;
  width: 100%;
`;

export const ColumnLineIcon = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Hr = styled.div`
  width: 0.5px;
  height: 3.43rem;
  flex-shrink: 0;
  background: ${Colors.secondary10};
`;

export const RowContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 11px 0px 8px;
`;

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnContentFlexEnd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const TitleItemReceipt = styled.h3`
  color: ${Colors.black50};
  font-family: Open Sans;
  font-size: 0.62rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const TitleContentItem = styled.h4`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
`;

export const TitleDataSmall = styled.h5`
  color: ${Colors.black50};
  font-family: Open Sans;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CardReport = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${Colors.border20};
  background: ${Colors.white};
  box-shadow: 0px 2px 10px 0px rgba(108, 93, 211, 0.05);
  margin-top: 60px;
  margin-bottom: 34px;
`;

export const ColumnContentReport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 304px;
  height: 75px;

  h3 {
    color: ${Colors.black100};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 9px;
  }
`;
