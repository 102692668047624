import { useCallback } from "react";

import { useSearchParams } from "react-router-dom";

import { Checkbox } from "antd";

import { RiCloseCircleLine, RiMoneyDollarCircleLine } from "react-icons/ri";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";

import {
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  NormalCardBorder,
} from "./styles";

export const dataPayment = [
  { id: 1, name: "PIX", key: "pix" },
  { id: 2, name: "Cartão de crédito", key: "credit-card" },
  { id: 3, name: "Boleto bancário", key: "boleto" },
  { id: 4, name: "Cortesia", key: "courtesy" },
];

export function ModalFilterByFormPayment() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isOpenFilterModalPayment, setIsOpenFilterModalPayment } =
    useFiltersContext();

  const closeModal = useCallback(() => {
    setIsOpenFilterModalPayment(false);
  }, [setIsOpenFilterModalPayment]);

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        payment_type: value,
      });

      closeModal();
    },
    [closeModal, searchParams, setSearchParams]
  );

  return (
    <ModalStyled
      width={857}
      visible={isOpenFilterModalPayment}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Filtrar por forma de pagamento</h1>

        <h2>
          Selecione uma forma de pagamento para a visualização dos
          resultados.
        </h2>

        <ContainerSelect>
          <Checkbox.Group style={{ width: "100%" }} defaultValue={["Pear"]}>
            {dataPayment?.map((type) => (
              <NormalCardBorder
                onClick={() => handleSelectedIdFilter(type.key)}
              >
                <div className="circle-icon">
                  <RiMoneyDollarCircleLine
                    size={16}
                    color={Colors.secondary100}
                  />
                </div>

                <h3 className="normal">{type?.name}</h3>
              </NormalCardBorder>
            ))}
          </Checkbox.Group>
        </ContainerSelect>
      </ContainerModal>
    </ModalStyled>
  );
}
