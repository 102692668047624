import { BiPlus } from "react-icons/bi";

import { useCreateEvent } from "contexts/CreateEventContext";

import { useAuthTicketsByEventsId } from "services/Hook/Event/Ticket";

import { Colors } from "constants/colors";

import { TicketEvent } from "types";

import {
  ButtonAddTicket,
  ButtonAddTicketResponsive,
  Card,
  Container,
  Divider,
  LabelTitle,
  SubTitle,
  Title,
  TitleTicket,
} from "./styles";

import { ButtonNext, ButtonPrev, ContainerButtons } from "../../styles";

import { ListTicketByEvent } from "components/ListTicketByEvent";
import { ModalCreateAndEditTicket } from "components/ModalCreateAndEditTicket";
import { useCreateAndEditTicket } from "contexts/CreateAndEditTicketContext";
import { DrawerCreateAndEditTicket } from "components/DrawerCreateAndEditTicket";
import { useCreateTicket } from "hooks/useCreateTicket";

interface EventTicketInterface {
  results: TicketEvent[];
  count: number;
}

export function Ticket() {
  const { event } = useCreateEvent();

  const { setIsOpenModalTicket, setIsOpenDrawerTicket, setSelectedTicket } =
    useCreateAndEditTicket();

  const { data: dataTickets } = useAuthTicketsByEventsId<EventTicketInterface>([
    String(event?.event_response?.id) ?? "",
  ]);

  const { reset } = useCreateTicket({
    event_id: event?.event_response?.id ?? "",
  });

  return (
    <Container>
      <Card>
        <Title>6. Ingressos</Title>
        <SubTitle>
          Defina os detalhes referentes aos ingressos do seu evento.
        </SubTitle>

        <Divider />

        <LabelTitle>Crie os ingressos do seu evento</LabelTitle>

        <ButtonAddTicket
          type="button"
          onClick={() => {
            setSelectedTicket(null);
            setIsOpenModalTicket(true);
            reset();
          }}
        >
          <BiPlus size={18} color={Colors.primary100} />

          <span>Criar ingresso</span>
        </ButtonAddTicket>

        <ButtonAddTicketResponsive
          type="button"
          onClick={() => {
            setSelectedTicket(null);
            setIsOpenDrawerTicket(true);
            reset();
          }}
        >
          <BiPlus size={18} color={Colors.primary100} />

          <span>Criar ingresso</span>
        </ButtonAddTicketResponsive>

        {dataTickets && dataTickets?.results?.length > 0 ? (
          <>
            <Divider />

            <TitleTicket>INGRESSOS SALVOS</TitleTicket>

            <ListTicketByEvent event_id={event?.event_response?.id ?? ""} />
          </>
        ) : null}
      </Card>

      <ContainerButtons>
        <ButtonPrev type="button" onClick={() => {}}>
          <span>Voltar</span>
        </ButtonPrev>

        <ButtonNext type="button" onClick={event.goToNextStep}>
          <p>Prosseguir</p>
        </ButtonNext>
      </ContainerButtons>

      <ModalCreateAndEditTicket event_id={event?.event_response?.id ?? ""} />

      <DrawerCreateAndEditTicket event_id={event?.event_response?.id ?? ""} />
    </Container>
  );
}
