import { Colors } from "constants/colors";
import styled from "styled-components";

import { Modal } from "antd";

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  .custom-drawer .ant-drawer-title {
    color: ${Colors.secondary100};
    font-weight: bold;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }

  @media (max-width: 800px) {
    padding: 0px;
  }
`;

export const ModalStyledPurchasing = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerItems = styled.div`
  margin-top: 7.96rem;

  max-width: 1280px;
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 28px;
  padding-left: 28px;

  @media (max-width: 1024px) {
    margin-top: 85px;
  }

  div.header-title {
    h1.title {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 160%;

      color: ${Colors.black100};
      margin-top: 54px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .breadcrumb {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const ContainerAllItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.4rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerAllItemsMobile = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: none;
  }

  div.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-family: "Cairo";
      font-weight: 900;
      font-size: 16px;
      /* line-height: 25px; */
      margin-bottom: 0px;
      color: ${Colors.black100};
    }
  }
`;

export const CardTicket = styled.div`
  max-width: 1480px;
  width: 100%;

  min-height: 341px;
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  margin-left: 0px;
  border: 1px solid ${Colors.black10};
  padding: 20px 40px;
  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 20px;

  h2 {
    color: ${Colors.black100};
    font-weight: bold;
    font-size: 32px;
    line-height: 160%;
  }

  div.full-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  /* @media (max-width: 1200px) {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  } */
  h2 {
    font-family: "Cairo";
    font-weight: 900;
    font-size: 1.5rem;
    margin-bottom: 0px;
    color: ${Colors.black100};
  }
`;

export const ButtonFilter = styled.button`
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${Colors.white20};
  border-radius: 14px;
  width: 157px;
  height: 60px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    color: ${Colors.secondary100};
    margin-left: 10px;
  }
`;

export const DivEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  h2.empty {
    font-weight: bold;
    font-size: 26px;
    line-height: 160%;
    /* identical to box height, or 42px */

    color: ${Colors.gray10};
  }

  p.empty {
    font-weight: normal;
    font-size: 22px;
    line-height: 160%;
    /* or 35px */

    text-align: center;

    color: ${Colors.gray20};
  }
`;

export const ContainerFilters = styled.div`
  width: 100%;
  max-width: 476px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* @media (max-width: 1200px) {
    width: 100%;
    align-items: center;
    justify-content: space-between;
  } */

  nav {
    max-width: 476px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 36px; */

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 14px;
    border: 1px solid ${Colors.border};

    div.searchInput {
      width: 100%;
      height: 100%;

      input {
        height: 100%;
        width: 90%;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px 2px 0px 28px;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: ${Colors.gray};
      }
    }

    div.search {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18%;
      height: 100%;
      border-left: 1px solid ${Colors.border};
    }
  }
`;

export const ListAccordion = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div + div {
    margin-top: 18px;
  }
`;

export const HeaderSearching = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 63px;
  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  border-radius: 14px;

  div.filters {
    width: 32%;
    height: 100%;
    display: flex;
    border-left: 1px solid ${Colors.border30};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 31px 0px 40px;

    @media (max-width: 1120px) {
      padding: 0px 18px 0px 18px;
    }

    @media (max-width: 1180px) {
      width: 38%;
    }

    span {
      font-weight: bold;
      font-size: 18px;
      line-height: 34px;

      color: ${Colors.black100};

      @media (max-width: 1120px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;

export const HeaderEventBar = styled.div`
  width: 80%;
  display: flex;

  padding-left: 25px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div.buttons {
    button {
      cursor: pointer;
      background: transparent;
      border: 0;
      margin-left: 38px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 34px;
      color: ${Colors.black30};

      @media (max-width: 1120px) {
        font-size: 14px;
        line-height: 18px;
        margin-left: 18px;
      }
    }

    button.first {
      margin-left: 0px;
    }

    button.active {
      font-weight: bold;
      font-size: 18px;
      line-height: 34px;

      color: ${Colors.black100};

      @media (max-width: 1120px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

interface CardAccordionProps {
  active: boolean;
}

export const CardAccordion = styled.div<CardAccordionProps>`
  cursor: pointer;
  width: 100%;


  min-height: 5.2rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: ${(props) => (props.active ? Colors.black5 : Colors.white)};

  border: 1px solid ${Colors.black10};
  box-shadow: 0px 4px 20px rgba(18, 25, 34, 0.05);
  border-radius: 0.93rem;

  padding: 0.98rem 1.92rem 1.17rem 1.96rem;

  div.content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    div.image {
      margin-top: 7px;
    }

    div.texts {
      height: 100%;
      display: flex;
      flex-direction: column;

      justify-content: flex-start;
      margin-left: 23px;

      h2 {
        font-weight: 700;
        font-size: 0.93rem;
        margin-bottom: 0px;

        color: ${Colors.secondary100};
      }

      p {
        font-weight: 600;
        font-size: 0.84rem;
        margin-bottom: 0px;
        color: ${Colors.secondary40};
      }
    }
  }
`;

export const CardAddress = styled.div`
  width: 100%;
  height: 249px;
  padding: 14px 40px 24px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 14px;

  div.content {
    h2 {
      font-weight: bold;
      font-size: 22px;
      line-height: 160%;
      color: ${Colors.secondary100};
    }

    nav.texts {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      p {
        margin-top: -6px;
        font-weight: normal;
        font-size: 20px;
        color: ${Colors.black100};
      }
    }
  }

  div.buttons {
    display: flex;
    flex-direction: row;
  }
`;

export const ButtonVisualizationTicket = styled.button`
  width: 190px;
  height: 2.81rem;
  cursor: pointer !important;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${Colors.primary100};
  border-radius: 0.65rem;
  margin-right: 10px;

  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    color: ${Colors.white};
    margin-left: 10px;
  }
`;

export const ButtonDownload = styled.button`
  cursor: pointer;
  width: 190px;
  height: 2.81rem;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${Colors.primary10};
  border-radius: 0.65rem;
  margin-right: 10px;

  span {
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    color: ${Colors.primary100};
  }
`;

export const ButtonPrint = styled.button`
  cursor: pointer;
  width: 190px;
  height: 2.81rem;
  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 14px;

  background: ${Colors.primary10};

  span {
    font-weight: bold;
    font-size: 0.93rem;
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.primary100};
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;

  button {
    cursor: pointer;
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${Colors.black10};
  box-sizing: border-box;
  border-top: 0;
  border-radius: 20px;
  padding-right: 20px;
  padding-left: 30px;

  margin-top: -45px;

  position: relative;

  div.all {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 600px; */
    margin-top: 1.4rem;

    div.content {
      max-width: 600px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 70px;

      height: 105px;

      padding-bottom: 23px;

      border-bottom: 1px solid ${Colors.secondary10};

      &:last-child {
        margin-right: 8px;
      }

      div.column {
        display: flex;
        flex-direction: column;

        h2 {
          font-weight: bold;
          /* font-size: 17px; */
          font-size: 0.79rem;
          margin-bottom: 0px;
          /* line-height: 160%; */
          color: ${Colors.black80};
          overflow-wrap: break-word;
        }

        p {
          font-weight: normal;
          /* font-size: 15px; */

          font-size: 0.7rem;
          /* line-height: 160%; */
          color: ${Colors.black70};
          overflow-wrap: break-word;
          word-break: break-all;
        }
      }
    }

    div.container-image {
      margin-top: 40px;
      margin-left: 10px;

      @media (max-width: 1200px) {
        padding-left: 10px;
      }

      img {
        border-radius: 10px;
      }
    }
  }
`;

export const DataDropDown = styled.div`
  /* display: block; */
  /* position: relative; */

  width: 100%;

  z-index: 2;
  margin-bottom: 42px;

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;
      }
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const BadgeMobile = styled.div`
  margin-top: 27px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 32px;

  background: ${Colors.white30};
  border-radius: 5px;

  h4 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0px;
    color: ${Colors.black100};
  }
`;

export const BorderNormalPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${Colors.secondary10};

  div.column {
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;

      color: ${Colors.black};
    }

    strong {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;

      color: ${Colors.black};
    }
  }

  div.quantity {
    h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
      text-align: center;

      color: ${Colors.black};
    }
  }
`;

export const BorderLongPrice = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 26px;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${Colors.secondary10};

  div.column {
    display: flex;
    flex-direction: column;

    h4 {
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;

      color: ${Colors.black};
    }

    strong {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;

      color: ${Colors.black};
    }

    div.coupon {
      span {
        font-weight: bold;
        font-size: 10px;
        line-height: 19px;

        color: ${Colors.secondary70};
      }
    }
  }

  div.quantity {
    h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
      text-align: center;

      color: ${Colors.black};
    }
  }
`;

export const BorderSmallPrice = styled.div`
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 8px;
  border-bottom: 1px solid ${Colors.secondary10};

  h2.title-card {
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;

    display: flex;
    align-items: center;

    color: ${Colors.black};
    margin-bottom: 0px;
  }

  div.card {
    div.t-payment {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  span {
    margin-top: 0px;
  }
`;

export const ButtonCancelPurchaseMobile = styled.button`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  background: ${Colors.primary10};
  border-radius: 8px;
  margin-top: 40px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;

    color: ${Colors.primary100};
  }
`;

export const ContainerHelp = styled.div``;

export const ContainerDrawer = styled.div`
  width: 100%;
  height: 100%;

  div.data-drawer {
    display: flex;
    flex-direction: column;
    height: 113px;
    margin-top: 27px;
    border-bottom: 1px solid ${Colors.secondary10};

    div.data {
      display: flex;
      flex-direction: column;

      padding-left: 14px;
      padding-right: 14px;

      h1 {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;

        color: ${Colors.black100};
      }
    }
  }

  div.next-drawer {
    display: flex;
    flex-direction: column;
    height: 134px;
    margin-top: 27px;
    margin-bottom: 25px;
    border-bottom: 1px solid ${Colors.secondary10};

    div.next {
      display: flex;
      flex-direction: column;

      padding-left: 14px;
      padding-right: 14px;

      h1 {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;

        color: ${Colors.black100};
      }

      div {
        display: flex;
        flex-direction: column;

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: red;
          border-color: transparent;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;

          color: ${Colors.secondary70};
        }
      }
    }
  }

  div.select-drawer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 84px;
    margin-top: 27px;

    div.select {
      display: flex;
      flex-direction: column;

      padding-left: 14px;
      padding-right: 14px;

      h1 {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;

        color: ${Colors.black100};
      }

      div {
        width: 100%;
        margin-top: 4px;
      }
    }
  }

  div.buttons-drawer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 37px;

    padding-left: 14px;
    padding-right: 14px;

    button.first {
      border: 0;
      width: 97px;
      height: 31px;
      left: 96px;
      top: 470px;

      background: ${Colors.primary10};
      border-radius: 6px;

      font-weight: 600;
      font-size: 12px;
      line-height: 22px;

      color: ${Colors.primary100};
    }

    button.second {
      border: 0;
      width: 97px;
      height: 31px;

      background: ${Colors.primary100};

      box-shadow: 0px 3px 20px rgba(212, 0, 80, 0.15);
      border-radius: 6px;

      font-weight: bold;
      font-size: 12px;
      line-height: 22px;

      color: ${Colors.white};
    }
  }
`;

export const InputContainer = styled.div`
  width: 100%;

  margin-top: 17px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 2px solid ${Colors.secondary20};

  input {
    border: 0;
    outline: 0;
    margin-left: 24px;
    margin-bottom: 5px;
    width: 100%;
  }
`;

export const ContainerModalTicketDetail = styled.div`
  width: 100%;
  height: 100%;

  margin-top: 1.92rem;

  padding: 0px 1.87rem 0px 1.87rem;

  @media (max-width: 800px) {
    border-radius: 10px;
    padding: 0px;
  }
`;

export const DivBorder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  border: 1px solid #ccc;
  border-radius: 0px 0px 1.12rem 1.12rem;
`;

export const CenterTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.75rem;

  h1.visualization {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0px;
    color: ${Colors.secondary100};
  }
`;

export const BackgroundTicket = styled.div`
  width: 100%;
  height: 9.18rem;

  position: relative;

  position: relative;
  overflow: hidden;

  border-radius: 1.12rem 1.12rem 0px 0px;
  padding: bottom;
  display: flex;
  flex-direction: column;

  justify-content: flex-end;

  @media (max-width: 800px) {
    height: 134px;

    border-radius: 10px 10px 0px 0px;
  }

  img {
    filter: brightness(25%);
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  div.content {
    width: 100%;
    z-index: 2;
    position: relative;
    padding-left: 1.4rem;
    padding-bottom: 1rem;

    @media (max-width: 800px) {
      padding-left: 12px;
      padding-bottom: 0px;
    }

    div.text {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;

      svg {
        margin-right: 10px;
      }

      @media (max-width: 800px) {
        svg {
          max-height: 15px;
          max-width: 15px;
        }
      }
    }

    h3 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 1.21rem;
      margin-bottom: 0.93rem;

      color: ${Colors.white};

      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

      @media (max-width: 800px) {
        font-size: 16px;
        line-height: 30px;
      }
    }

    h4 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 0.93rem;
      margin-bottom: 0px;

      color: ${Colors.white};
      margin-bottom: 0px;
      /* text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); */

      @media (max-width: 800px) {
        font-size: 10px;
        line-height: 14px;
        width: 90%;
      }
    }

    p {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 0.93rem;
      margin-bottom: 0px;

      color: ${Colors.white};
      margin-bottom: 0px;

      @media (max-width: 800px) {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;

export const ContainerContentModalTicket = styled.div`
  width: 100%;
  padding: 2.34rem 2.81rem 0px 1.4rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 800px) {
    padding: 25px 20px 0px 15px;
  }

  div.data {
    h3.data {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 900;
      font-size: 1.21rem;
      margin-bottom: 0px;
      line-height: 1.5rem;

      color: ${Colors.black100};

      @media (max-width: 800px) {
        font-size: 14px;
        line-height: 1rem;
        margin-bottom: 10px;
      }
    }

    div.table {
      display: flex;
      flex-direction: column;
      margin-top: 0.93rem;

      @media (max-width: 800px) {
        margin-top: 0.4rem;
      }

      h5 {
        font-weight: 700;
        font-size: 0.93rem;
        /* line-height: 160%; */
        margin-bottom: 0px;
        font-family: "Cairo";

        color: ${Colors.black100};
        margin-bottom: 0px;

        @media (max-width: 800px) {
          font-weight: 700;
          font-size: 14px;
          line-height: 26px;
        }
      }

      p {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 0.84rem;
        margin-bottom: 0px;

        color: ${Colors.black60};

        @media (max-width: 800px) {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  div.qr {
    width: 14.25rem;
    min-height: 15.59rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background: ${Colors.white};
    border: 2px solid #F0F0F0;
    border-radius: 0.46rem;
    padding: 0.93rem 0.93rem 0.56rem 0.93rem;


    div.shadow {
      width: 100%;
      padding: 1.40rem;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 800px) {
      display: none;
    }

    h1 {
      font-weight: bold;
      font-size: 0.93rem;
      /* line-height: 160%; */
      margin-bottom: 0px;

      letter-spacing: 0.1em;

      color: ${Colors.black100};
      /* margin-top: 20px; */
    }
  }

  div.qr-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 16px;

    button {
      border: 0;
      margin-top: 12px;
      width: 84px;
      height: 31px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      background: ${Colors.primary10};
      border-radius: 6px;

      font-weight: 600;
      font-size: 10px;
      line-height: 19px;

      color: ${Colors.primary100};
    }

    @media (min-width: 800px) {
      display: none;
    }
  }
`;

export const DivLogo = styled.div`
  width: 100%;

  margin-top: 40px;
  height: 8.57rem;

  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 30px;

  img {
    width: 9.79rem;
    height: 2.53rem;
  }

  @media (max-width: 800px) {
    display: none;
  }

  div.all-content {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    border-top: 1px solid ${Colors.secondary10};
  }

  span {
    font-size: 9px;
    color: ${Colors.gray50};
    letter-spacing: 6px;
  }
`;

export const DivBorderMobile = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  margin-top: 15px;

  @media (min-width: 800px) {
    display: none;
  }

  div.item {
    border-top: 1px solid ${Colors.secondary10};
    padding-top: 25px;

    h3 {
      font-weight: bold;
      font-size: 14px;
      line-height: 25px;

      color: ${Colors.black100};
    }

    div.border {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      width: 100%;
      height: 38px;
      padding-left: 19px;
      padding-right: 20px;

      background: #ffffff;
      border: 1px solid #f0f0f0;
      border-radius: 8px;
      margin-top: 10px;

      div.row {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          margin-bottom: 0px;
          margin-left: 12px;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;

          color: ${Colors.primary100};
        }
      }
    }
  }
`;

export const ContainerButtonModalTicket = styled.div`
  margin-top: 60px;
  margin-bottom: 35px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ButtonChangeOwnership = styled.button`
  border: 0;
  /* height: ; */
  width: 100%;
  height: 38px;

  background: ${Colors.primary100};

  box-shadow: 0px 5px 30px rgba(212, 0, 80, 0.25);
  border-radius: 8px;

  font-weight: bold;
  font-size: 12px;
  line-height: 22px;

  color: ${Colors.white};
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-right: 26px;
  padding-left: 26px; */

  div.title-header {
    h2.title {
      font-weight: 600;
      font-size: 40px;
      line-height: 160%;
      text-align: center;
      margin-top: 190px !important;

      color: ${Colors.secondary100};

      @media (max-width) {
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  div.container-web {
    width: 100%;
    padding-left: 37px;
    padding-right: 37px;
    @media (max-width: 1024px) {
      display: none;
    }

    div.cancel-purchasing {
      margin-top: 80px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      button {
        cursor: pointer;
        border: 0;
        width: 389px;
        height: 82px;
        left: 1244px;
        top: 1628px;
        background: ${Colors.primary10};
        border-radius: 14px;

        span {
          font-weight: bold;
          font-size: 26px;
          line-height: 160%;

          text-align: center;
          color: ${Colors.primary100};
        }
      }
    }

    footer.help-you {
      display: flex;
      flex-direction: column;
      margin-top: 80px;
      justify-content: flex-end;
      padding-top: 80px;
      border-top: 1px solid ${Colors.secondary10};

      h1 {
        text-align: left;
        font-weight: bold;
        font-size: 26px;
        line-height: 160%;

        color: ${Colors.black30};
        margin-bottom: 30px;
      }

      div.drop {
        cursor: pointer;
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-left: 30px;
        padding-right: 39px;

        background: ${Colors.white};
        /* Preto/preto 10 */

        border: 1px solid ${Colors.black10};
        box-sizing: border-box;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
        border-radius: 20px;

        div.first {
          display: flex;
          flex-direction: row;
          align-items: center;

          div.badge {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;

            background: ${Colors.primary10};
            border-radius: 20px;
          }

          h2 {
            color: ${Colors.primary100};
            font-weight: normal;
            font-size: 26px;
            line-height: 160%;
            margin-left: 20px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  div.container-mobile {
    width: 100%;
    @media (min-width: 1024px) {
      display: none;
    }

    footer.help-you {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      justify-content: flex-end;

      border-top: 1px solid ${Colors.secondary10};
      padding: 24px 28px 24px 28px;

      h1 {
        text-align: left;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;

        text-transform: uppercase;

        color: ${Colors.gray20};

        opacity: 0.75;
        margin-bottom: 10px;
      }

      div.drop {
        cursor: pointer;
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-left: 19px;
        padding-right: 5px;

        background: ${Colors.white};
        border: 1px solid ${Colors.black10};
        box-sizing: border-box;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        div.first {
          display: flex;
          flex-direction: row;
          align-items: center;

          div.badge {
            display: flex;
            align-items: center;
            justify-content: center;

            background: ${Colors.white};
          }

          h2 {
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            margin-left: 13px;

            color: ${Colors.secondary40};
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`;

export const ContainerBorderShipping = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  padding-bottom: 30px;
  border-bottom: 1px solid ${Colors.secondary10};

  div.badge-quantity {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 60px;

    background: ${Colors.white30};

    h4 {
      font-weight: 600;
      font-size: 26px;
      line-height: 160%;
      margin-bottom: 0px;

      color: ${Colors.black100};
    }
  }

  div.content-border {
    display: flex;
    flex-direction: column;

    h2.title-border {
      font-weight: 500;
      font-size: 22px;
      line-height: 160%;
      margin-bottom: 0px;

      color: ${Colors.secondary100};
    }

    div.row {
      margin-top: 12px;
      strong {
        font-weight: bold;
        font-size: 26px;
        line-height: 160%;

        color: ${Colors.black100};
      }

      span {
        font-weight: 600;
        font-size: 22px;
        line-height: 160%;

        text-decoration-line: line-through;
        margin-left: 15px;

        color: ${Colors.black40} #a0a1ab;
      }
    }
  }
`;

export const ContainerBorderNormalShipping = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  padding-bottom: 30px;
  border-bottom: 1px solid ${Colors.secondary10};

  div.badge-quantity {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 60px;

    background: ${Colors.white30};

    h4 {
      font-weight: 600;
      font-size: 26px;
      line-height: 160%;
      margin-bottom: 0px;

      color: ${Colors.black100};
    }
  }

  div.content-border {
    display: flex;
    flex-direction: column;

    h2.title-border {
      font-weight: 500;
      font-size: 22px;
      line-height: 160%;
      margin-bottom: 0px;

      color: ${Colors.secondary100};
    }

    div.row {
      margin-top: 12px;
      strong {
        font-weight: bold;
        font-size: 26px;
        line-height: 160%;

        color: ${Colors.black100};
      }

      span {
        font-weight: 600;
        font-size: 22px;
        line-height: 160%;

        text-decoration-line: line-through;
        margin-left: 15px;

        color: ${Colors.black40} #a0a1ab;
      }
    }
  }
`;

export const ContainerModalTypePayment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  padding-bottom: 30px;

  border-bottom: 1px solid ${Colors.secondary10};

  h3.type {
    margin-bottom: 0px;

    font-weight: 500;
    font-size: 22px;
    line-height: 160%;

    color: ${Colors.black100};
  }

  div.t-payment {
    display: flex;
    align-items: center;

    span.price {
      font-weight: 600;
      font-size: 22px;
      line-height: 160%;

      color: ${Colors.black100};
    }
  }
`;

export const ContainerModalSubtotal = styled.div`
  margin-top: 48px;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-bottom: 42px;

  border-bottom: 1px solid ${Colors.secondary10};

  div.row-sub {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 30px;

    h3 {
      margin-bottom: 0px;
      font-weight: 500;
      font-size: 22px;
      line-height: 160%;

      color: ${Colors.black100};
    }

    span {
      font-weight: 600;
      font-size: 28px;
      line-height: 160%;

      color: ${Colors.black100};
    }
  }
`;

export const ContainertModalTotal = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 26px;
    line-height: 160%;

    color: ${Colors.black100};
  }

  span {
    font-weight: bold;
    font-size: 36px;
    line-height: 160%;

    color: ${Colors.black100};
  }
`;

export const ContainerDetailsShoping = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 49px 50px 44px 50px;

  margin-top: 56px;

  background: ${Colors.white};
  border: 1px solid ${Colors.black10};
  box-sizing: border-box;
  border-radius: 20px;

  div.header-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 30px;
    border-bottom: 1px solid ${Colors.secondary10};

    div.badge-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 306px;
      height: 48px;

      background: ${Colors.white30};
      border-radius: 5px;

      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 0px;
        color: ${Colors.black100};
      }
    }

    div.content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      img {
        border-radius: 10px;
      }

      div.column {
        display: flex;
        flex-direction: column;
        margin-left: 24px;

        h2 {
          font-weight: 600;
          font-size: 26px;
          line-height: 160%;
          margin-bottom: 0px;
          color: ${Colors.black100};
        }

        span {
          font-weight: 600;
          font-size: 20px;
          line-height: 160%;

          color: ${Colors.black50};
          margin-top: 10px;
        }

        a {
          font-weight: bold;
          font-size: 20px;
          line-height: 160%;

          text-decoration-line: underline;

          color: ${Colors.primary100};

          margin-top: 12px;
        }
      }
    }
  }
`;

export const Center = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerModalConfirm = styled.div`
  margin-top: 53px;

  max-width: 75.89rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  div.texts-confirm {
    margin-top: 29px;

    h3 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      /* font-size: 32px; */
      font-size: 1.5rem;
      margin-bottom: 0px;
      text-align: center;

      color: ${Colors.secondary100};
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      /* font-size: 20px; */
      font-size: 0.93rem;

      text-align: center;
      color: ${Colors.black90};
    }
  }

  div.buttons {
    margin-top: 37.5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  width: 12.32rem;
  height: 2.81rem;

  background: ${Colors.primary10};
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    text-align: center;
    color: ${Colors.primary100};
  }
`;

export const ButtonConfirm = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;

  /* width: 263px;
  height: 60px; */
  width: 12.32rem;
  height: 2.81rem;

  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    /* font-size: 20px; */
    font-size: 0.93rem;
    text-align: center;

    color: ${Colors.white};
  }
`;

export const ContainerBottom = styled.div`
  margin-top: 1.87rem;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  border-bottom: 1px solid ${Colors.border40};
  padding-bottom: 1.87rem;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ButtonPrintTicket = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  width: 262px;
  height: 2.81rem;

  background: ${Colors.white20};
  border-radius: 0.65rem;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    margin-bottom: 0px;
    margin-left: 0.46rem;

    color: ${Colors.secondary100};
  }
`;

export const ContainerButtonBack = styled.div`
  margin-top: 1.87rem;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;

  width: 12.28rem;
  height: 2.81rem;

  background: ${Colors.primary10};
  border-radius: 0.65rem;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    margin-bottom: 0px;

    text-align: center;

    color: ${Colors.primary100};
  }
`;
