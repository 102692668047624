import { RiMailLine } from "react-icons/ri";
import {
  ActionWrapper,
  ActionWrapperDownload,
  ActionsContainer,
  Container,
  Content,
  EventContainer,
  Header,
  HeaderWrapper,
  Status,
  Title,
} from "./styles";
import { TransactionTicket } from "types";
import { Colors } from "constants/colors";
import { IoMdDownload } from "react-icons/io";
import print_ticket_icon from "assets/print-ticket-icon.svg";

const StatusForOf = {
  PAID: "Pago",
  PENDING: "Pendente",
  REFUND: "Estornada",
};

type LeadCardProps = {
  name: string;
  email?: string;
  eventName: string;
  eventTicketName: string;
  handleResendTicket(): void;
  handlePrintOutTicket(): void;
  handleDownloadTicket(): void;
  disabledResendTicket?: boolean;
  checkIn: boolean;
  checkOut: boolean;
  formatedCheckinAt: string;
  formatedCheckoutAt: string;
};

function LeadCard({
  name,
  email,
  eventName,
  eventTicketName,
  handleResendTicket,
  handleDownloadTicket,
  handlePrintOutTicket,
  disabledResendTicket = false,
  checkIn,
  checkOut,
  formatedCheckinAt,
  formatedCheckoutAt,
}: LeadCardProps) {
  return (
    <Container>
      <HeaderWrapper>
        <Header>
          <Title>{name}</Title>
          {email && <Content>{email}</Content>}
        </Header>

        <ActionsContainer>
          <ActionWrapper
            type="button"
            onClick={handleResendTicket}
            disabled={disabledResendTicket}
          >
            <RiMailLine size={15} />
          </ActionWrapper>

          <ActionWrapper onClick={handlePrintOutTicket}>
            <img
              src={print_ticket_icon}
              width="15px"
              height="15px"
              alt="print"
            />
          </ActionWrapper>

          <ActionWrapperDownload onClick={handleDownloadTicket}>
            <IoMdDownload size={15} color={Colors.primary100} />
          </ActionWrapperDownload>
        </ActionsContainer>
      </HeaderWrapper>

      <EventContainer>
        <Title>{eventName}</Title>
        <Content>{eventTicketName}</Content>
      </EventContainer>

      <EventContainer>
        <Title>Checkin</Title>
        <Content>{checkIn ? formatedCheckinAt : "Não"}</Content>
      </EventContainer>

      <EventContainer>
        <Title>Checkout</Title>
        <Content>{checkOut ? formatedCheckoutAt : "Não"}</Content>
      </EventContainer>
    </Container>
  );
}

export default LeadCard;
