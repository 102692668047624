
import { Link, useSearchParams } from "react-router-dom";

import { Tabs } from "antd";

import { sortingCheckInOut } from "constants/filters";
import { useAuthMyEvent } from "services/Hook/Event";

import { useDisclosure } from "hooks/useDisclosure";

import { Event } from "types";

import {
  Container,
  ContainerItems,
  HeaderEvent,
  ContainerTabs,
  ContainerHeaderButtons,
  ButtonMakeCheckinOut,
  ContainerNeed,
  TitleNeed,
  Card,
  CircleIcon,
} from "./styles";

import { Checkout } from "./Tabs/CheckOut";

import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Footer } from "components/Footer";
import { HeaderFilter } from "components/HeaderFilter";
import { ModalFilterByEvent } from "components/ModalFilterByEvent";
import { ModalCheckout } from "./components/ModalCheckout";
import { Header } from "components/Header";
import { ModalCheckIn } from "./components/ModalCheckIn";
import { EmptySearchComponent } from "components/EmptySearchComponent";

import { CheckIn } from "./Tabs/CheckIn";


import { BiChevronRight } from "react-icons/bi";
import { Colors } from "constants/colors";

import event_available from "assets/qr_code.svg";
import customer_service from "assets/ri_customer-service-line.svg";

const { TabPane } = Tabs;

const metaTags = {
  title: `CheckinOut - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  noIndex: true,
};

export default function CheckInOutNew() {
  const [searchParams, setSearchParams] = useSearchParams();

  const eventId = searchParams.get("events") ?? "";
  const tabName = searchParams.get("tab_name") ?? "checkin";

  const { data } = useAuthMyEvent<Event>(eventId ?? null);

  const {
    isModalVisible: isModalVisibleCheckIn,
    handleOk: handleOkCheckIn,
    handleCancel: handleCancelCheckIn,
    showModal: showModalCheckIn,
  } = useDisclosure();

  const {
    isModalVisible: isModalVisibleCheckout,
    handleOk: handleOkCheckout,
    handleCancel: handleCancelCheckout,
    showModal: showModalCheckout,
  } = useDisclosure();

  const handleActiveTab = (activeKey: string) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      tab_name: activeKey,
    });
  };

  return (
    <>
      <Header metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <div className="bread">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Painel de check-in & out", link: "/" },
              ]}
            />
          </div>

          <HeaderEvent>
            <h1>Painel de check-in & out</h1>

            {tabName === "checkin" ? (
              <ContainerHeaderButtons>
                <ButtonMakeCheckinOut type="button" onClick={showModalCheckIn}>
                  <img
                    src={event_available}
                    width={18}
                    height={18}
                    alt="event available"
                  />

                  <span>Realizar check-in</span>
                </ButtonMakeCheckinOut>
              </ContainerHeaderButtons>
            ) : null}

            {tabName === "checkout" ? (
              <ContainerHeaderButtons>
                <ButtonMakeCheckinOut type="button" onClick={showModalCheckout}>
                  <img
                    src={event_available}
                    width={18}
                    height={18}
                    alt="event available"
                  />

                  <span>Realizar check-out</span>
                </ButtonMakeCheckinOut>
              </ContainerHeaderButtons>
            ) : null}
          </HeaderEvent>

          <ContainerTabs>
            <Tabs onChange={handleActiveTab} activeKey={tabName}>
              <TabPane tab="Check-in" key={"checkin"}>
                <HeaderFilter filters={sortingCheckInOut} />

                {data ? <CheckIn event={data} /> : <EmptySearchComponent />}
              </TabPane>

              <TabPane tab="Check-out" key="checkout">
                <HeaderFilter filters={sortingCheckInOut} />

                {data ? <Checkout event={data} /> : <EmptySearchComponent />}
              </TabPane>

              <TabPane tab="Participantes" key="3"></TabPane>
            </Tabs>
          </ContainerTabs>

          <ContainerNeed>
            <TitleNeed>PRECISA DE AJUDA?</TitleNeed>

            <Link to="/faq">
              <Card>
                <div className="content">
                  <CircleIcon>
                    <img src={customer_service} alt="" />
                    </CircleIcon>

                    <h5>Suporte</h5>
                </div>

                <BiChevronRight size={28} color={Colors.secondary40} />
              </Card>
            </Link>
          </ContainerNeed>
        </ContainerItems>

        <ModalFilterByEvent isMultiple={false} />

        <ModalCheckIn
          isModalVisible={isModalVisibleCheckIn}
          handleCancel={handleCancelCheckIn}
          handleOk={handleOkCheckIn}
        />

        <ModalCheckout
          isModalVisible={isModalVisibleCheckout}
          handleCancel={handleCancelCheckout}
          handleOk={handleOkCheckout}
        />
      </Container>

      <Footer />
    </>
  );
}
