import { FormEvent, useCallback, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Select } from "antd";

import { RiCalendar2Line, RiContactsLine } from "react-icons/ri";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Colors } from "constants/colors";

import {
  BorderSelect,
  ColumnDrawer,
  ContainerDrawer,
  ContainerFilterDrawer,
  ContainerForm,
  ContentDrawer,
  InputContainer,
  LabelFilter,
  SelectStyled,
  BadgeWithClose,
} from "./styles";
import moment from "moment";

import { useSearchMyEvent } from "hooks/useSearchMyEvent";

import { AiFillCloseCircle } from "react-icons/ai";
import { useAuthOrganizer } from "services/Hook/Organizer";
import { useAuthTicketsByEventsId } from "services/Hook/Event/Ticket";
import { EventOrganizer, TicketEvent } from "types";
import { dataPayment } from "components/ModalFilterByFormPayment";
import { dataStatus } from "components/ModalFilterByStatus";
import { DATA_TYPE_MOCK } from "components/ModalFilterByPurchaseType";
import { KeySort } from "constants/filters";
import { date } from "utils/masks";

const { Option } = Select;

interface EventTicketInterface {
  results: TicketEvent[];
  count: number;
}

type CreateDataEventFormData = {
  organizer: string;
  events: string;
  tickets: string;
  payment_type: string;
  price_min: number;
  price_max: number;
  data_gte: string;
  data_lte: string;
  payment_status: string;
  type: string;
};

const createDataEventFormSchema = yup.object().shape({
  price_min: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, "A Quantidade mínima é 1")
    .max(999999, "A Quantidade máxima é 999999")
    .notRequired(),
  price_max: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, "A Quantidade mínima é 1")
    .max(999999, "A Quantidade máxima é 999999")
    .notRequired(),
  begin_date: yup.string().nullable().notRequired(),
  end_date: yup.string().nullable().notRequired(),
  events: yup.string().notRequired().nullable(),
  tickets: yup.string().notRequired(),
  payment_type: yup.string().notRequired(),
  payment_status: yup.string().notRequired(),
  type: yup.string().notRequired(),
});

interface Filter {
  title: string;
  sortKey: KeySort;
}

interface FormFilterResponsiveProps {
  setOpen?(value: boolean): void;
  filters: Filter[];
}

export function FormFilterResponsive({
  filters,
  setOpen,
}: FormFilterResponsiveProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [nameOrganizer, setNameOrganizer] = useState("");
  const [eventsInFilter, setEventsInFilter] = useState<EventOrganizer[]>([]);

  const { data: dataOrganizers } = useAuthOrganizer();

  const organizer = searchParams.get("organizer") ?? "";
  const data_gte = searchParams.get("data_gte") ?? "";
  const data_lte = searchParams.get("data_lte") ?? "";
  const type = searchParams.get("type") ?? "";
  const paymentStatus = searchParams.get("payment_status") ?? "";
  const ticket = searchParams.get("tickets") ?? "";
  const paymentType = searchParams.get("payment_type") ?? "";
  const events = searchParams.get("events") ?? "";

  const { data: dataEvents } = useSearchMyEvent();

  const { control, handleSubmit, watch, reset, register } =
    useForm<CreateDataEventFormData>({
      resolver: yupResolver(createDataEventFormSchema),
    });

  const eventWatch = watch("events");

  const eventsLength = events?.split(",").length;

  const { data: dataTickets } = useAuthTicketsByEventsId<EventTicketInterface>([
    String(eventWatch ?? ""),
  ]);

  useEffect(() => {
    if (dataOrganizers) {
      const organizerSearch = dataOrganizers?.find(
        (organizerFind: any) => String(organizerFind._id) === organizer
      );

      if (organizerSearch) {
        setNameOrganizer(organizerSearch?.name);
      }
    }
  }, [dataOrganizers, organizer]);

  useEffect(() => {
    if (eventsLength > 0) {
      const eventsId = events?.split(",");

      const getEventsById = dataEvents?.results.filter((event) =>
        eventsId.includes(event._id)
      );

      if (getEventsById) {
        setEventsInFilter(getEventsById);
      }
    }
  }, [dataEvents?.results, events, eventsLength]);

  useEffect(() => {
    reset({
      organizer: organizer,
      events,
      payment_status: paymentStatus ?? "",
      tickets: ticket,
      payment_type: paymentType,
      type,
    });
  }, [events, organizer, paymentStatus, paymentType, reset, ticket, type]);

  const handleAddFilterTransaction: SubmitHandler<
    CreateDataEventFormData
  > = async ({
    price_min,
    price_max,
    organizer,
    events: eventsForm,
    payment_type,
    tickets,
    data_gte,
    data_lte,
    payment_status,
    type,
  }) => {
    if (data_gte && data_lte) {
      searchParams.delete("data_type");
    }

    if (data_gte === "" && data_lte === "") {
      searchParams.delete("data_gte");
      searchParams.delete("data_lte");
    }

    if (!organizer) {
      searchParams.delete("data_type");
    }

    if (!eventsForm) {
      searchParams.delete("events");
    }

    if (!tickets) {
      searchParams.delete("tickets");
    }

    if (!payment_status) {
      searchParams.delete("payment_status");
    }

    if (!type) {
      searchParams.delete("type");
    }

    const dateGte = moment(data_gte, "DD/MM/YYYY");
    const formatDateGte = dateGte.format("YYYY-MM-DD");

    const dateLte = moment(data_lte, "DD/MM/YYYY");
    const formatDateLte = dateLte.format("YYYY-MM-DD");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      ...(!!data_gte &&
        !!data_lte && {
          data_gte: formatDateGte,
          data_lte: formatDateLte,
        }),
      ...(!!price_min &&
        price_max && {
          price_min: String(price_min),
          price_max: String(price_max),
        }),
      ...(!!organizer && { organizer: String(organizer) }),
      ...(!!eventsForm && { events: String(eventsForm) }),
      ...(!!payment_type && { payment_type: String(payment_type) }),
      ...(!!tickets && { tickets: String(tickets) }),

      ...(!!payment_status && { payment_status: String(payment_status) }),
      ...(!!type && { type: String(type) }),
    });

    if (setOpen) {
      setOpen(false);
    }
  };

  const handleClearFilters = () => {
    reset({
      data_gte: "",
      data_lte: "",
      events: "",
      organizer: "",
      payment_type: "",
      payment_status: "",
      price_max: undefined,
      price_min: undefined,
      tickets: "",
      type: "",
    });

    const newParams = searchParams;

    newParams.delete("organizer");
    newParams.delete("events");
    newParams.delete("data_gte");
    newParams.delete("data_lte");
    newParams.delete("payment_type");
    newParams.delete("price_max");
    newParams.delete("price_min");
    newParams.delete("tickets");
    newParams.delete("type");
    newParams.delete("payment_status");

    setSearchParams({
      ...Object.fromEntries([...newParams]),
    });
  };

  const closeOrganizer = () => {
    searchParams.delete("organizer");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });

    reset({
      organizer: "",
    });
  };

  const closeEvents = () => {
    searchParams.delete("events");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });

    reset({
      events: "",
    });
  };

  const dataGte = data_gte !== "" ? moment(data_gte, "YYYY-MM-DD") : "";
  const formattedDataGte = dataGte !== "" ? dataGte.format("DD/MM/YYYY") : "";

  const dataLte = data_gte !== "" ? moment(data_lte, "YYYY-MM-DD") : "";
  const formattedDataLte = dataLte !== "" ? dataLte.format("DD/MM/YYYY") : "";

  const purchaseType = DATA_TYPE_MOCK.find((t) => t.key === type);

  const handleKeyUp = useCallback((e: FormEvent<HTMLInputElement>) => {
    date(e);
  }, []);

  return (
    <ContainerForm onSubmit={handleSubmit(handleAddFilterTransaction)}>
      {filters.map((filter) => {
        if (filter.sortKey === "organizer") {
          return (
            <ContainerFilterDrawer>
              <ContentDrawer>
                <ColumnDrawer>
                  <LabelFilter>Organizador</LabelFilter>

                  <Controller
                    control={control}
                    name="organizer"
                    rules={{ required: "Salutation is required" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <BorderSelect>
                        <SelectStyled
                          size="middle"
                          labelInValue
                          showSearch
                          allowClear
                          bordered={false}
                          placeholder="Insira o nome ou selecione..."
                          value={value}
                          ref={ref}
                          style={{
                            width: "100%",
                          }}
                          onChange={(e: any) => {
                            if (e === undefined) {
                              onChange(null);
                              return;
                            }

                            onChange(e.key);
                          }}
                        >
                          {dataOrganizers?.map((organizer: any) => (
                            <Option value={organizer?._id} key={organizer?._id}>
                              {organizer?.name}
                            </Option>
                          ))}
                        </SelectStyled>
                      </BorderSelect>
                    )}
                  />
                </ColumnDrawer>

                {organizer ? (
                  <BadgeWithClose>
                    <div className="content">
                      <RiContactsLine size={12} color={Colors.secondary70} />

                      <span>{nameOrganizer}</span>
                    </div>

                    <AiFillCloseCircle
                      onClick={closeOrganizer}
                      size={14}
                      color={Colors.secondary70}
                    />
                  </BadgeWithClose>
                ) : null}
              </ContentDrawer>
            </ContainerFilterDrawer>
          );
        }

        if (filter.sortKey === "data") {
          return (
            <ContainerFilterDrawer>
              <ContentDrawer>
                <ColumnDrawer>
                  <LabelFilter>Data</LabelFilter>

                  <InputContainer>
                    <div>
                      <RiCalendar2Line size={18} color={Colors.secondary40} />
                    </div>

                    <input
                      {...register("data_gte")}
                      defaultValue={formattedDataGte}
                      placeholder="Selecione a data inicial"
                      onKeyUp={handleKeyUp}
                    />
                  </InputContainer>

                  <InputContainer>
                    <div>
                      <RiCalendar2Line size={18} color={Colors.secondary40} />
                    </div>

                    <input
                      {...register("data_lte")}
                      defaultValue={formattedDataLte}
                      placeholder="Selecione a data final"
                      onKeyUp={handleKeyUp}
                    />
                  </InputContainer>
                </ColumnDrawer>
              </ContentDrawer>
            </ContainerFilterDrawer>
          );
        }

        if (filter.sortKey === "events") {
          return (
            <ContainerFilterDrawer>
              <ContentDrawer>
                <ColumnDrawer>
                  <LabelFilter>Evento</LabelFilter>

                  <Controller
                    control={control}
                    name="events"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <BorderSelect>
                        <SelectStyled
                          size="middle"
                          labelInValue
                          showSearch
                          allowClear
                          bordered={false}
                          placeholder="Insira o nome ou selecione..."
                          ref={ref}
                          value={value}
                          style={{
                            width: "100%",
                          }}
                          onChange={(e: any) => {
                            if (e === undefined) {
                              onChange(null);
                              return;
                            }

                            onChange(e.key);
                          }}
                        >
                          {dataEvents?.results?.map((event) => (
                            <Option value={event?._id} key={event?._id}>
                              {event.name}
                            </Option>
                          ))}
                        </SelectStyled>
                      </BorderSelect>
                    )}
                  />
                </ColumnDrawer>

                {eventsInFilter ? (
                  <>
                    {eventsInFilter?.map((event) => (
                      <BadgeWithClose>
                        <div className="content">
                          <RiContactsLine
                            size={12}
                            color={Colors.secondary70}
                          />

                          <span>{event?.name}</span>
                        </div>

                        <AiFillCloseCircle
                          onClick={closeEvents}
                          size={14}
                          color={Colors.secondary70}
                        />
                      </BadgeWithClose>
                    ))}
                  </>
                ) : null}
              </ContentDrawer>
            </ContainerFilterDrawer>
          );
        }

        if (filter.sortKey === "tickets") {
          return (
            <ContainerFilterDrawer>
              <ContentDrawer>
                <ColumnDrawer>
                  <LabelFilter>Tipo de ingresso</LabelFilter>

                  <Controller
                    control={control}
                    name="tickets"
                    rules={{ required: "Salutation is required" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <BorderSelect>
                        <SelectStyled
                          size="middle"
                          labelInValue
                          showSearch
                          allowClear
                          bordered={false}
                          disabled={!eventWatch}
                          placeholder="Insira o nome ou selecione..."
                          value={value}
                          ref={ref}
                          style={{
                            width: "100%",
                          }}
                          onChange={(e: any) => {
                            if (e === undefined) {
                              onChange(null);
                              return;
                            }

                            onChange(e.key);
                          }}
                        >
                          {dataTickets?.results?.map((ticket) => (
                            <Option value={ticket?._id} key={ticket._id}>
                              {ticket?.name}
                            </Option>
                          ))}
                        </SelectStyled>
                      </BorderSelect>
                    )}
                  />
                </ColumnDrawer>
              </ContentDrawer>
            </ContainerFilterDrawer>
          );
        }

        if (filter.sortKey === "payment") {
          return (
            <ContainerFilterDrawer>
              <ContentDrawer>
                <ColumnDrawer>
                  <LabelFilter>Forma de pagamento</LabelFilter>

                  <Controller
                    control={control}
                    name="payment_type"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <BorderSelect>
                        <SelectStyled
                          size="middle"
                          labelInValue
                          showSearch
                          allowClear
                          bordered={false}
                          placeholder="Insira o nome ou selecione..."
                          value={value}
                          ref={ref}
                          style={{
                            width: "100%",
                          }}
                          onChange={(e: any) => {
                            if (e === undefined) {
                              onChange(null);
                              return;
                            }

                            onChange(e.key);
                          }}
                        >
                          {dataPayment.map((payment) => (
                            <Option value={payment.key} key={payment.key}>
                              {payment.name}
                            </Option>
                          ))}
                        </SelectStyled>
                      </BorderSelect>
                    )}
                  />
                </ColumnDrawer>
              </ContentDrawer>
            </ContainerFilterDrawer>
          );
        }

        if (filter.sortKey === "type") {
          <ContainerFilterDrawer>
            <ContentDrawer>
              <ColumnDrawer>
                <LabelFilter>Tipo da venda</LabelFilter>

                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value, ref } }) => (
                    <BorderSelect>
                      <SelectStyled
                        size="middle"
                        labelInValue
                        showSearch
                        allowClear
                        bordered={false}
                        placeholder="Insira o tipo ou selecione..."
                        value={value}
                        ref={ref}
                        style={{
                          width: "100%",
                        }}
                        onChange={(e: any) => {
                          if (e === undefined) {
                            onChange(null);
                            return;
                          }

                          onChange(e.key);
                        }}
                      >
                        {DATA_TYPE_MOCK?.map((t) => (
                          <Option value={t.key} key={t.key}>
                            {t.name}
                          </Option>
                        ))}
                      </SelectStyled>
                    </BorderSelect>
                  )}
                />
              </ColumnDrawer>

              {purchaseType ? (
                <BadgeWithClose>
                  <div className="content">
                    <RiContactsLine size={12} color={Colors.secondary70} />

                    <span>{purchaseType.name}</span>
                  </div>

                  <AiFillCloseCircle
                    onClick={closeOrganizer}
                    size={14}
                    color={Colors.secondary70}
                  />
                </BadgeWithClose>
              ) : null}
            </ContentDrawer>
          </ContainerFilterDrawer>;
        }

        if (filter.sortKey === "payment_status") {
          return (
            <ContainerFilterDrawer>
              <ContentDrawer>
                <ColumnDrawer>
                  <LabelFilter>Status pagamento</LabelFilter>

                  <Controller
                    control={control}
                    name="payment_status"
                    rules={{ required: "Salutation is required" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <BorderSelect>
                        <SelectStyled
                          size="middle"
                          labelInValue
                          showSearch
                          allowClear
                          bordered={false}
                          placeholder="Insira o nome ou selecione..."
                          value={value}
                          ref={ref}
                          style={{
                            width: "100%",
                          }}
                          onChange={(e: any) => {
                            if (e === undefined) {
                              onChange(null);
                              return;
                            }

                            onChange(e.key);
                          }}
                        >
                          {dataStatus.map((payment) => (
                            <Option value={payment.key} key={payment.key}>
                              {payment.name}
                            </Option>
                          ))}
                        </SelectStyled>
                      </BorderSelect>
                    )}
                  />
                </ColumnDrawer>
              </ContentDrawer>
            </ContainerFilterDrawer>
          );
        }

        return null;
      })}

      <ContainerDrawer>
        <div className="buttons-drawer">
          <button type="button" className="first" onClick={handleClearFilters}>
            Limpar
          </button>

          <button type="submit" className="second">
            Aplicar
          </button>
        </div>
      </ContainerDrawer>
    </ContainerForm>
  );
}
