import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.81rem;

  padding-right: 4rem;
  padding-left: 4rem;

  margin-top: 4.81rem;

  div.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.93rem;
  }

  h2 {
    font-family: Open Sans;
    font-size: 26px;
    font-weight: 700;
    line-height: 41.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: ${Colors.gray10};
  }

  h3 {
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 400;
    line-height: 35.2px;
    text-align: center;
    color: ${Colors.gray20};
    white-space: normal;
    word-wrap: break-word;
  }

  button {
    cursor: pointer;
    outline: 0;
    border: 0;
    width: 473px;
    height: 60px;
    top: 528px;
    left: 293px;
    gap: 0px;
    border-radius: 14px;
    opacity: 0px;
    background-color: ${Colors.primary10};

    @media (max-width: 1024px) {
      width: 210px;
    }

    span {
      font-family: Cairo;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: ${Colors.primary100};
    }
  }
`;
