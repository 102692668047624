import { Colors } from "constants/colors";
import { Modal } from "antd";
import styled from "styled-components";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const TitleTransactions = styled.h4`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 2.34rem;
  margin-bottom: 0px;

  @media (max-width: 670px) {
    display: none;
  }
`;

export const TitleTransactionsResponsive = styled.h5`
  color: ${Colors.gray20};
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 22px;
  margin-bottom: 0px;

  @media (min-width: 670px) {
    display: none;
  }
`;

export const TitleHistory = styled.h5`
  color: ${Colors.gray20};
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 1.31rem;
  margin-bottom: 0px;

  @media (min-width: 670px) {
    display: none;
  }
`;

export const ButtonReport = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 12.81rem;
  height: 2.81rem;

  background: ${Colors.primary10};
  border-radius: 0.65rem;

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-bottom: 0px;

    color: ${Colors.primary100};
    margin-left: 10px;
  }

  img {
    width: 1.12rem;
    height: 1.12rem;
  }
`;

export const ContainerTabs = styled.div`
  width: 100%;

  .ant-tabs-tab {
    font-size: 1.03rem;
    font-family: "Poppins";
    font-weight: normal;
    margin-bottom: 0px;
    color: ${Colors.black70};
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-active {
    color: ${Colors.primary100} !important;
    font-weight: 700;
    font-size: 1.03rem;
    margin-bottom: 0px;
    color: ${Colors.primary100};
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-btn {
    font-family: "Poppins";
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: "Poppins";
    color: ${Colors.primary100} !important;
  }

  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-tab {
    display: block;
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }

  .ant-tabs-tab-active {
    color: ${Colors.primary100} !important;
  }
  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.primary100} !important;
    font-weight: 500;
  }

  @media (max-width: 800px) {
    .ant-tabs-tab {
      font-family: "Poppins";
      font-weight: normal;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;

      color: ${Colors.black70};
    }

    .ant-tabs-tab-active {
      color: ${Colors.primary100} !important;

      font-weight: bold;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;

      color: ${Colors.primary100};
    }

    .ant-tabs-ink-bar {
      color: ${Colors.primary100};
      background: ${Colors.primary100};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${Colors.primary100} !important;
      font-weight: 500;
    }

    .ant-tabs-nav {
      width: 100% !important;
    }

    .ant-tabs-tab {
      display: block;
      flex: 1;
      text-align: center;
    }

    .ant-tabs-nav > div:nth-of-type(1) {
      display: unset !important;
      width: 100% !important;
    }

    .ant-tabs-tab-active {
      color: ${Colors.primary100} !important;
    }
    .ant-tabs-ink-bar {
      color: ${Colors.primary100};
      background: ${Colors.primary100};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${Colors.primary100} !important;
      font-weight: 500;
    }
  }
`;

export const HiddenResponsive = styled.div`
  @media (max-width: 670px) {
    display: none;
  }
`;

interface CardAccordionProps {
  active?: boolean;
}

export const CardAccordion = styled.div<CardAccordionProps>`
  cursor: pointer;
  width: 100%;
  height: 5.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 1.68rem;

  background: ${(props) => (props.active ? Colors.black5 : Colors.white)};

  z-index: 2;

  border: 1px solid ${Colors.border50};
  box-shadow: 0px 4px 20px rgba(18, 25, 34, 0.05);
  border-radius: 0.93rem;

  padding: 0.89rem 1.92rem 1.17rem 1.96rem;

  @media (max-width: 800px) {
    height: 49px;
    padding: 7px 21px 3px 19px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 5px;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  div.content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    div.image {
      margin-top: 2px;

      @media (max-width: 800px) {
        margin-top: 5px;
      }
    }

    div.texts {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      /* margin-left: 23px; */

      h2 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.03rem;

        margin-bottom: 0px;

        color: ${Colors.secondary100};

        @media (max-width: 800px) {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;

          color: ${Colors.black100};
        }
      }

      p {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        margin-bottom: 0px;

        color: ${Colors.secondary40};

        @media (max-width: 800px) {
          font-weight: bold;
          font-size: 10px;
          line-height: 19px;

          color: ${Colors.secondary40};
        }
      }
    }
  }
`;

export const DataDropDown = styled.div`
  display: block;
  position: relative;

  width: 100%;

  z-index: 1;
  margin-top: -35px;

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;
      }
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  min-height: 5.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${Colors.border50};
  border-top: 0;
  box-sizing: border-box;
  border-radius: 20px;
  padding-right: 20px;
  padding-bottom: 0.98rem;

  @media (max-width: 800px) {
    display: none;
  }

  div.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 70px;
    padding-left: 30px;
    width: 100%;

    div.column {
      display: flex;
      flex-direction: column;

      h2 {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        line-height: 160%;
        color: ${Colors.black80};
      }

      p {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 0.84rem;
        margin-bottom: 0px;
        color: ${Colors.black70};
      }
    }
  }

  div.container-image {
    margin-top: 40px;

    @media (max-width: 1200px) {
      padding-left: 10px;
    }

    img {
      border-radius: 10px;
    }
  }
`;

export const ContainerBorderMobile = styled.div`
  width: 100%;
  min-height: 180px;
  display: flex;
  flex-direction: column;

  border: 1px solid ${Colors.border50};
  border-top: 0;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  margin-top: 15px;
  padding: 17px 15px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const RowMobileFirst = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const RowMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  h6.row-margin-right {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
    margin-right: 4px;
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const RowMobileBorder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  padding-bottom: 12px;

  border-bottom: 1px solid ${Colors.secondary10};

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  margin-top: 30px;

  a {
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-top: 12px;
    margin-bottom: 22px;
  }
`;

export const ButtonDownload = styled.button`
  width: 100%;
  height: 2.81rem;

  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${Colors.white20};
  border-radius: 14px;
  margin-right: 10px;

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.secondary100};
  }
`;

export const ButtonPrint = styled.button`
  width: 12.28rem;
  height: 2.81rem;

  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 14px;

  background: ${Colors.white20};

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;

    div.text {
      display: none;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.red10};
  }
`;

export const ContainerTransactions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const FooterItems = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 38px;

  @media (min-width: 800px) {
    display: none;
  }

  h3 {
    font-weight: 600;
    font-size: 11px;
    line-height: 21px;
    color: ${Colors.black50};
  }
`;

export const ButtonMoreItems = styled.button`
  width: 100%;
  height: 38px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 34px;

  :disabled {
    opacity: 0.6;
  }

  border: 0;
  background: ${Colors.primary100};
  border-radius: 8px;

  span {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.white};
  }
`;

export const DropDown = styled.div`
  display: block;
  position: absolute;
  background-color: ${Colors.white};
  width: 179px;
  height: 123px;

  z-index: 1;
  position: absolute;
  margin-top: -5px;

  border: 1px solid ${Colors.black10};
  border-radius: 0px 0px 14px 14px;
  border-radius: 0px 0px 14px 14px;
`;
