import { useCallback } from "react";

import { useSearchParams } from "react-router-dom";

import { Checkbox } from "antd";

import { RiCloseCircleLine } from "react-icons/ri";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";

import {
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  NormalCardBorder,
} from "./styles";

export const DATA_TYPE_MOCK = [
  { id: 1, name: "Checkout Eventx", key: "eventx-checkout" },
  { id: 2, name: "Venda Manual", key: "manual" },
  { id: 3, name: "Importada de Planilha", key: "import" },
  { id: 5, name: "Webhook Xgrow", key: "xgrow" },
];

export function ModalFilterByPurchaseType() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isOpenModalFilterPurchaseType, setIsOpenModalFilterPurchaseType } =
    useFiltersContext();

  const closeModal = useCallback(() => {
    setIsOpenModalFilterPurchaseType(false);
  }, [setIsOpenModalFilterPurchaseType]);

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        type: value,
      });

      closeModal();
    },
    [closeModal, searchParams, setSearchParams]
  );

  return (
    <ModalStyled
      width={857}
      visible={isOpenModalFilterPurchaseType}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Filtrar por tipo de venda</h1>

        <h2>
          Selecione um tipo de venda para a visualização dos
          resultados.
        </h2>

        <ContainerSelect>
          <Checkbox.Group style={{ width: "100%" }} defaultValue={["Pear"]}>
            {DATA_TYPE_MOCK?.map((type) => (
              <NormalCardBorder
                onClick={() => handleSelectedIdFilter(type.key)}
              >
                <h3 className="normal">{type?.name}</h3>
              </NormalCardBorder>
            ))}
          </Checkbox.Group>
        </ContainerSelect>
      </ContainerModal>
    </ModalStyled>
  );
}
