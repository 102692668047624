import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { VscChevronDown, VscChevronUp } from "react-icons/vsc";
import { ButtonAccount, AccountDropDown } from "../../styles";
import { useOutsideClick } from "hooks/useOutsideClick";

interface AccountDropdownProps {
  user: any;
  signOut: () => void;
}

function AccountDropdown({ user, signOut }: AccountDropdownProps) {
  const [active, setActive] = useState(false);

  const ref = useOutsideClick(() => {
    setActive(false);
  });

  return (
    <div className="account-container" ref={ref}>
      <ButtonAccount active={active} onClick={() => setActive(!active)}>
        <AiOutlineUser size={20} />
        Minha conta
        {active ? <VscChevronUp size={20} /> : <VscChevronDown size={20} />}
      </ButtonAccount>

      {active && (
        <AccountDropDown userLogged={!!user}>
          <ul>
            <Link to="/profile/personal-data">
              <li>
                <span>Meu Perfil</span>
              </li>
            </Link>
            <Link to="/profile/my-shopping">
              <li>
                <span>Compras</span>
              </li>
            </Link>
            <Link to="/profile/my-ticket">
              <li>
                <span>Ingressos</span>
              </li>
            </Link>
            <Link to="/faq">
              <li>
                <span>Suporte</span>
              </li>
            </Link>
            <li onClick={signOut} className="exit">
              <span>Sair</span>
            </li>
          </ul>
        </AccountDropDown>
      )}
    </div>
  );
}

export default AccountDropdown;
