import { Modal } from "antd";
import { Colors } from "constants/colors";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 22px;

  > h1 {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.6rem;
    color: ${Colors.black100};
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const AddTeamButtonMobile = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  outline: none;
  border: none;

  width: 100%;
  background: ${Colors.primary10};

  border-radius: 8px;
  padding: 10px 0;

  font-family: "Cairo";
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1.25rem;
  color: ${Colors.primary100};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  padding: calc(100px + 70px) 38px;
  background: ${Colors.white};

  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: calc(70px) 24px;
    row-gap: 24px;
  }
`;

export const ResourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  margin-top: 50px;

  @media (max-width: 1024px) {
    margin-top: 0;

    > .pagination {
      display: none;
    }
  }
`;

export const ResourceHeaderBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 2rem;
    line-height: 3.2rem;
    color: ${Colors.black100};
  }

  @media (max-width: 1024px) {
    > span {
      font-size: 1.25rem;
      line-height: 1.6rem;
    }
  }
`;

export const ResourceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .content {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 2rem;
    line-height: 3.2rem;
    color: ${Colors.black100};
  }

  @media (max-width: 1024px) {
    span {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    svg {
      display: none;
    }
  }
`;

export const ResourceList = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
`;

export const ResourceCard = styled.div<{ color: string }>`
  max-height: 120px;
  height: 120px;
  background: ${Colors.white};
  padding: 22px 20px 22px 12px;

  border: 1px solid #f0f0f0;
  border-radius: 14px;
  display: flex;
  align-items: center;
  column-gap: 22px;

  .info-wrapper {
    display: flex;
    flex-direction: column;
  }

  .info-container {
    display: flex;
    align-items: center;
  }

  span {
    flex-shrink: 1;

    font-family: "Open Sans";
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 700;
    color: ${({ color }) => color};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.6rem;
    color: ${Colors.black30};
  }

  b {
    font-family: "Cairo";
    font-size: 1.625rem;
    font-weight: 700;
    color: ${Colors.black100};
  }

  .link-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
    margin: 0 0 0 auto;
  }

  position: relative;

  @media (max-width: 1024px) {
    max-height: 160px;
    height: 100%;
    max-width: 160px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;

    > .link-icon {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    span {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const ResourceIcon = styled.div<{ color: string }>`
  padding: 10px;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background: ${({ color }) => color};
`;

export const AddTeamLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  width: 100%;
  max-width: 265px;
  background: ${Colors.primary100};
  cursor: pointer;

  border-radius: 14px;
  padding: 14px 0;
  box-shadow: 0px 15px 30px 0px #d4005026;

  font-family: "Cairo";
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${Colors.white};

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SettingsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  outline: none;

  width: 100%;
  max-width: 265px;
  background: #f4f3f4;
  cursor: pointer;

  border-radius: 14px;
  padding: 14px 0;

  font-family: "Cairo";
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${Colors.secondary100};

  &:hover {
    opacity: 0.7;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const TeamsMobileTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  @media (min-width: 1024px) {
    display: none;
  }
`;
