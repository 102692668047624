import { KeySort } from "constants/filters";
import { HeaderFilters } from "./styles";

import { SelectOrganizerFilter } from "components/FiltersSelect/SelectOrganizerFilter";
import { SelectDataFilter } from "components/FiltersSelect/SelectDataFilter";
import { SelectEventFilter } from "components/FiltersSelect/SelectEventFilter";
import { SelectTicketFilter } from "components/FiltersSelect/SelectTicketFilter";
import { SelectTypePaymentFilter } from "components/FiltersSelect/SelectTypePaymentFilter";
import { SelectPriceFilter } from "components/FiltersSelect/SelectPriceFilter";
import { SelectPaymentStatusFilter } from "components/FiltersSelect/SelectPaymentStatusFilter";
import { SelectPurchaseTypeFilter } from "components/FiltersSelect/SelectPurchaseTypeFilter";

interface Filter {
  title: string;
  sortKey: KeySort;
}

interface HeaderFiltersEventProps {
  filters: Filter[];
}

export function HeaderFilter({ filters }: HeaderFiltersEventProps) {
  return (
    <HeaderFilters>
      <h3 className="filter">Filtrar por:</h3>

      <div className="filters">
        {filters.map((filter) => {
          if (filter.sortKey === "organizer") {
            return (
              <SelectOrganizerFilter
                title={filter.title}
                sortKey={filter.sortKey}
              />
            );
          }

          if (filter.sortKey === "data") {
            return (
              <SelectDataFilter title={filter.title} sortKey={filter.sortKey} />
            );
          }

          if (filter.sortKey === "events") {
            return (
              <SelectEventFilter
                title={filter.title}
                sortKey={filter.sortKey}
                onClearEvents={() => {}}
              />
            );
          }

          if (filter.sortKey === "tickets") {
            return (
              <SelectTicketFilter
                title={filter.title}
                sortKey={filter.sortKey}
              />
            );
          }

          if (filter.sortKey === "payment") {
            return (
              <SelectTypePaymentFilter
                title={filter.title}
                sortKey={filter.sortKey}
              />
            );
          }

          if (filter.sortKey === "price") {
            return (
              <SelectPriceFilter
                title={filter.title}
                sortKey={filter.sortKey}
              />
            );
          }

          if (filter.sortKey === "payment_status") {
            return (
              <SelectPaymentStatusFilter
                title={filter.title}
                sortKey={filter.sortKey}
              />
            );
          }

          if (filter.sortKey === "type") {
            return (
              <SelectPurchaseTypeFilter
                title={filter.title}
                sortKey={filter.sortKey}
              />
            );
          }

          return null;
        })}
      </div>
    </HeaderFilters>
  );
}
