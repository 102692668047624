import { authClient } from "services/authClient";
import { UserTicketResponse } from "./types";

import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

export async function getUserTicket(ticket_code: string) {
  const endpoint = `/user-ticket/query?code=${ticket_code}`;

  const response = await authClient.get<UserTicketResponse>(endpoint, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const formatedCheckinAt =
  response.data?.checkin && response.data?.checkin_at
    ? format(parseISO(response?.data?.checkin_at), "dd/MM/yyyy HH:mm", {
        locale: ptBR,
      })
    : "";

  const formatedCheckouAt =
  response.data?.checkout && response.data?.checkout_at
    ? format(parseISO(response.data?.checkout_at), "dd/MM/yyyy HH:mm", {
        locale: ptBR,
      })
    : "";

  return {
    ...response.data,
    formatedCheckinAt,
    formatedCheckouAt,
  };
}

export async function makeCheckin(id: string) {
  const endpoint = `/user-ticket/checkin/${id}`;

  return authClient.patch(endpoint);
}

export async function makeCheckout(id: string) {
  const endpoint = `/user-ticket/checkout/${id}`;

  return authClient.patch(endpoint);
}
