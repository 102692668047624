import { useEffect, useState } from "react";

import { RiCalendar2Line, RiMapPinLine, RiTimeLine } from "react-icons/ri";

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

import { useSearchUserTicketChart } from "hooks/useSearchUserTicketChart";
import { Colors } from "constants/colors";

import { Event } from "types";

import { formatDateVanilla } from "utils/formatDateVanilla";
import { extractEventHour } from "utils/extractEventHour";

import {
  Content,
  RowTexts,
  TitleRow,
  TitleStrongRow,
  Status,
  Title,
  Divider,
  ContainerBottom,
  TitleBottom,
  ContainerGraphs,
  ContainerBorder,
  Graph,
  RowTextsCenter,
  ContainerResponsiveBorder,
  ContainerBottomButtons,
} from "./styles";

import {
  ButtonMakeCheckinOut,
} from "pages/CheckInOutNew/styles";

import { DrawerCheckin } from "pages/CheckInOutNew/components/DrawerCheckin";

import event_available from "assets/qr_code.svg";

interface CheckInProps {
  event: Event;
}

export function CheckIn({ event }: CheckInProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [checkinPercent, setCheckinPercent] = useState({
    carried: 0,
    remaining: 0
  })

  const { data } = useSearchUserTicketChart()

  const series1 = [checkinPercent.carried];

  const series2 = [checkinPercent.remaining];

  const [chartCarriedOptions, setChartCarriedOptions] = useState<ApexOptions>({
    chart: {
      height: 350,
      type: "radialBar",
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 180,
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "10px",
                  offsetY: 100,
                },
                value: {
                  fontSize: "14px",
                  offsetY: 60,
                },
                total: {
                  fontSize: "14px",
                  offsetY: 50,
                },
              },
            },
          },
        },
      },
    ],
    fill: {
      type: "gradient",
      colors: [Colors.primary100],
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      width: 0.3,
    },
    labels: ["Median Ratio"],
  })

  const [chartRemainingOptions, setChartRemainingOptions] = useState<ApexOptions>({
    chart: {
      height: 350,
      type: "radialBar",
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 180,
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "10px",
                  offsetY: 100,
                },
                value: {
                  fontSize: "14px",
                  offsetY: 60,
                },
                total: {
                  fontSize: "14px",
                  offsetY: 50,
                },
              },
            },
          },
        },
      },
    ],
    fill: {
      type: "gradient",
      colors: [Colors.secondary100],
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      width: 0.3,
    },
    labels: ["Median Ratio"],
  })

  useEffect(() => {
    const findChartTicketByEventId = data?.find(ticketChart => ticketChart?.event_id === event?._id)

    if(findChartTicketByEventId){
      setCheckinPercent({
        carried: findChartTicketByEventId?.checkin_percentage,
        remaining: 100 - findChartTicketByEventId?.checkin_percentage
      })

      setChartCarriedOptions((prevOptions) => ({
        ...prevOptions,
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            track: {
              strokeWidth: "37%",
            },
            hollow: {
              size: "70%",
              imageWidth: 90,
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: "22px",
                color: undefined,
              },
              total: {
                show: true,
                label: "Realizados",
                color: "#000",
                fontSize: "20px",
                formatter: function() {
                  return `${findChartTicketByEventId?.checkin_percentage}%`;
                }
              },
            },
          },
        },
      }));

      setChartRemainingOptions((prevOptions) => ({
        ...prevOptions,
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            track: {
              strokeWidth: "37%",
            },
            hollow: {
              size: "70%",
              imageWidth: 90,
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: "22px",
                color: undefined,
              },
              total: {
                show: true,
                label: "Restantes",
                color: "#000",
                fontSize: "20px",
                formatter: function() {
                  return `${100 - findChartTicketByEventId?.checkin_percentage}%`;
                }
              },
            },
          },
        },
      }));
    }
  },[data, event?._id])

  const handleOpen = () => {
    setIsVisible(true);
  };

  return (
    <div>
      <ContainerBorder>
        <ContainerResponsiveBorder>
          <Title>{event?.name}</Title>

          <Content>
            <RowTexts>
              <Status />

              <TitleStrongRow>Publicado</TitleStrongRow>
            </RowTexts>

            <RowTexts>
              <RiMapPinLine size={18} color={Colors.primary100} />

              <TitleRow>
                {event?.address_name ? `${event?.address_name} |` : ""}{" "}
                {event?.address_street}, {event?.address_number} -{" "}
                {event?.address_complement}
                {event?.address_district}, {event?.address_city} -{" "}
                {event?.address_state}, {event?.address_zipcode}
              </TitleRow>
            </RowTexts>

            <RowTexts>
              <RiCalendar2Line size={18} color={Colors.primary100} />

              <TitleRow>
                {formatDateVanilla(event.begin_date)},{" "}
                {extractEventHour(event.begin_hour)}
              </TitleRow>
            </RowTexts>
          </Content>
        </ContainerResponsiveBorder>

        <ContainerBottom>
          <RowTextsCenter>
            <RiTimeLine size={16} color={Colors.black50} />

            <TitleBottom>Atualizado em 21/03/21 - às 13:20h</TitleBottom>
          </RowTextsCenter>

          <ContainerGraphs>
            <Graph>
              <ReactApexChart
                options={chartCarriedOptions}
                series={series1}
                type="radialBar"
                height={350}
              />
            </Graph>

            <Graph>
              <ReactApexChart
                options={chartRemainingOptions}
                series={series2}
                type="radialBar"
                height={350}
              />
            </Graph>
          </ContainerGraphs>

          <ContainerBottomButtons>
            <div className="divider" />

            <ButtonMakeCheckinOut type="button" onClick={handleOpen}>
              <img
                src={event_available}
                width={18}
                height={18}
                alt="event available"
              />

              <span>Realizar check-in</span>
            </ButtonMakeCheckinOut>
          </ContainerBottomButtons>
        </ContainerBottom>
      </ContainerBorder>

      <Divider />

      <DrawerCheckin isVisible={isVisible} setIsVisible={setIsVisible} />
    </div>
  );
}
