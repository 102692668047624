import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  width: 100%;
  max-width: 370px;

  .error {
    margin-left: 4px;
    color: ${Colors.error};
    font-family: "Poppins";
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Label = styled.span`
  color: ${Colors.secondary40};
  font-family: "Poppins";
  font-size: 14px;
  line-height: 18px;
`;

export const Wrapper = styled.div<{ hasIcon?: boolean; hasValue?: boolean }>`
  position: relative;

  .icon {
    position: absolute;
    top: auto;
    bottom: auto;
    left: 20px;
    color: ${Colors.secondary20};
    z-index: 100;
    font-size: 18px;
    margin: 14px 0;

    ${({ hasValue = false }) =>
      hasValue &&
      css`
        display: none !important;
      `}
  }

  .ant-select-selection-placeholder {
    ${({ hasIcon = false }) =>
      hasIcon &&
      css`
        padding: 0 24px !important;
      `}
    ${({ hasValue = false }) =>
      hasValue &&
      css`
        display: none !important;
      `}
  }

  .ant-select {
    height: 50px;
    background: ${Colors.white};
    width: 100%;
    box-shadow: none !important;
  }

  .ant-select-selector {
    height: 50px !important;
    border: 1px solid ${Colors.secondary20} !important;
    border-radius: 8px !important;
    width: 100%;
    background: ${Colors.white};

    padding: 0 20px !important;
    display: flex !important;
    align-items: center !important;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center !important;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 18px;
    line-height: 32px;
    text-align: left;

    ${({ hasValue = false }) =>
      hasValue &&
      css`
        padding: 0 0 !important;
      `}
    ${({ hasValue = false }) =>
      hasValue &&
      css`
        padding: 0 0 !important;
      `}
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  &:not(:first-child) {
    border: 1px solid ${Colors.black};
    margin: 10px 0;
  }

  .value {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
`;
