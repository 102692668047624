import cancel_confirm from "assets/cancel-confirm.svg";
import { ButtonClose, ButtonConfirm, ContainerModalConfirm, ModalStyled } from "./styles";

interface ModalConfirmDeletionProps {
  handleCancel: () => void;
  handleOk: () => void;
  isModalVisibleConfirm: boolean;
  fetchingDelete: boolean;
  title: string;
}

function ModalConfirmDeletion({ title, fetchingDelete, handleCancel, handleOk, isModalVisibleConfirm }: ModalConfirmDeletionProps) {
  return (
    <ModalStyled
      width={642}
      visible={isModalVisibleConfirm}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={null}
    >
      <ContainerModalConfirm>
        <img
          src={cancel_confirm}
          width={189}
          height={189}
          alt="confirm-cancel"
        />

        <div className="texts-confirm">
          <h3>{title}</h3>

          <p>
            Esta ação é irreversível, tem certeza?
          </p>
        </div>

        <div className="buttons">
          <ButtonClose onClick={handleCancel}>
            <span>Voltar</span>
          </ButtonClose>

          <ButtonConfirm
            disabled={fetchingDelete}
            onClick={handleOk}
          >
            <span>Sim</span>
          </ButtonConfirm>
        </div>
      </ContainerModalConfirm>
    </ModalStyled>
  );
}

export default ModalConfirmDeletion;
