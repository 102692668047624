import { Box, DropDownEvent } from "./styles";
import { Colors } from "constants/colors";
import { useOutsideClick } from "hooks/useOutsideClick";
import { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";

interface LinkType {
  type: string;
  title: string;
  image?: string;
  link?: string;
  onClick?(): void;
}

interface DropDownProps {
  links: LinkType[];
}

export function DropDown({ links }: DropDownProps) {
  const [hasOpen, setHasOpen] = useState(false);

  const ref = useOutsideClick(() => {
    setHasOpen(false);
  });

  return (
    <div className="div-icon-three" ref={ref}>
      <Box active={hasOpen} onClick={() => setHasOpen(!hasOpen)}>
        <BsThreeDots
          style={{ cursor: "pointer" }}
          size={22}
          color={Colors.black100}
        />
      </Box>

      {hasOpen ? (
        <DropDownEvent>
          <ul>
            {links.map((link) => (
              <>
                {link.type === "link" ? (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={link?.link ?? ""}
                  >
                    <li>
                      {link?.image ? (
                        <div className="circle-icon">
                          <img src={link?.image} alt="eyes" />
                        </div>
                      ) : null}

                      <span>{link?.title}</span>
                    </li>
                  </Link>
                ) : null}

                {link.type === "blank" ? (
                  <a
                    style={{ textDecoration: "none" }}
                    href={link.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li>
                      <div className="circle-icon">
                        <img src={link.image} alt="eyes" />
                      </div>
                      <span>Visualizar Link do Checkout</span>
                    </li>
                  </a>
                ) : null}

                {link.type === "action" ? (
                  <li onClick={link?.onClick}>
                    <div className="circle-icon">
                      <img src={link?.image} alt="duplicate" />
                    </div>

                    <span>{link?.title}</span>
                  </li>
                ) : null}
              </>
            ))}
          </ul>
        </DropDownEvent>
      ) : null}
    </div>
  );
}
