import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerQr = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;

  @media (max-width: 1024px) {
    margin-top: 0px;
    align-items: flex-start;
  }
`;

export const ContainerCamera = styled.div`
  height: 311px;
  width: 100%;
  max-width: 35.43rem;

  border-radius: 8px;
  background-color: ${Colors.border20};

  @media (max-width: 1024px) {
    height: 361px;
    border-radius: 0px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;

    @media(max-width: 600px){
      height: 361px;
    }
  }

  .scan-region-highlight {
    border: 1.5px solid ${Colors.white} !important;
  }
`;

export const DivLastRead = styled.div`
  width: 100%;

  min-height: 2.34rem;
  border-radius: 0.187rem;
  border: 1px solid ${Colors.border20};

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 0.56rem;
  margin-top: 1.12rem;

  @media(max-width: 800px){
    margin-top: 0px;
  }

  h3 {
    font-family: Open Sans;
    font-size: 0.75rem;
    font-weight: 400;
    text-align: left;
    color: ${Colors.black50};
    margin-bottom: 0px;
    margin-left: 0.56rem;
  }
`;

export const StatusCheckContainer = styled.div`
  width: 100%;
  // height: 5.57rem;
  margin-top: 1.12rem;
  display: flex;
  flex-direction: column;
  gap: 0.37rem;
  align-items: center;

  h1.title {
    font-family: Cairo;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: ${Colors.secondary100};
  }

  h3 {
   font-family: Open Sans;
   font-size: 0.93rem;
   font-weight: 400;
   text-align: center;
   color: ${Colors.black90};
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.37rem;
  margin-top: 0.37rem;
  padding: 0.75rem;

  border: 1px solid ${Colors.secondary10};
  border-radius: 0.18rem;

  strong {
    font-family: Open Sans;
    font-size: 0.75rem;
    font-weight: 700;
    text-align: left;
    color: ${Colors.black100};
    margin-bottom: 0px;
  }

  h4 {
    font-family: Open Sans;
    font-size: 0.65rem;
    font-weight: 400;
    text-align: left;
    color: ${Colors.black50};
    margin-bottom: 0px;
  }
`;

export const ContainerFooterButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ButtonCodeProps {
  active?: boolean;
}

export const ButtonCode = styled.button<ButtonCodeProps>`
  outline: none;
  border: 0;
  cursor: pointer;

  width: 14.25rem;
  height: 2.81rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.65rem;
  margin-top: 1.5rem;
  background: ${props => props.active ? Colors.primary10 : "none"};

  span {
    font-family: Cairo;
    font-size: 0.93rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
    color: ${props => props.active ? Colors.primary100 : Colors.black30};
  }
`;
