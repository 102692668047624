/* eslint-disable react-hooks/rules-of-hooks */
import { memo } from "react";

import { RiCloseCircleLine } from "react-icons/ri";

import { Purchase, Transaction } from "types";
import { Colors } from "constants/colors";

import { ConfirmReverse } from "components/ConfirmReverse";

import { ModalStyled } from "./styles";

interface ModalConfirmReverseProps {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
  showModalConfirm(): void;
  showModalError(): void;
  purchase: Purchase | Transaction | null;
}

export function ModalConfirmReverseComponent({
  isModalVisible,
  handleOk,
  handleCancel,
  showModalConfirm,
  showModalError,
  purchase,
}: ModalConfirmReverseProps) {
  return (
    <ModalStyled
      width={900}
      visible={isModalVisible}
      onOk={handleOk}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      onCancel={handleCancel}
      footer={null}
    >
      <ConfirmReverse
        purchase={purchase}
        showModalConfirm={showModalConfirm}
        showModalError={showModalError}
      />
    </ModalStyled>
  );
}

export const ModalConfirmReverse = memo(ModalConfirmReverseComponent);
