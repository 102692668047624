import { RiCloseCircleLine, RiCoupon2Line } from "react-icons/ri";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";


import { Colors } from "constants/colors";

import {
  AllInput,
  ButtonProceed,
  Container,
  ContainerButtonProceed,
  ContainerIcon,
  ContentManualCheckIn,
  InputContainer,
  ModalStyled,
} from "./styles";

import ManualReport from "assets/manual_report.svg";


const manualCheckinOutFormSchema = yup.object().shape({
  code: yup.string().required("Código obrigatório"),
})

type ManualCheckinOut = {
  code: string;
}

interface ModalManualCheckinOutProps {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
  getTicketCode(code: string): void;
}

export function ModalManualCheckinOut({isModalVisible, handleOk, handleCancel, getTicketCode}: ModalManualCheckinOutProps) {
    const {
      register,
      formState: { errors },
      handleSubmit,
      reset
    } = useForm<ManualCheckinOut>({
      resolver: yupResolver(manualCheckinOutFormSchema),
    });


    const handleManualCheckinOut: SubmitHandler<ManualCheckinOut> = async ({ code }) => {
      getTicketCode(code)

      handleOk()

      reset({ code: "" })
    }

  return (
    <ModalStyled
      width={857}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          size={43}
          color={Colors.black40}
          style={{ marginTop: 30, marginRight: 60 }}
        />
      }
      footer={null}
    >
    <Container onSubmit={handleSubmit(handleManualCheckinOut)}>
      <ContainerIcon>
        <img src={ManualReport} width={78} height={78} alt="" />
      </ContainerIcon>

      <ContentManualCheckIn>
        <h3>Check-in manual</h3>

        <span>Insira o código do ingresso abaixo.</span>
      </ContentManualCheckIn>

      <AllInput>
        <InputContainer>
          <div>
            <RiCoupon2Line size={23} color={Colors.secondary40} />
          </div>

          <input
            placeholder="Código do ingresso"
            {...register("code")}
          />
        </InputContainer>

        {errors.code && <span>{errors?.code?.message}</span>}
      </AllInput>

      <ContainerButtonProceed>
        <ButtonProceed type="submit">
          <span>Prosseguir</span>
        </ButtonProceed>
      </ContainerButtonProceed>
    </Container>
  </ModalStyled>
  );
}
