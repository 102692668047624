import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RiCalendar2Line } from "react-icons/ri";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
import { ButtonOrganizer, AccountDropDownOrganizer } from "../../styles";
import { Colors } from "constants/colors";
import { useOutsideClick } from "hooks/useOutsideClick";

interface OrganizerDropdownProps {
  organizerId?: string;
  canCheckInOut?: boolean;
}

export const OrganizerDropdown: React.FC<OrganizerDropdownProps> = ({
  organizerId,
  canCheckInOut = false,
}) => {
  const [active, setActive] = useState(false);

  const ref = useOutsideClick(() => {
    setActive(false);
  });

  return (
    <div className="account-container" ref={ref}>
      <ButtonOrganizer active={active} onClick={() => setActive(!active)}>
        <RiCalendar2Line size={20} color={Colors.primary100} />
        Organizador
        {active ? (
          <IoChevronUpSharp size={20} />
        ) : (
          <IoChevronDownSharp size={20} />
        )}
      </ButtonOrganizer>

      {active && (
        <AccountDropDownOrganizer>
          <ul>
            {Boolean(organizerId) && (
              <>
                <Link to={`/organizer/profile/main/${organizerId}`}>
                  <li>
                    <span>Perfil</span>
                  </li>
                </Link>
                <Link to="/my-event">
                  <li>
                    <span>Meus eventos</span>
                  </li>
                </Link>
                <Link to="/financial">
                  <li>
                    <span>Financeiro</span>
                  </li>
                </Link>
                <Link to="/ticket-office">
                  <li>
                    <span>Bilheteria</span>
                  </li>
                </Link>
              </>
            )}

            {canCheckInOut ? (
              <Link to="/organizer/ticket-check">
                <li aria-label="button">
                  <span>Ticket check-in</span>
                </li>
              </Link>
            ) : null}

            <Link to="/teams">
              <li aria-label="button" className="last">
                <span>Meus times</span>
              </li>
            </Link>
          </ul>
        </AccountDropDownOrganizer>
      )}
    </div>
  );
};
