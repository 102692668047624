import { Colors } from "constants/colors";
import styled from "styled-components";

const variant: { [key: string]: any } = {
  PENDING: "#FFF8ED",
  PAID: "#F5FAF2",
  REFUND: "#FCF0EF",
  CANCEL: "#FCF0EF",
  default: Colors.black,
};

const status_color: { [key: string]: any } = {
  PENDING: "#F6B148",
  PAID: "#93C77B",
  REFUND: "#DE665E",
  CANCEL: "#DE665E",
  default: Colors.white,
};

interface BadgeStyleProps {
  status?: string;
}

export const BadgeStyle = styled.div<BadgeStyleProps>`
  display: flex;
  width: 9.75rem;
  height: 1.68rem;

  align-items: center;
  justify-content: center;
  border-radius: 4.68rem;

  background-color: ${(props) => variant[`${props.status ?? "default"}`]};

  span {
    color: ${(props) => status_color[`${props.status ?? "default"}`]};
    font-family: Open Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
  }
`;

export const TextStyle = styled.h2`
  color: #888996;
  font-family: "Open Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
