import { RiCloseCircleLine } from "react-icons/ri";
import { ModalStyled } from "./styles";
import { Colors } from "constants/colors";
import { CheckinComponent } from "../CheckinComponent";

interface ModalCheckInProps {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}

export function ModalCheckIn({
  isModalVisible,
  handleOk,
  handleCancel,
}: ModalCheckInProps) {
  return (
    <ModalStyled
      width={857}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          size={43}
          color={Colors.black40}
          style={{ marginTop: 30, marginRight: 60 }}
        />
      }
      footer={null}
    >
      <CheckinComponent key="checkin" />
    </ModalStyled>
  );
}
