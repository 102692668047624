import styled from "styled-components";
import { Colors } from "constants/colors";

export const Container = styled.div`
  width: 24.7rem;
  height: 12.28rem;

  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  box-shadow: 0px 4px 23px rgba(108, 93, 211, 0.1);
  border-radius: 0.65rem;

  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.header-integration {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    .ant-switch-checked {
      background-color: ${Colors.primary100};
    }

    img {
      object-fit: contain;
    }
  }

  div.content-integration {
    margin-top: 0.98rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    div.actions-container {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    div.badge {
      cursor: pointer;
      width: 2.1rem;
      height: 2.1rem;

      display: flex;
      align-items: center;
      justify-content: center;

      background: ${Colors.primary10};
      border-radius: 0.56rem;
    }

    div.texts {
      width: 67%;
      h2 {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 1.03rem;
        margin-bottom: 0px;
        color: ${Colors.black80};
      }

      span {
        font-family: "Open Sans";
        font-weight: normal;
        font-size: 0.84rem;
        margin-top: 0.46rem;
        margin-bottom: 0px;
        color: ${Colors.black50};
      }
    }
  }
`;

export const ButtonConnect = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 5.1rem;
  height: 2.25rem;

  background: ${Colors.primary100};
  border-radius: 0.46rem;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    margin-bottom: 0px;
    color: ${Colors.white};
  }
`;
