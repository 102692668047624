import styled from "styled-components";

import { Colors } from "constants/colors";
import { Drawer } from "antd";

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-title {
    color: ${Colors.secondary100};
    font-size: 16px;
    font-weight: bold;
  }
`;
