import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";

import { useDisclosure } from "hooks/useDisclosure";

import { useAuthMyEvent } from "services/Hook/Event";
import { authClient } from "services/authClient";

import Data from "constants/Datas";
import { Event } from "types";

export type Integration = {
  _id: number | string;
  label: string;
  short: string;
  description: string;
  thumb: string;
  link: string;
  integrationData: {
    event_id: string;
    _id: number | string;
    key: string;
    status: true;
    token: string;
    type: number;
    user_id: number;
    custom?: string;
    event_ticket_id: {
      _id: "6136893641179";
      name: "teste ingresso";
    };
  } | null;
};

type IntegrationResponse = {
  event_id: string;
  _id: number;
  key: string;
  status: true;
  token: string;
  type: number;
  user_id: number;
};

type IntegrationsContextData = {
  activeIntegrations?: boolean;
  activeNewIntegration?: boolean;
  activeEditIntegration?: boolean;
  handleChangeActiveIntegrations(value: boolean): void;
  handleChangeNewIntegration(value: boolean): void;
  handleChangeEditIntegration(value: boolean): void;
  eventIntegrations: Integration[] | [];
  eventIntegrationsActive: Integration[] | [];
  eventIntegrationsInative: Integration[] | [];
  selectedIntegration: Integration | null;
  setSelectedIntegration(integration: Integration): void;
  handleShowModalEdit(type: number | string): void;
  idsActiveIntegrations: string[];
  open: boolean;
  setOpen(value: boolean): void;
  getIntegrations(): Promise<void>;
  eventIntegrationUpdate: Integration | null;
  setEventIntegrationUpdate(value: Integration | null): void;
  handleActiveIntegrations(): void;
  setIdUpdateIntegration(value: number | null): void;
  handleActiveNewIntegration(): void;
  onDismiss(): void;
  isModalEditVisible: boolean;
  handleOkEdit(): void;
  handleCancelEdit(): void;
  idUpdateIntegration: number | null;
  handleActiveEditIntegration(value: number): void;
  setNickname(value: string): void;
};

const IntegrationsContext = createContext<IntegrationsContextData>(
  {} as IntegrationsContextData
);

export const IntegrationsProvider: React.FC = ({ children }) => {
  const [activeIntegrations, setActiveIntegrations] = useState(false);
  const [activeNewIntegration, setActiveNewIntegration] = useState(false);
  const [activeEditIntegration, setActiveEditIntegration] = useState(false);

  const [nickname, setNickname] = useState("");

  const [eventIntegrations, setEventIntegrations] = useState<
    Integration[] | []
  >([]);

  const [eventIntegrationsActive, setEventIntegrationsActive] = useState<
    Integration[] | []
  >([]);

  const [eventIntegrationsInative, setEventIntegrationsInative] = useState<
    Integration[] | []
  >([]);

  const [eventIntegrationUpdate, setEventIntegrationUpdate] =
    useState<Integration | null>(null);

  const [selectedIntegration, setSelectedIntegration] =
    useState<Integration | null>(null);

  const [idsActiveIntegrations, setIdsActiveIntegrations] = useState<string[]>(
    []
  );

  const [open, setOpen] = useState(false);

  const [idUpdateIntegration, setIdUpdateIntegration] = useState<number | null>(
    null
  );

  const handleChangeActiveIntegrations = (value: boolean) => {
    setActiveIntegrations(value);
  };

  const handleChangeNewIntegration = (value: boolean) => {
    setActiveNewIntegration(value);
  };

  const handleChangeEditIntegration = (value: boolean) => {
    setActiveEditIntegration(value);
  };

  const { data } = useAuthMyEvent<Event>(nickname ?? "");

  const {
    isModalVisible: isModalEditVisible,
    handleOk: handleOkEdit,
    handleCancel: handleCancelEdit,
    showModal: showModalEdit,
  } = useDisclosure();

  const getIntegrations = useCallback(async () => {
    try {
      if (!data?._id) {
        return;
      }

      const response = await authClient.get(`/event-integration/${data?._id}`);
      const responseData: IntegrationResponse[] = response.data.results;
      const xgrowIntegrations = responseData.filter((r) => r.type === 3) ?? [];
      const localIntegrations =
        xgrowIntegrations.length > 0
          ? Data.EventIntegrations.filter((e) => e._id !== 3)
          : Data.EventIntegrations;

      const mapIntegrations = localIntegrations.map((integration) => {
        const findIntegration = responseData.find(
          (old) => old.type === integration._id
        );

        if (findIntegration) {
          return {
            ...integration,
            integrationData: { ...findIntegration },
          };
        }

        return {
          ...integration,
          integrationData: null,
        };
      });

      const mapXgrowIntegrations = xgrowIntegrations.map((integration) => {
        const { _id, description, label, link, short, thumb } =
          Data.EventIntegrations[2];

        return {
          _id,
          label,
          short,
          description,
          thumb,
          link,
          integrationData: integration,
        };
      });

      const allIntegrations = [
        ...(mapIntegrations as Integration[]),
        ...(mapXgrowIntegrations as Integration[]),
      ];

      setEventIntegrations(allIntegrations);

      console.log({ mapXgrowIntegrations });

      const filterActive = mapXgrowIntegrations.filter(
        (item) => item.integrationData.status === true
      );

      const filterInative = mapXgrowIntegrations.filter(
        (item) => !item.integrationData.status && item !== null
      );

      setEventIntegrationsActive(filterActive as Integration[]);
      setEventIntegrationsInative(filterInative as Integration[]);

      const allIntegrationsIds = responseData?.map(
        (integration) => integration.type
      );

      setIdsActiveIntegrations(allIntegrationsIds.map((i) => i.toString()));
    } catch (err) {}
  }, [data?._id]);

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  const handleShowModalEdit = (type: number | string) => {
    const getEventIntegrationByType = eventIntegrations.find(
      (integration) => integration._id === type
    );

    if (getEventIntegrationByType) {
      setEventIntegrationUpdate(getEventIntegrationByType);
      showModalEdit();
    }
  };

  function onDismiss() {
    setOpen(false);
  }

  const handleActiveIntegrations = () => {
    handleChangeActiveIntegrations(true);
  };

  const handleActiveNewIntegration = () => {
    handleChangeActiveIntegrations(false);
    handleChangeEditIntegration(false);
    handleChangeNewIntegration(true);
    onDismiss();
  };

  const handleActiveEditIntegration = (type: number) => {
    const getEventIntegrationByType = eventIntegrations.find(
      (integration) => integration._id === type
    );

    if (getEventIntegrationByType) {
      setEventIntegrationUpdate(getEventIntegrationByType);

      handleChangeActiveIntegrations(false);
      handleChangeNewIntegration(false);
      handleChangeEditIntegration(true);

      onDismiss();
    }
  };

  return (
    <IntegrationsContext.Provider
      value={{
        activeIntegrations,
        activeNewIntegration,
        activeEditIntegration,
        handleChangeActiveIntegrations,
        handleChangeNewIntegration,
        handleChangeEditIntegration,
        eventIntegrations,
        eventIntegrationsActive,
        eventIntegrationsInative,
        selectedIntegration,
        setSelectedIntegration,
        handleShowModalEdit,
        idsActiveIntegrations,
        open,
        setOpen,
        getIntegrations,
        eventIntegrationUpdate,
        setEventIntegrationUpdate,
        handleActiveIntegrations,
        setIdUpdateIntegration,
        handleActiveNewIntegration,
        onDismiss,
        isModalEditVisible,
        handleOkEdit,
        handleCancelEdit,
        handleActiveEditIntegration,
        idUpdateIntegration,
        setNickname,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

export function useIntegrations(): IntegrationsContextData {
  const context = useContext(IntegrationsContext);

  if (!context) {
    throw new Error(
      `useIntegrations must be used within a IntegrationsProvider`
    );
  }

  return context;
}
