import { useSearchParams } from "react-router-dom";

import { Colors } from "constants/colors";

import {
  Container,
} from "./styles";
import { useFiltersContext } from "contexts/FiltersContext";
import { RiCalendarCheckFill } from "react-icons/ri";
import SelectV2 from "components/SelectV2";
import { useSearchMyEvent } from "hooks/useSearchMyEvent";

interface SelectFilterModalProps {
  title: string;
  label?: string;
}

export function SelectEventFilterV2({
  title,
  label,
}: SelectFilterModalProps) {
  const [searchParams] = useSearchParams();
  const { data: dataEvents } = useSearchMyEvent();

  const { setIsOpenFilterModalEvent } = useFiltersContext();

  const events = searchParams.get("events") ?? "";

  const handleOpenModal = () => {
    setIsOpenFilterModalEvent(true);
  };

  const value = dataEvents?.results?.filter(e => events.split("%").includes(e._id)) ?? [];

  return (
    <Container>
      <SelectV2
        boxIcon={<RiCalendarCheckFill color={Colors.secondary30} />}
        icon={
          <RiCalendarCheckFill
            color={Colors.primary100}
            size={18}
          />
        }
        options={[]}
        placeholder={title}
        label={label ?? ""}
        open={false}
        asModalOpener
        onClick={handleOpenModal}
        value={value?.length > 0 ? value?.map(val => val.name).join(",") : undefined}
      />
    </Container>
  );
}
