import { Select, SelectProps } from "antd";
import { ReactNode } from "react";
import { Container, Label, OptionContainer, Wrapper } from "./styles";

type OptionType = {
  label: string;
  value: string;
  icon?: ReactNode;
  disabled?: boolean;
};

interface SelectV2Props extends SelectProps {
  options: OptionType[];
  icon: ReactNode;
  label: string;
  boxIcon?: ReactNode;
  error?: string;
  asModalOpener?: boolean;
}

const { Option } = Select;

function SelectV2({
  options,
  icon,
  label,
  boxIcon,
  value,
  error,
  asModalOpener = false,
  ...props
}: SelectV2Props) {
  if (asModalOpener) {
    return (
      <Container>
        <Label>{label}</Label>

        <Wrapper hasIcon={Boolean(boxIcon)} hasValue={Boolean(value)}>
          {!Boolean(value) ? <div className="icon">{boxIcon}</div> : null}
          <Select {...props} bordered={false} value={value}>
          </Select>
        </Wrapper>

        {error ? <p className="error">{error}</p> : null}
      </Container>
    )
  }

  return (
    <Container>
      <Label>{label}</Label>

      <Wrapper hasIcon={Boolean(boxIcon)} hasValue={Boolean(value)}>
        {!Boolean(value) ? <div className="icon">{boxIcon}</div> : null}
        <Select {...props} bordered={false} value={value}>
          {options.map((option) => (
            <Option disabled={Boolean(option?.disabled)} label={option.label} value={option.value}>
              <OptionContainer>
                {option?.icon ? option.icon : icon}

                <span className="value">{option.label}</span>
              </OptionContainer>
            </Option>
          ))}
        </Select>
      </Wrapper>

      {error ? <p className="error">{error}</p> : null}
    </Container>
  );
}

export default SelectV2;
