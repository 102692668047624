import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { authClient } from "services/authClient";
import { useAuthTicketsByEventsId } from "services/Hook/Event/Ticket";
import { useSWRConfig } from "swr";
import { TicketEvent } from "types";

interface EventTicketInterface {
  results: TicketEvent[];
  count: number;
}

export function useSearchEventTicket(eventId: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate } = useSWRConfig();

  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );
  const [pageMobile, setPageMobile] = useState(1);

  const [loadingMoreItems, setLoadingMoreItems] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const { data, partialUrl } = useAuthTicketsByEventsId<EventTicketInterface>(
    [eventId],
    page
  );

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });

      window.scrollTo({ top: 660, behavior: "smooth" });
    },
    [searchParams, setSearchParams]
  );

  const handleLoadMoreItemsMobile = useCallback(() => {
    setPageMobile(pageMobile + 1);

    mutate(
      [partialUrl, 1, eventId],
      async () => {
        setLoadingMoreItems(true);

        const response = await authClient.post(
          `${partialUrl}?page=${pageMobile + 1}&limit=10`,
          {
            event_id: [eventId],
          }
        );

        const count = response.data.count;

        const last_page = Math.ceil(count / 10);

        if (pageMobile + 1 === last_page) {
          setHasMoreItems(false);
        }

        setLoadingMoreItems(false);

        const mapEvents = response.data.results.map(
          (ticketEvent: TicketEvent) => ({
            ...ticketEvent,
            isOpen: false,
          })
        );

        let newMapEvents = data?.results;

        const mobile = data?.results;

        if (mobile) {
          newMapEvents = [...mobile, ...mapEvents];
        }

        return {
          count: response.data.count,
          results: newMapEvents,
        };
      },
      false
    );
  }, [data?.results, eventId, mutate, pageMobile, partialUrl]);

  return {
    data,
    page,
    loadingMoreItems,
    hasMoreItems,
    handleLoadMoreItemsMobile,
    onChange,
  };
}
