import { Select } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContainerItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-right: 1.75rem;
  padding-left: 1.68rem;

  a {
    width: 100%;
  }
`;

export const LoadMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-right: 1.75rem;
  padding-left: 1.68rem;
`;

export const Title = styled.h1`
  width: 100%;
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 0px;
  text-align: left;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.87rem;
  margin-bottom: 0.75rem;
  background: ${Colors.secondary10};
`;

export const TitleTotal = styled.h2`
  color: #adadad;
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  text-align: normal;
  line-height: normal;
  text-transform: uppercase;
`;

export const TitlePrice = styled.h3`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.56rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
`;

export const ContainerTax = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.1rem;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid ${Colors.border20};
  background: ${Colors.white};
  padding-left: 0.62rem;
  margin-top: 0.81rem;
`;

export const TitleTax = styled.p`
  color: ${Colors.black50};
  font-family: Open Sans;
  font-size: 0.62rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 0.25rem;

  strong {
    color: ${Colors.black50};
    font-family: Open Sans;
    font-size: 0.62rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const ContainerLastReceipts = styled.div`
  width: 100%;
  min-height: 10rem;
  background-color: ${Colors.lightgray10};
  border-top: 1px solid ${Colors.secondary10};
  padding-top: 1.25rem;
  margin-top: 1.68rem;
`;

export const ContainerItemsReceipts = styled.div`
  display: flex;
  width: 100%;
  /* height: 100%; */

  flex-direction: column;
  padding-right: 1.75rem;
  padding-left: 1.68rem;
`;

export const ListReceipts = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
`;

export const ItemReceipt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.93rem;
  width: 100%;
`;

export const ColumnLineIcon = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Hr = styled.div`
  width: 0.5px;
  height: 3.43rem;
  flex-shrink: 0;
  background: #e9e7ee;
`;

export const RowContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 11px 0px 8px;
`;

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnContentFlexEnd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const TitleItemReceipt = styled.h3`
  color: #888996;
  font-family: Open Sans;
  font-size: 0.62rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const TitleContentItem = styled.h4`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
`;

export const TitleDataSmall = styled.h5`
  color: #888996;
  font-family: Open Sans;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ContainerAccordion = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;

  :first-child {
    margin-bottom: 20px;
  }

  div.accordion + div.accordion {
    margin-top: 13px;
  }

  div.header-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 16px;
    margin-bottom: 26px;

    h1 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      margin-top: 0px;
      margin-bottom: 0px;
      text-transform: uppercase;
      color: ${Colors.gray20};
      opacity: 0.75;
    }

    div.box {
      width: 25.73px;
      height: 25.73px;
      border-radius: 50%;
      background: ${Colors.white20};

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }
`;

interface CardAccordionProps {
  active: boolean;
}

export const CardAccordionMobile = styled.div<CardAccordionProps>`
  cursor: pointer;
  width: 100%;

  height: 49px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  z-index: 8;
  position: relative;

  background: ${(props) => (props.active ? Colors.black5 : Colors.white)};

  border: 1px solid ${Colors.black10};
  box-shadow: 0px 4px 20px rgba(18, 25, 34, 0.05);
  border-radius: 5px;

  padding: 7px 21px 10px 19px;

  div.content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    div.image {
      margin-top: 4.5px;
    }

    div.texts {
      height: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 23px;

      div.first {
        h2.text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 0px;

          color: ${Colors.black100};

          max-width: 180px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      span {
        font-family: "Cairo";
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        margin-bottom: 0px;

        color: ${Colors.black50};
        margin-top: 2px;
      }
    }
  }
`;

export const DataDropDownMobile = styled.div`
  display: block;
  position: relative;

  width: 100%;
  z-index: 1;
  margin-top: -35px;
  margin-bottom: 14px;

  height: 100%;

  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  border-radius: 5px;

  /* padding-left: 16px;
  padding-right: 16px; */

  div.content-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 42px;

    padding-bottom: 25px;

    border-bottom: 1px solid ${Colors.secondary10};

    div.container-image {
      img {
        border-radius: 6px;
        object-fit: cover;
      }
    }

    div.texts-ticket {
      display: flex;
      flex-direction: column;

      div + div {
        margin-top: 13px;
      }

      div.data {
        display: flex;
        flex-direction: column;

        h2.text-data {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;

          margin-bottom: 0px;
          color: ${Colors.black100};
        }

        span {
          font-family: "Poppins";
          font-weight: normal;
          font-size: 10px;
          line-height: 15px;
          margin-top: 2px;
          color: ${Colors.black50};
        }
      }
    }
  }
`;

export const ContainerButtonMobile = styled.div`
  width: 100%;
  padding: 0px 17px 17px 18px;
  display: flex;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`;

export const TitleMonth = styled.h3`
  color: #888996;
  font-family: Open Sans;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const TitlePriceMonth = styled.h4`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
`;

export const ListSales = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.03rem;

  li:nth-child(2n + 1) {
    background-color: ${Colors.gray70};
  }
`;

export const Sale = styled.li`
  display: flex;
  width: 100%;
  height: 2.62rem;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
`;

export const TitleDataSale = styled.h3`
  color: #888996;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TitleTotalSale = styled.h4`
  color: #888996;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TitleTotalSalePrice = styled.h5`
  color: var(--preto, #11142d);
  font-family: Cairo;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ButtonReport = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 2.37rem;

  background: ${Colors.primary10};
  border-radius: 0.5rem;

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.75rem;
    margin-bottom: 0px;

    color: ${Colors.primary100};
    margin-left: 10px;
  }

  img {
    width: 0.81rem;
    height: 0.81rem;
  }
`;

export const LableTitle = styled.h3`
  width: 100%;
  color: ${Colors.gray20};
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0px;
`;

export const LableTitleAccordion = styled.h3`
  width: 100%;
  color: ${Colors.gray20};
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const BorderSelect = styled.div`
  border: 2px solid ${Colors.secondary20};
  width: 100%;
  height: 2.81rem;
  border-radius: 0.37rem;
  margin-right: 0.51rem;
  display: flex;
  margin-top: 9px;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0.45rem;

    height: 32px;
    border-radius: 6px;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .ant-select-selection-item {
    color: ${Colors.secondary70};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  position: static;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
`;

export const ButtonPrimary = styled.button`
  border: 0;
  width: 100%;
  height: 38px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${Colors.primary100};
  box-shadow: 0px 5px 30px 0px rgba(212, 0, 80, 0.25);
  margin-top: 30px;
  margin-bottom: 34px;

  span {
    color: ${Colors.white};
    font-family: Cairo;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const ButtonSecondary = styled.button`
  border: 0;
  width: 100%;
  height: 38px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${Colors.primary10};

  span {
    color: ${Colors.primary100};
    font-family: Cairo;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const ContentFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
