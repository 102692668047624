interface paramsStringProps {
  price?: string;
  data?: string;
  categories?: string;
  id?: string;
  address_city?: string;
  address_state?: string;
  name?: string;
  organizer?: string;
  organizers?: string;
  events?: string;
  tickets?: string;
  status?: string;
  date_type?: string;
  data_gte?: string;
  data_lte?: string;
  data_type?: string;
  payment_type?: string;
  filter_id?: string;
  filter_text?: string;
  payment_status?: string;
  type?: string;
}

export function paramsToString({
  id,
  name,
  price,
  data,
  categories,
  address_city,
  address_state,
  organizer,
  organizers,
  events,
  tickets,
  status,
  date_type,
  data_gte,
  data_lte,
  data_type,
  payment_type,
  filter_id,
  filter_text,
  payment_status,
  type,
}: paramsStringProps) {
  let params = {};

  if (id) {
    params = { ...params, categories: id };
  }

  if (categories) {
    params = { ...params, categories };
  }

  if (name) {
    params = { ...params, name };
  }

  if (address_city) {
    params = { ...params, address_city };
  }

  if (address_state) {
    params = { ...params, address_state };
  }

  if (price) {
    switch (Number(price)) {
      case 1:
        params = { ...params, gte_price: 0, lte_price: 50 };
        break;

      case 2:
        params = { ...params, gte_price: 0, lte_price: 100 };
        break;

      case 3:
        params = { ...params, gte_price: 0, lte_price: 500 };
        break;

      default:
        break;
    }
  }

  if (data) {
    switch (Number(data)) {
      case 1:
        params = { ...params, data_type: "hoje" };
        break;

      case 2:
        params = { ...params, data_type: "Esta semana" };
        break;

      case 3:
        params = { ...params, data_type: "este mes" };
        break;

      default:
        break;
    }
  }

  if (organizer) {
    params = { ...params, organizer };
  }

  if (organizers) {
    params = { ...params, organizers };
  }

  if (events) {
    params = { ...params, events };
  }

  if (tickets) {
    params = { ...params, tickets };
  }

  if (status) {
    params = { ...params, status };
  }

  if (date_type) {
    params = { ...params, date_type };
  }

  if (data_gte) {
    params = { ...params, data_gte };
  }

  if (data_lte) {
    params = { ...params, data_lte };
  }

  if (data_type) {
    params = { ...params, data_type };
  }

  if (payment_type) {
    params = { ...params, payment_type };
  }

  if (filter_id && filter_text) {
    params = { ...params, [filter_id]: filter_text };
  }

  if (payment_status) {
    params = { ...params, payment_status };
  }

  if (type) {
    params = { ...params, type };
  }

  return params;
}
