import { Colors } from "constants/colors";
import { sizeConversor } from "utils/conversors";
import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  margin-top: 2rem;

  @media (max-width: 900px) {
    display: none;
  }


  table td {
    border-right-width: 0px;
    border-bottom-width: 0px;
  }


  table {
    width: 100%;

    min-width: 600px;
    border-style: hidden;


    border-collapse: separate;
    border-spacing: 0 11px;

    div.button {
      @media(max-width: 1240px){
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }


    th {
      background-color: ${Colors.white};
      padding: 1rem;
      text-align: left;
      color: ${Colors.white};
      font-size: 0.875rem;
      line-height: 1.6;


      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      margin-bottom: 0px;

      color: ${Colors.black30};

      &:first-child {
        border-top-left-radius: 8px;
        padding-left: 1.5rem;
      }


      &:last-child {
        border-top-right-radius: 8px;
        padding-right: 1.5rem;
      }
    }


    td {
      border: 1px solid ${Colors.border50};
      border-style: solid none;
      padding: 11px;

      h5 {
        font-family: "Cairo";
        font-style: normal;
        font-weight: 700;
        font-size: 0.93rem;
        margin-bottom: 0px;
        margin-top: 0px;

        color: ${Colors.black80};
      }

      h6 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        margin-bottom: 0px;
        margin-top: 4px;
        color: ${Colors.black70};
      }

      .button {
        display: flex;
        gap: ${sizeConversor(10)};
      }
    }

    td:first-child {
      border-left-style: solid;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;

      img {
        border-radius: 6px;
      }

      div.row {
        display: flex;
        flex-direction: row;

        div.column {
          display: flex;
          flex-direction: column;
          margin-left: 7px;
        }
      }
    }

    td:last-child {
      border-right-style: solid;
      border-bottom-right-radius: 11px;
      border-top-right-radius: 11px;
    }

    tbody {
      tr {
        display: table-row;
        border-radius: 0.93rem 0px 0px 0.93rem;
        border: 1px solid ${Colors.black10};

        div {
        }
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }
      tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }
    }

    td {
      margin-bottom: 20px;
      padding: 1rem;
      font-size: 0.875rem;
      line-height: 1.6;

      div.date {
        display: flex;
        flex-direction: column;

        h5 {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 700;
          font-size: 0.93rem;
          margin-bottom: 0px;
          margin-top: 0px;

          color: ${Colors.black80};
        }

        h6 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          margin-bottom: 0px;
          margin-top: 4px;
          color: ${Colors.black70};
        }
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        margin-bottom: 0px;

        color: ${Colors.black70};
      }

      margin-bottom: 0.56rem;

      &:first-child {
        padding-left: 1.5rem;

        border-top-left-radius: 8px;
      }

      &:last-child {
        padding-right: 1.5rem;
        border-radius: 0px 0.93rem 0.93rem 0px;

        border-right: 1px solid ${Colors.black10};
      }
    }
  }
`;

interface StatusProps {
  status: string;
}

export const Status = styled.div<StatusProps>`
  display: flex;
  justify-content: center;

  font-family: Open Sans;
  font-size: ${sizeConversor(16)};
  font-style: normal;
  font-weight: 600;

  border-radius: ${sizeConversor(100)};
  padding: ${sizeConversor(5)} ${sizeConversor(18)};
  width: ${(props) => (props.status == "Pago" ? "74px" : props.status == "Pendente" ? "111px" : props.status == "Estornado" ? "115px" : "")};

  background-color: ${(props) => (props.status == "Pago" ? "rgba(245, 250, 242, 1)" : props.status == "Pendente" ? "rgba(255, 248, 237, 1)" : props.status == "Estornado" ? "rgba(241, 241, 243, 1)" : "")};
  color: ${(props) => (props.status == "Pago" ? "rgba(147, 199, 123, 1)" : props.status == "Pendente" ? "rgba(246, 177, 72, 1)" : props.status == "Estornado" ? "rgba(112, 114, 129, 1)" : "")};
`;

export const ActionButton = styled.button`
  display: flex;
  gap: ${sizeConversor(10)};
  min-width: ${sizeConversor(262)};
  height: ${sizeConversor(60)};
  padding: ${sizeConversor(14)} ${sizeConversor(25)};
  justify-content: center;
  align-items: center;

  color: var(--secundaria-secundaria-100, #241058);
  font-family: Cairo;
  font-size: ${sizeConversor(20)};
  font-style: normal;
  font-weight: 700;
  line-height: 160%;

  border: none;
  border-radius: ${sizeConversor(14)};
  background: #F4F3F4;
`;


export const ButtonDownload = styled.button`
  cursor: pointer;
  width: 190px;
  height: 2.81rem;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${Colors.primary10};
  border-radius: 0.65rem;
  margin-right: 10px;

  span {
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    color: ${Colors.primary100};
  }
`;
